/* eslint-disable prettier/prettier */
<template>
  <div>
    <!-- BEGIN MODAL SECTION -->

    <b-modal id="detail-kegiatan" ok-only centered size="xl" content-class="shadow" hide-footer hide-header>
      <b-container fluid class="mb-5 mt-0 pt-0">
        <b-row class="pr-0 pl-0">
          <b-col class="d-flex justify-content-end position-relative pr-0 pl-0">
            <b style="font-size:20px;position:absolute; left:50%;">
              Detail
            </b>
            <div>
              <b-button variant="outline-secondary" style="background: #FFFFFF;" size="sm" class="mr-4"
                @click="$bvModal.hide('detail-kegiatan')">
                <v-icon class="pr-3">mdi-close</v-icon>
                Tutup
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-container>
      <b-row>
        <b-col sm="3">
          <p style="font-weight: 600">
            Nomor Laporan
          </p>
        </b-col>:<b-col sm="6">
          {{detailKegiatan.id}}
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="3">
          <p style="font-weight: 600">
            Nama Kegiatan
          </p>
        </b-col>:<b-col sm="6">
          {{detailKegiatan.nama_kegiatan}}
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="3">
          <p style="font-weight: 600">
            Jenis Kegiatan
          </p>
        </b-col>:<b-col sm="6">
          {{detailKegiatan.masterJenisKegiatan.nama_jenis_kegiatan}}
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="3">
          <p style="font-weight: 600">
            Tempat Kegiatan
          </p>
        </b-col>:<b-col sm="6">
          {{detailKegiatan.tempat_kegiatan}}
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="3">
          <p style="font-weight: 600">
            Tanggal Kegiatan
          </p>
        </b-col>:<b-col sm="6">
          {{detailKegiatan.tanggal_kegiatan | moment('DD MMMM YYYY')}}
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="3">
          <p style="font-weight: 600">
            Sumber Dana
          </p>
        </b-col>:<b-col sm="6">
          {{detailKegiatan.masterSumberDana.nama_sumber_dana}}
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="3">
          <p style="font-weight: 600">
            Keterangan
          </p>
        </b-col>:<b-col sm="6">
          {{detailKegiatan.keterangan}}
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="3">
          <p style="font-weight: 600; margin:0px;">
            Lihat File Unggahan
          </p>
        </b-col>:<b-col sm="6">
          <b-button @click="newTab([{path:fileURL + '/' + detailKegiatan.file_laporan}])" variant="primary" size="sm">
            <v-icon>
              mdi-clipboard
            </v-icon>
            &nbsp;
            Lihat File
          </b-button>
        </b-col>
      </b-row>

    </b-modal>

    <b-modal id="filter" ok-only centered size="md" content-class="shadow" hide-footer>
      <template #modal-title>
        <div style="font-size:24px; font-weight:600;">Filter</div>
      </template>
      <div>
        <b-form-row>
          <b-col>
            <b-row class="mb-5 d-flex align-items-center">
              <b-col sm="5">
                <p style="font-weight: 600; margin:0px;">Provinsi</p>
              </b-col>:<b-col sm="6">
                <b-form-select v-model="selectedProvinsi">
                  <b-form-select-option :value="item.kode" v-for="(item,index) in listProvinsi" :key="index">
                    {{item.nama}}</b-form-select-option>
                </b-form-select>
              </b-col>
            </b-row>
            <b-row v-if="selectedProvinsi != ''" class="mb-5 d-flex align-items-center">
              <b-col sm="5">
                <p style="font-weight: 600; margin:0px;">Kabupaten/Kota</p>
              </b-col>:<b-col sm="6">
                <b-form-select type="text" v-model="filter.kabupaten_kota" placeholder="Silahkan Pilih Kabupaten/Kota">
                  <b-form-select-option :value="item.kode" v-for="(item,index) in listKota" :key="index">{{item.nama}}
                  </b-form-select-option>
                </b-form-select>
              </b-col>
            </b-row>
            <b-row class="mb-5 d-flex align-items-center">
              <b-col sm="5">
                <p style="font-weight: 600; margin:0px;">Status SKT</p>
              </b-col>
              :
              <b-col sm="6">
                <b-form-select type="text" v-model="filter.sktAktif" placeholder="Silahkan Pilih Status SKT">
                  <b-form-select-option :value="'true'">Aktif</b-form-select-option>
                  <b-form-select-option :value="'false'">Tidak Aktif</b-form-select-option>
                </b-form-select>
              </b-col>
            </b-row>
            <b-row :class="filter.bidang_kegiatan.length == 1 ? 'pb-3 align-items-center' : 'pb-3 '">
              <b-col sm="5">
                <p style="font-weight: 600; margin:0px;">Bidang Kegiatan</p>
              </b-col>:<b-col sm="6">
                <b-row v-for="(i,index) in filter.bidang_kegiatan" :key="index" class="pb-2">
                  <b-col cols="9" class="pr-0">
                    <b-form-select v-model="filter.bidang_kegiatan[index].id" style="max-width: 478px;">
                      <b-form-select-option :value="[item.id, item.namaJenisOrmas]"
                        v-for="(item,tr) in listBidangKegiatan" :key="tr">{{item.namaJenisOrmas}}</b-form-select-option>
                    </b-form-select>
                  </b-col>
                  <b-col cols="3" class="d-flex align-items-center pl-0">
                    <div class="button-minus" v-if="index != 0 && filter.bidang_kegiatan.length - index == 1">
                      <v-icon style="color:white;" small @click="filter.bidang_kegiatan.splice(-1)">mdi-minus</v-icon>
                    </div>
                    <div class="button-plus" v-if="filter.bidang_kegiatan.length - index != 1" style="opacity:0.5;">
                      <v-icon style="color:white;" small>mdi-plus</v-icon>
                    </div>
                    <div class="button-plus" v-if="filter.bidang_kegiatan.length - index == 1">
                      <v-icon style="color:white;" small @click="filter.bidang_kegiatan.push({id: []})">mdi-plus
                      </v-icon>
                    </div>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row class="mb-5 d-flex align-items-center">
              <b-col sm="5">
                <p style="font-weight: 600; margin:0px;">Status Sekretariat</p>
              </b-col>:<b-col sm="6">
                <b-form-select v-model="filter.status_sekretariat" type="text"
                  placeholder="Silahkan Pilih Status Sekretariat">
                  <b-form-select-option :value="[item.id, item.namaLokasi]" v-for="(item,index) in listSekretariat"
                    :key="index">{{item.namaLokasi}}</b-form-select-option>
                </b-form-select>
              </b-col>
            </b-row>
            <b-row class="mb-5 d-flex align-items-center">
              <b-col sm="5">
                <p style="font-weight: 600; margin:0px;">Ciri Khusus</p>
              </b-col>:<b-col sm="6">
                <b-form-select v-model="filter.ciri_khusus" type="text" placeholder="Silahkan Pilih Status Sekretariat">
                  <b-form-select-option :value="[item.id, item.namaCiriKhusus]" v-for="(item,index) in listCiriKhusus"
                    :key="index">{{item.namaCiriKhusus}}</b-form-select-option>
                </b-form-select>
              </b-col>
            </b-row>
            <b-row class="mb-5 d-flex align-items-center">
              <b-col sm="5">
                <p style="font-weight: 600; margin:0px;">Jenjang Ormas</p>
              </b-col>:<b-col sm="6">
                <b-form-select type="text" v-model="filter.jenjang_ormas"
                  placeholder="Silahkan Pilih Status Sekretariat">
                  <b-form-select-option :value="'true'">Berjenjang</b-form-select-option>
                  <b-form-select-option :value="'false'">Tidak Berjenjang</b-form-select-option>
                </b-form-select>
              </b-col>
            </b-row>
            <b-row class="mb-5 d-flex align-items-center" v-if="tab != 1">
              <b-col sm="5">
                <p style="font-weight: 600; margin:0px;">Status Kegiatan</p>
              </b-col>:<b-col sm="6">
                <b-form-select type="text" v-model="filter.status_kegiatan"
                  placeholder="Silahkan Pilih Status Sekretariat">
                  <b-form-select-option :value="'true'">Aktif</b-form-select-option>
                  <b-form-select-option :value="'false'">Tidak Aktif</b-form-select-option>
                </b-form-select>
              </b-col>
            </b-row>
            <b-row class="mb-5 d-flex align-items-center" v-if="tab != 1">
              <b-col sm="5">
                <p style="font-weight: 600; margin:0px;">Ormas Dalam Sengketa</p>
              </b-col>:<b-col sm="6">
                <b-form-select type="text" v-model="filter.dalam_sengketa"
                  placeholder="Silahkan Pilih Status Sekretariat">
                  <b-form-select-option :value="'true'">Sedang Berlangsung</b-form-select-option>
                  <b-form-select-option :value="'false'">Sudah Berakhir</b-form-select-option>
                </b-form-select>
              </b-col>
            </b-row>
            <b-row class="mb-5 d-flex align-items-center">
              <b-col sm="5">
                <p style="font-weight: 600; margin:0px;">Tahun Terbit SKT</p>
              </b-col>:<b-col sm="6">
                <b-form-select v-model="selectedTahunSkt">
                  <b-form-select-option :value="item" v-for="(item,index) in listTahunSkt" :key="index">
                    {{item}}</b-form-select-option>
                </b-form-select>
              </b-col>
            </b-row>
          </b-col>
        </b-form-row>
        <div class="text-center">
          <b-button variant="outline-primary" class="mt-16 text-center mr-5" @click="$bvModal.hide('filter')">
            Batal
          </b-button>
          <b-button variant="primary" class="mt-16 text-center" @click="submitFilter()">
            Terapkan
          </b-button>
        </div>
      </div>
    </b-modal>

    <b-modal id="filterAsing" ok-only centered size="md" content-class="shadow" hide-footer>
      <template #modal-title>
        <div style="font-size:24px; font-weight:600;">Filter</div>
      </template>
      <div>
        <b-form-row>
          <b-col>
            <b-row class="mb-5 d-flex align-items-center">
              <b-col sm="5">
                <p style="font-weight: 600; margin:0px;">Mitra KL</p>
              </b-col>:<b-col sm="6">
              </b-col>
            </b-row>
            <b-row v-if="selectedProvinsi != ''" class="mb-5 d-flex align-items-center">
              <b-col sm="5">
                <p style="font-weight: 600; margin:0px;">Status MSP</p>
              </b-col>:<b-col sm="6">
              </b-col>
            </b-row>
            <b-row class="mb-5 d-flex align-items-center">
              <b-col sm="5">
                <p style="font-weight: 600; margin:0px;">Lokus Provinsi</p>
              </b-col>
              :
              <b-col sm="6">
              </b-col>
            </b-row>
            <b-row :class="filter.bidang_kegiatan.length == 1 ? 'pb-3 align-items-center' : 'pb-3 '">
              <b-col sm="5">
                <p style="font-weight: 600; margin:0px;">Lokus Kabupaten/Kota</p>
              </b-col>:<b-col sm="6">
                <!-- <b-row v-for="(i,index) in filter.bidang_kegiatan" :key="index" class="pb-2">
                  <b-col cols="9" class="pr-0">
                    <b-form-select v-model="filter.bidang_kegiatan[index].id" style="max-width: 478px;">
                      <b-form-select-option :value="[item.id, item.namaJenisOrmas]"
                        v-for="(item,tr) in listBidangKegiatan" :key="tr">{{item.namaJenisOrmas}}</b-form-select-option>
                    </b-form-select>
                  </b-col>
                  <b-col cols="3" class="d-flex align-items-center pl-0">
                    <div class="button-minus" v-if="index != 0 && filter.bidang_kegiatan.length - index == 1">
                      <v-icon style="color:white;" small @click="filter.bidang_kegiatan.splice(-1)">mdi-minus</v-icon>
                    </div>
                    <div class="button-plus" v-if="filter.bidang_kegiatan.length - index != 1" style="opacity:0.5;">
                      <v-icon style="color:white;" small>mdi-plus</v-icon>
                    </div>
                    <div class="button-plus" v-if="filter.bidang_kegiatan.length - index == 1">
                      <v-icon style="color:white;" small @click="filter.bidang_kegiatan.push({id: []})">mdi-plus
                      </v-icon>
                    </div>
                  </b-col>
                </b-row> -->
              </b-col>
            </b-row>
          </b-col>
        </b-form-row>
        <div class="text-center">
          <b-button variant="outline-primary" class="mt-16 text-center mr-5" @click="$bvModal.hide('filterAsing')">
            Batal
          </b-button>
          <b-button variant="primary" class="mt-16 text-center" @click="submitFilterAsing()">
            Terapkan
          </b-button>
        </div>
      </div>
    </b-modal>

    <b-modal id="filterBadanHukum" ok-only centered size="md" content-class="shadow" hide-footer>
      <template #modal-title>
        <div style="font-size:24px; font-weight:600;">Filter</div>
      </template>
      <div>
        <b-form-row>
          <b-col>
            <b-row class="mb-5 d-flex align-items-center">
              <b-col sm="5">
                <p style="font-weight: 600; margin:0px;">Jenis Badan Hukum</p>
              </b-col>:<b-col sm="6">
                <b-form-select type="text" v-model="filterbadanhukum.jenisbadanhukum"
                  placeholder="Silahkan Pilih Status Sekretariat">
                  <b-form-select-option :value="0">~pilih jenis badan hukum~</b-form-select-option>
                  <b-form-select-option :value="1">Perkumpulan</b-form-select-option>
                  <b-form-select-option :value="2">Yayasan</b-form-select-option>
                </b-form-select>
              </b-col>
            </b-row>
          </b-col>
        </b-form-row>
        <div class="text-center">
          <b-button variant="outline-primary" class="mt-16 text-center mr-5" @click="$bvModal.hide('filterBadanHukum')">
            Batal
          </b-button>
          <b-button variant="primary" class="mt-16 text-center" @click="submitFilterBadanHukum()">
            Terapkan
          </b-button>
        </div>
      </div>
    </b-modal>

    <b-modal id="detail-cabang" ok-only centered size="xl" content-class="shadow" hide-footer hide-header>
      <b-container fluid class="mb-5">
        <b-row class="pt-5 mt-2 pr-0 pl-0">
          <b-col class="d-flex justify-content-between pr-0 pl-0">
            <b style="font-size:20px;">
              Detail - Cabang Ormas
            </b>
            <div>
              <b-button variant="outline-secondary" style="background: #FFFFFF;" size="sm" class="mr-4"
                @click="$bvModal.hide('detail-cabang')">
                <v-icon class="pr-3">mdi-close</v-icon>
                Tutup
              </b-button>
              <b-button
                v-if="tabDetailCabang == 2 && userType == 'admin' && detailCabang.file_sk_pengurus && detailCabang.file_sk_pengurus !== null && detailCabang.file_sk_pengurus !== ''"
                @click="newTab(detailCabang.file_sk_pengurus)" variant="primary" size="sm" class="mr-4">
                <v-icon class="pr-3">mdi-clipboard</v-icon>
                Lihat SK Pengurus
              </b-button>
              <button @click="cetakPDFCabang(detailCabang.id)" style="color:white;"
                class="btn font-weight-bolder font-size-sm bg-hitam" data-wizard-type="action-submit">
                <i class="fas fa-file-pdf" style="color:white;"></i>Cetak PDF
              </button>
            </div>
          </b-col>
        </b-row>
      </b-container>
      {{detailCabang ? '' : ''}}
      <div v-if="detailCabang">
        <b-tabs pills card justified v-model="tabDetailCabang">

          <b-tab active title="Profil" style="background-color:white;margin-top:0px;">
            <b-row>
              <b-col cols="7">
                <b-row>
                  <b-col sm="3">
                    <p style="font-weight: 600">{{ "Nama Ormas" }}</p>
                  </b-col>:<b-col sm="6">{{ detailCabang.nama_cabang}}</b-col>
                </b-row>
                <b-row v-if="detailCabang.nama_singkatan">
                  <b-col sm="3">
                    <p style="font-weight: 600">{{ "Singkatan" }}</p>
                  </b-col>:<b-col sm="6">{{detailCabang.nama_singkatan}}</b-col>
                </b-row>
                <b-row>
                  <b-col sm="3">
                    <p style="font-weight: 600">
                      {{ "Status Sekretariat" }}
                    </p>
                  </b-col>:<b-col sm="6" v-if="detailCabang.masterLokasiSekretariat">{{
                  detailCabang.masterLokasiSekretariat.nama_lokasi }}</b-col>
                </b-row>
                <b-row>
                  <b-col sm="3">
                    <p style="font-weight: 600">
                      {{ "Alamat sekretariat" }}
                    </p>
                  </b-col>:<b-col sm="6">{{ detailCabang.alamat_sekretariat }}</b-col>
                </b-row>
                <b-row>
                  <b-col sm="3">
                    <p style="font-weight: 600">{{ "Kabupaten/Kota" }}</p>
                  </b-col>:<b-col sm="6" v-if="detailCabang.wilayahKabupaten">{{ detailCabang.wilayahKabupaten.nama }}
                  </b-col>
                </b-row>
                <b-row>
                  <b-col sm="3">
                    <p style="font-weight: 600">{{ "Provinsi" }}</p>
                  </b-col>:<b-col sm="6">{{ detailCabang.wilayahProvinsi.nama }}</b-col>
                </b-row>
                <b-row v-if="userType == 'admin'">
                  <b-col sm="3">
                    <p style="font-weight: 600">{{ "Nomor Telepon" }}</p>
                  </b-col>:<b-col sm="6">{{ detailCabang.no_telp }}</b-col>
                </b-row>
                <b-row v-if="userType == 'admin'">
                  <b-col sm="3">
                    <p style="font-weight: 600">{{ "Nomor FAX" }}</p>
                  </b-col>:<b-col sm="6">{{ detailCabang.no_fax }}</b-col>
                </b-row>
                <b-row>
                  <b-col sm="3">
                    <p style="font-weight: 600; magrin:0px">{{ "NPWP" }}</p>
                  </b-col>:<b-col sm="6">{{ detailCabang.npwp }}
                    <b-button v-if="userType == 'admin'" size="sm" variant="success">
                      <v-icon style="color:white;" small>mdi-eye</v-icon>Lihat File Scan
                    </b-button>
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="5">
              </b-col>
            </b-row>
          </b-tab>
          <b-tab title="Info Pendirian" style="background-color:white;margin-top:0px;">
            <b-row>
              <b-col cols="7">
                <b-row>
                  <b-col sm="4">
                    <p style="font-weight: 600">Nama Notaris</p>
                  </b-col>:<b-col sm="6" v-if="detailCabang.infoPendirian">{{ detailCabang.infoPendirian.namaNotaris }}
                  </b-col>
                </b-row>
                <b-row>
                  <b-col sm="4">
                    <p style="font-weight: 600; margin:0px;">Nomor AKTA Pendirian</p>
                  </b-col>:<b-col sm="6" v-if="detailCabang.infoPendirian">{{ detailCabang.infoPendirian.nomorAkta }}
                  </b-col>
                </b-row>
                <b-row>
                  <b-col sm="4">
                    <p style="font-weight: 600">Tanggal AKTA Pendirian</p>
                  </b-col>:<b-col sm="6" v-if="detailCabang.infoPendirian">{{ detailCabang.infoPendirian.tanggalAkta |
                  moment("DD - MM - YYYY")}} </b-col>
                </b-row>
                <b-row>
                  <b-col sm="4">
                    <p style="font-weight: 600; margin:0px;">Nomor SK Susunan Pengurus</p>
                  </b-col>:<b-col sm="6" v-if="detailCabang.infoPendirian">{{ detailCabang.infoPendirian.nomorAkta }}
                  </b-col>
                </b-row>
                <b-row>
                  <b-col sm="4">
                    <p style="font-weight: 600">Tempat Pendirian</p>
                  </b-col>:<b-col sm="6" v-if="detailCabang.infoPendirian">{{ detailCabang.infoPendirian.tempatPendirian
                  }} </b-col>
                </b-row>
                <b-row>
                  <b-col sm="4">
                    <p style="font-weight: 600">Tanggal Pendirian</p>
                  </b-col>:<b-col sm="6" v-if="detailCabang.infoPendirian">{{
                  detailCabang.infoPendirian.tanggalPendirian | moment("DD - MM - YYYY") }} </b-col>
                </b-row>
                <b-row v-if="detailCabang.infoPendirian && detailCabang.infoPendirian.masterKesbangpol">
                  <b-col sm="4">
                    <p style="font-weight: 600">Nama Kesbangpol</p>
                  </b-col>:<b-col sm="6">{{ detailCabang.infoPendirian.masterKesbangpol.nama }} </b-col>
                </b-row>
                <b-row
                  v-if="detailCabang.infoPendirian && detailCabang.infoPendirian.masterKesbangpol && userType == 'admin'">
                  <b-col sm="4">
                    <p style="font-weight: 600">Email Kesbangpol</p>
                  </b-col>:<b-col sm="6">{{ detailCabang.infoPendirian.masterKesbangpol.email }} </b-col>
                </b-row>
                <b-row
                  v-if="detailCabang.infoPendirian && detailCabang.infoPendirian.masterKesbangpol && userType == 'admin'">
                  <b-col sm="4">
                    <p style="font-weight: 600">Nomor HP Kesbangpol</p>
                  </b-col>:<b-col sm="6">{{ detailCabang.infoPendirian.masterKesbangpol.nomorTelepon }} </b-col>
                </b-row>
              </b-col>
              <b-col cols="5">
              </b-col>
            </b-row>
          </b-tab>
          <b-tab title="Struktur Pengurus"
            style="background-color:white;margin-top:0px; padding-left:0px; padding-right:0px;">
            <b-card-text class="pl-0 pr-0">
              <div class="card-body pt-0 pb-3 pl-0 pr-0">
                <div class="tab-content">
                  <!--begin::Table-->
                  <div class="table-responsive">
                    <table class="
                table
                table-head-custom
                table-vertical-center
                table-head-bg
                table-bordered
              ">
                      <thead>
                        <tr class="text-left">
                          <th style="min-width: 100px; padding-left:0px; padding-right: 0px;" class="text-capitalize">
                            Foto</th>
                          <th style="min-width: 150px; padding-left:0px; padding-right: 0px;" class="text-capitalize">
                            Nama</th>
                          <th style="width:50px; padding-left:0px; padding-right: 0px;" class="text-capitalize">Jabatan
                          </th>
                          <th style="width: 190px; padding-left:0px; padding-right: 0px;" class="text-capitalize">Masa
                            Bakti</th>
                          <th v-if="userType == 'admin'" style="width: 150px; padding-left:0px; padding-right: 0px;"
                            class="text-capitalize">No. Identitas</th>
                          <th style="min-width: 100px; padding-left:0px; padding-right: 0px;" class="text-capitalize">
                            Tanggal Lahir</th>
                          <th v-if="userType == 'admin'" style="min-width: 100px; padding-left:0px; padding-right: 0px;"
                            class="text-capitalize">No. HP</th>
                        </tr>
                      </thead>
                      <tbody style="background:#FAFAFA;">
                        <template v-for="(item, i) in detailCabang.pengurusOrmas">
                          <tr v-bind:key="i">
                            <td>
                              <img v-if="item.foto !== null && item.foto !== ''" :src="fileURL + '/' + item.foto"
                                style="width:64px;height:64px;" />
                              <img v-else
                                src="/assets/images/default-thumbnail.jpg"
                                style="width: 60%; margin-bottom: 20px; margin-left: 8px;opacity:20%;" alt="abstract" />

                            </td>
                            <td>
                              <span class="
                          text-dark-75
                          font-weight-bolder
                          d-block
                          text-left
                          font-size-lg
                        ">{{ item ? item.nama_pengurus : '' }}</span>
                            </td>
                            <td>
                              <span class="
                          text-dark-75
                          font-weight-bolder
                          d-block
                          text-left
                          font-size-lg
                        ">{{ item.masterJabatan ? item.masterJabatan.nama_jabatan : '-' }}</span>
                            </td>
                            <td>
                              <span class="
                          text-dark-75
                          font-weight-bolder
                          d-block
                          text-left
                          font-size-lg
                        ">{{item.periode_mulai | moment('DD/MM/YYYY')}} - {{item.periode_selesai |
                        moment('DD/MM/YYYY')}}</span>
                            </td>
                            <td v-if="userType == 'admin'">
                              <span class="
                          text-dark-75
                          font-weight-bolder
                          d-block
                          text-left
                          font-size-lg
                        ">{{ item.identitas }}</span>
                            </td>
                            <td>
                              <span class="
                          text-dark-75
                          font-weight-bolder
                          d-block
                          text-left
                          font-size-lg
                        ">{{item.tanggal_lahir | moment("DD-MM-YYYY")}}</span>
                            </td>
                            <td v-if="userType == 'admin'">
                              <span class="
                          text-dark-75
                          font-weight-bolder
                          d-block
                          text-left
                          font-size-lg
                        ">{{ item.no_hp }}</span>
                            </td>
                          </tr>
                        </template>
                      </tbody>
                    </table>

                  </div>
                </div>
              </div>
            </b-card-text>
          </b-tab>
          <b-tab title="Rekening" style="background-color:white;margin-top:0px;">
            <div class="table-responsive">
              <table class="
                table
                table-head-custom
                table-vertical-center
                table-head-bg
                table-bordered
              ">
                <thead>
                  <tr class="text-left">
                    <th style="width: 20px" class="text-capitalize">No</th>
                    <th style="min-width: 50px" class="text-capitalize">Nomor Rekening</th>
                    <th style="min-width: 50px" class="text-capitalize">Nama Bank</th>
                    <th style="min-width: 50px" class="text-capitalize">Pemilik rekening</th>
                    <th style="min-width: 50px" class="text-capitalize">Jenis rekening</th>
                  </tr>
                </thead>
                <tbody v-if="detailCabang.rekeningOrmas.length == 0">
                  <template>
                    <tr>
                      <td colspan="6"> -- Tidak ada rekening -- </td>
                    </tr>
                  </template>
                </tbody>
                <tbody style="background:#FAFAFA;">
                  <template v-for="(item, i) in detailCabang.rekeningOrmas">
                    <tr v-bind:key="i">
                      <td>
                        <span class="
                    text-dark-75
                    font-weight-bolder
                    d-block
                    text-left
                    font-size-lg
                  ">{{ i + 1 }}</span>
                      </td>
                      <td>
                        <span class="
                    text-dark-75
                    font-weight-bolder
                    d-block
                    text-left
                    font-size-lg
                  ">{{item.no_rekening}}</span>
                      </td>
                      <td>
                        <span class="
                    text-dark-75
                    font-weight-bolder
                    d-block
                    text-left
                    font-size-lg
                  ">{{item.nama_bank}}</span>
                      </td>
                      <td>
                        <span class="
                    text-dark-75
                    font-weight-bolder
                    d-block
                    text-left
                    font-size-lg
                  ">{{item.nama_pemilik_rekening}}</span>
                      </td>
                      <td>
                        <span class="
                    text-dark-75
                    font-weight-bolder
                    d-block
                    text-left
                    font-size-lg
                  ">{{item.masterJenisRekening ? item.masterJenisRekening.nama : ''}}</span>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </b-tab>
        </b-tabs>
      </div>
    </b-modal>

    <b-modal id="modal-profil" ok-only centered size="xl" content-class="shadow" hide-header hide-footer>
      <div>
        <b-container fluid>
          <b-row class="pt-5 mt-2 pr-0 pl-0">
            <b-col class="d-flex justify-content-between pr-0 pl-0">
              <b style="font-size:20px;">
                Profil Ormas
              </b>
              <div>
                <b-button variant="outline-secondary" style="background: #FFFFFF;" size="sm" class="mr-4"
                  @click="$bvModal.hide('modal-profil')">
                  <v-icon class="pr-3">mdi-close</v-icon>
                  Tutup
                </b-button>
                <button style="color:white;" class="btn font-weight-bolder font-size-sm bg-hitam"
                  data-wizard-type="action-submit" v-if="isOrmas" @click="cetakPDFDetail(info_organization.id)">
                  <i class="fas fa-file-pdf" style="color:white;"></i>Cetak PDF
                </button>
              </div>
            </b-col>
          </b-row>
          <b-row class="pt-5">
            <b-tabs pills card id="detail-tab" justified v-model="tabDetail" style="border-radius:10px;width:100%;">

              <b-tab title="Profil" style="background-color:white;margin-top:0px;">
                <b-row>
                  <b-col cols="7">
                    <b-row>
                      <b-col sm="3">
                        <p style="font-weight: 600">{{ "Nama Ormas" }}</p>
                      </b-col>:<b-col sm="6" style="white-space: pre-wrap">{{ info_organization.nama_ormas}}</b-col>
                    </b-row>
                    <b-row>
                      <b-col sm="3">
                        <p style="font-weight: 600">{{ "Singkatan" }}</p>
                      </b-col>:<b-col sm="6">{{ info_organization.nama_singkat}}</b-col>
                    </b-row>
                    <b-row class="pt-0 pb-3 d-flex align-items-center">
                      <b-col sm="3">
                        <p style="font-weight: 600; margin:0px;">{{ "Nomor SKT Aktif" }}</p>
                      </b-col>:<b-col sm="6">{{ info_organization.no_skt}}
                        <b-button
                          v-if="userType == 'admin' && info_organization.file_skt != null && info_organization.file_skt != ''"
                          @click="newTab(info_organization.file_skt)" size="sm" variant="success">
                          <v-icon style="color:white;" small>mdi-eye</v-icon>Lihat File Scan
                        </b-button>
                      </b-col>
                    </b-row>
                    <b-row v-if="this.info_organization.skt_aktif == true && this.info_organization.m_jenis_akta_id != 2" class="pt-0 pb-3 d-flex align-items-center">
                      <b-col sm="3">
                        <p style="font-weight: 600; margin:0px;">{{ "Status SKT" }}</p>
                      </b-col>:<b-col sm="6">{{ 'Aktif' }}</b-col>
                    </b-row>
                    <b-row v-if="this.info_organization.skt_aktif == true && this.info_organization.m_jenis_akta_id == 2" class="pt-0 pb-3 d-flex align-items-center">
                      <b-col sm="3">
                        <p style="font-weight: 600; margin:0px;">{{ "Status SKT" }}</p>
                      </b-col>:<b-col sm="6">{{ 'Aktif Perubahan' }}</b-col>
                    </b-row>
                    <b-row v-if="this.info_organization.skt_aktif == false" class="pt-0 pb-3 d-flex align-items-center" style="color: red;">
                      <b-col sm="3">
                        <p style="font-weight: 600; margin:0px;">{{ "Status SKT" }}</p>
                      </b-col>:<b-col sm="6">{{ 'Tidak Aktif' }}</b-col>
                    </b-row>
                    <b-row class="pt-0 pb-3 d-flex align-items-center">
                      <b-col sm="3" :style="this.info_organization.skt_aktif == true ? '' : 'color:red;'">
                        <p style="font-weight: 600; margin:0px;">{{ "Tanggal Terbit SKT" }}</p>
                      </b-col>:
                      <b-col sm="6" :style="this.info_organization.skt_aktif == true ? '' : 'color:red;'">
                        {{ info_organization.tanggal_skt | moment('DD - MM - YYYY')}}
                      </b-col>
                    </b-row>
                    <b-row class="pt-0 pb-3 d-flex align-items-center">
                      <b-col sm="3" :style="this.info_organization.skt_aktif == true ? '' : 'color:red;'">
                        <p style="font-weight: 600; margin:0px;">{{ "Masa Berlaku SKT" }}</p>
                      </b-col>:
                      <b-col sm="6" :style="this.info_organization.skt_aktif == true ? '' : 'color:red;'">
                        {{tanggal_skt_end | moment('DD - MM - YYYY')}}
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col sm="3">
                        <p style="font-weight: 600">
                          {{ "Status Sekretariat" }}
                        </p>
                      </b-col>:<b-col sm="6" v-if="info_organization.masterLokasiSekretariat">{{
                      info_organization.masterLokasiSekretariat.nama_lokasi }}</b-col>
                    </b-row>
                    <b-row>
                      <b-col sm="3">
                        <p style="font-weight: 600">
                          {{ "Alamat sekretariat" }}
                        </p>
                      </b-col>:<b-col sm="6">{{ info_organization.alamat_sekretariat }}</b-col>
                    </b-row>
                    <b-row>
                      <b-col sm="3">
                        <p style="font-weight: 600">{{ "Kabupaten/Kota" }}</p>
                      </b-col>:<b-col sm="6"
                        v-if="info_organization.masterKabupatenKota && info_organization.masterKabupatenKota.nama">{{
                        info_organization.masterKabupatenKota.nama }}</b-col>
                    </b-row>
                    <b-row>
                      <b-col sm="3">
                        <p style="font-weight: 600">{{ "Provinsi" }}</p>
                      </b-col>:<b-col sm="6"
                        v-if="info_organization.masterProvinsi && info_organization.masterProvinsi.nama">{{
                        info_organization.masterProvinsi.nama }}</b-col>
                    </b-row>
                    <b-row v-if="userType == 'admin'">
                      <b-col sm="3">
                        <p style="font-weight: 600">{{ "Nomor Telepon" }}</p>
                      </b-col>:<b-col sm="6">{{ info_organization.no_telp }}</b-col>
                    </b-row>
                    <b-row v-if="userType == 'admin'">
                      <b-col sm="3">
                        <p style="font-weight: 600">{{ "Nomor FAX" }}</p>
                      </b-col>:<b-col sm="6">{{ info_organization.no_fax }}</b-col>
                    </b-row>
                    <b-row v-if="userType == 'admin'">
                      <b-col sm="3">
                        <p style="font-weight: 600">{{ "Email" }}</p>
                      </b-col>:<b-col sm="6">{{ info_organization.email != null && info_organization.email != '' ?
                      info_organization.email : '-' }}</b-col>
                    </b-row>
                    <b-row>
                      <b-col sm="3">
                        <p style="font-weight: 600">
                          {{ "Tujuan organisasi" }}
                        </p>
                      </b-col>:
                      <b-col sm="6">
                        <ul class="ml-5">
                          <li v-for="(item,index) in info_organization.tujuanOrmas" :key="index">{{ item != undefined ?
                          item.keterangan : '-' }}</li>
                        </ul>
                      </b-col>
                    </b-row>

                    <b-row class="d-flex align-items-center">
                      <b-col sm="3">
                        <p style="font-weight: 600; margin:0px;">{{ "NPWP" }}</p>
                      </b-col>:<b-col sm="6">{{ info_organization.npwp }}
                        <b-button
                          v-if="userType == 'admin' && info_organization.file_npwp != null && info_organization.file_npwp != ''"
                          @click="newTab(info_organization.file_npwp)" size="sm" variant="success">
                          <v-icon style="color:white;" small>mdi-eye</v-icon>
                          Lihat File Scan
                        </b-button>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col sm="3">
                        <p style="font-weight: 600">{{ "Ciri khusus" }}</p>
                      </b-col>:<b-col sm="6" v-if="info_organization.masterCiriKhusus">{{
                      info_organization.masterCiriKhusus.nama_ciri_khusus }}</b-col>
                    </b-row>
                    <b-row>
                      <b-col sm="3">
                        <p style="font-weight: 600">{{ "Bidang Kegiatan" }}</p>
                      </b-col>:<b-col sm="6">
                        <ul class="ml-5">
                          <li v-for="(item,index) in info_organization.bidangKegiatans" :key="index">{{
                          item.masterJenisOrmas.nama_jenis_ormas }}</li>
                        </ul>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col sm="3">
                        <p style="font-weight: 600">{{ "Wadah Berhimpun" }}</p>
                      </b-col>:<b-col sm="6">
                        <ul class="ml-5"
                          v-if="info_organization.berhimpunOtonom && info_organization.berhimpunOtonom.length > 0">
                          <span v-for="(item,index) in info_organization.berhimpunOtonom[0]" :key="index">
                            <li v-if="item.jenis == 1">{{ item.afiliasiOrmas.nama_ormas }}</li>
                          </span>
                        </ul>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col sm="3">
                        <p style="font-weight: 600">{{ "Badan Otonom / Sayap Organisasi" }}</p>
                      </b-col>:<b-col sm="6">
                        <ul class="ml-5"
                          v-if="info_organization.berhimpunOtonom && info_organization.berhimpunOtonom.length > 0">
                          <span v-for="(item,index) in info_organization.berhimpunOtonom[0]" :key="index">
                            <li v-if="item.jenis == 2">{{ item.afiliasiOrmas.nama_ormas }}</li>
                          </span>
                        </ul>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="5">
                    <b-row class="text-right">
                      <img v-if="info_organization.file_logo !== null"
                        :src="fileURL + '/' + info_organization.file_logo"
                        style="width: 60%; margin-bottom: 20px; margin-left: 8px;opacity:20%;" alt="abstract"
                        @error="replaceByDefault" />
                      <img v-else src="https://alppetro.co.id/dist/assets/images/default.jpg"
                        style="width: 60%; margin-bottom: 20px; margin-left: 8px;opacity:20%;" alt="abstract" />

                      <img v-if="info_organization.file_bendera !== null"
                        :src="fileURL + '/' + info_organization.file_bendera"
                        style="width: 60%; margin-bottom: 20px; margin-left: 8px;opacity:20%;" alt="abstract"
                        @error="replaceByDefault" />
                      <img v-else src="https://alppetro.co.id/dist/assets/images/default.jpg"
                        style="width: 60%; margin-bottom: 20px; margin-left: 8px;opacity:20%;" alt="abstract" />
                    </b-row>
                  </b-col>
                </b-row>
              </b-tab>
              <b-tab title="Info Pendirian" style="background-color:white;margin-top:0px;">
                <b-row>
                  <b-col cols="7">
                    <b-row>
                      <b-col sm="4">
                        <p style="font-weight: 600">Nama Notaris</p>
                      </b-col>:<b-col sm="6">{{ founder_organization.namaNotaris}} </b-col>
                    </b-row>
                    <b-row class="d-flex align-items-center">
                      <b-col sm="4">
                        <p style="font-weight: 600; margin:0px;">Nomor AKTA Pendirian</p>
                      </b-col>:<b-col sm="6">{{ founder_organization.nomorAkta }}
                        <b-button
                          v-if="userType == 'admin' && founder_organization.file_akta != null && founder_organization.file_akta != ''"
                          @click="newTab(founder_organization.file_akta)" size="sm" variant="success">
                          <v-icon style="color:white;" small>mdi-eye</v-icon>Lihat File Scan
                        </b-button>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col sm="4">
                        <p style="font-weight: 600">Tanggal AKTA Pendirian</p>
                      </b-col>:<b-col sm="6">{{ founder_organization.tanggalAkta | moment("DD - MM - YYYY") }} </b-col>
                    </b-row>
                    <b-row>
                      <b-col sm="4">
                        <p style="font-weight: 600">Tempat Pendirian</p>
                      </b-col>:<b-col sm="6">{{ founder_organization.wilayahProvinsi ?
                      founder_organization.wilayahProvinsi.nama : '' }} - {{ founder_organization.wilayahKabupatenKota
                      ? founder_organization.wilayahKabupatenKota.nama : '' }} </b-col>
                    </b-row>
                    <b-row>
                      <b-col sm="4">
                        <p style="font-weight: 600">Tanggal Pendirian</p>
                      </b-col>:<b-col sm="6">{{ founder_organization.tanggalPendirian | moment("DD - MM - YYYY") }}
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col sm="4">
                        <p style="font-weight: 600; margin:0px;">Nomor Surat Pendaftaran</p>
                      </b-col>:<b-col sm="6">{{ founder_organization.nomorSuratPendaftaran }}
                        <b-button
                          v-if="userType == 'admin' && founder_organization.file_surat_pendaftaran != null && founder_organization.file_surat_pendaftaran != ''"
                          @click="newTab(founder_organization.file_surat_pendaftaran)" size="sm" variant="success">
                          <v-icon style="color:white;" small>mdi-eye</v-icon>Lihat File Scan
                        </b-button>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col sm="4">
                        <p style="font-weight: 600">Tanggal Surat Pendaftaran</p>
                      </b-col>:<b-col sm="6">{{ founder_organization.tanggalSuratPendaftaran | moment("DD - MM - YYYY")
                      }} </b-col>
                    </b-row>
                    <b-row
                      v-if="founder_organization.wilayahProvinsi && founder_organization.wilayahProvinsi.masterKesbangpolProv">
                      <b-col sm="4">
                        <p style="font-weight: 600">Nama Kesbangpol Provinsi</p>
                      </b-col>:<b-col sm="6">{{ founder_organization.wilayahProvinsi.masterKesbangpolProv.nama }}
                      </b-col>
                    </b-row>
                    <b-row
                      v-if="founder_organization.wilayahProvinsi && founder_organization.wilayahProvinsi.masterKesbangpolProv && userType == 'admin'">
                      <b-col sm="4">
                        <p style="font-weight: 600">Email Kesbangpol Provinsi</p>
                      </b-col>:<b-col sm="6">{{ founder_organization.wilayahProvinsi.masterKesbangpolProv.email }}
                      </b-col>
                    </b-row>
                    <b-row
                      v-if="founder_organization.wilayahProvinsi && founder_organization.wilayahProvinsi.masterKesbangpolProv && userType == 'admin'">
                      <b-col sm="4">
                        <p style="font-weight: 600">Nomor HP Kesbangpol Provinsi</p>
                      </b-col>:<b-col sm="6">{{ founder_organization.wilayahProvinsi.masterKesbangpolProv.nomorTelepon
                      }} </b-col>
                    </b-row>

                    <b-row
                      v-if="founder_organization.wilayahKabupatenKota && founder_organization.wilayahKabupatenKota.masterKesbangpolKab">
                      <b-col sm="4">
                        <p style="font-weight: 600">Nama Kesbangpol Kabupaten/Kota</p>
                      </b-col>:<b-col sm="6">{{ founder_organization.wilayahKabupatenKota.masterKesbangpolKab.nama }}
                      </b-col>
                    </b-row>
                    <b-row
                      v-if="founder_organization.wilayahProvinsi && founder_organization.wilayahProvinsi.masterKesbangpolKab && userType == 'admin'">
                      <b-col sm="4">
                        <p style="font-weight: 600">Email Kesbangpol Kabupaten/Kota</p>
                      </b-col>:<b-col sm="6">{{ founder_organization.wilayahKabupatenKota.masterKesbangpolKab.email }}
                      </b-col>
                    </b-row>
                    <b-row
                      v-if="founder_organization.wilayahKabupatenKota && founder_organization.wilayahKabupatenKota.masterKesbangpolKab && userType == 'admin'">
                      <b-col sm="4">
                        <p style="font-weight: 600">Nomor HP Kesbangpol Kabupaten/Kota</p>
                      </b-col>:<b-col sm="6">{{
                      founder_organization.wilayahKabupatenKota.masterKesbangpolKab.nomorTelepon }} </b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="5">
                    <b-row>
                      <img v-if="info_organization.file_logo !== null"
                        :src="fileURL + '/' + info_organization.file_logo"
                        style="width: 60%; margin-bottom: 20px; margin-left: 8px;opacity:20%;" alt="abstract"
                        @error="replaceByDefault" />
                      <img v-else src="https://alppetro.co.id/dist/assets/images/default.jpg"
                        style="width: 60%; margin-bottom: 20px; margin-left: 8px;opacity:20%;" alt="abstract" />

                      <img v-if="info_organization.file_bendera !== null"
                        :src="fileURL + '/' + info_organization.file_bendera"
                        style="width: 60%; margin-bottom: 20px; margin-left: 8px;opacity:20%;" alt="abstract"
                        @error="replaceByDefault" />
                      <img v-else src="https://alppetro.co.id/dist/assets/images/default.jpg"
                        style="width: 60%; margin-bottom: 20px; margin-left: 8px;opacity:20%;" alt="abstract" />
                    </b-row>
                  </b-col>
                </b-row>
              </b-tab>
              <b-tab title="Struktur Ormas" style="background-color:white;margin-top:0px;">
                <div class="tab-content">
                  <!--begin::Table-->
                  <div class="table-responsive">
                    <table class="
                table
                table-head-custom
                table-vertical-center
                table-head-bg
                table-bordered
              ">
                      <thead>
                        <tr class="text-left">
                          <th style="width: 100px; padding-left:0px; padding-right: 0px;" class="text-capitalize">Foto1
                          </th>
                          <th style="width: 170px; padding-left:0px; padding-right: 0px;" class="text-capitalize">Nama
                          </th>
                          <th style="width: 200px; padding-left:0px; padding-right: 0px;" class="text-capitalize">Masa
                            Bakti</th>
                          <th style="width: 90px; padding-left:0px; padding-right: 0px;" class="text-capitalize">Jabatan
                          </th>
                          <th v-if="userType == 'admin'" style="width: 100px; padding-left:0px; padding-right: 0px;"
                            class="text-capitalize">No. Identitas</th>
                          <th style="width: 100px; padding-left:0px; padding-right: 0px;" class="text-capitalize">
                            Tanggal Lahir</th>
                          <th v-if="userType == 'admin'" style="width: 100px; padding-left:0px; padding-right: 0px;"
                            class="text-capitalize">No. HP</th>
                          <th v-if="userType == 'admin'" style="width: 100px; padding-left:0px; padding-right: 0px;"
                            class="text-capitalize">KTP</th>
                        </tr>
                      </thead>
                      <tbody style="background:#FAFAFA;">
                        <template v-for="(item, i) in structure_organization.pengurusInti">
                          <tr v-bind:key="i">
                            <td>
                              <img v-if="item.foto !== null && item.foto !== ''" :src="fileURL + '/' + item.foto"
                                style="width:64px;height:64px;" />
                              <img v-else
                                src="/assets/images/default-thumbnail.jpg"
                                style="width: 60%; margin-bottom: 20px; margin-left: 8px;opacity:20%;" alt="abstract" />


                            </td>
                            <td>
                              <span class="
                          text-dark-75
                          font-weight-bolder
                          d-block
                          text-left
                          font-size-lg
                        ">{{ item ? item.nama_pengurus : '' }}</span>
                            </td>
                            <td>
                              <span class="
                          text-dark-75
                          font-weight-bolder
                          d-block
                          text-left
                          font-size-lg
                        ">{{ item.periode_mulai | moment("DD/MM/YYYY") }} - {{ item.periode_selesai |
                        moment("DD/MM/YYYY") }}</span>
                            </td>
                            <td>
                              <span class="
                          text-dark-75
                          text-left
                          font-weight-bolder
                          d-block
                          font-size-lg
                        ">{{ item.masterJabatan ? item.masterJabatan.nama_jabatan : '-' }}</span>
                            </td>
                            <td v-if="userType == 'admin'">
                              <span class="
                          text-left
                          text-dark-75
                          font-weight-bolder
                          d-block
                          font-size-lg
                        ">{{ item.identitas }}</span>
                            </td>
                            <td>
                              <span class="
                          text-dark-75
                          text-left
                          font-weight-bolder
                          d-block
                          font-size-lg
                        ">{{item.tanggal_lahir | moment("DD-MM-YYYY")}}</span>
                            </td>
                            <td v-if="userType == 'admin'">
                              <span class="
                          text-dark-75
                          font-weight-bolder
                          text-left
                          d-block
                          font-size-lg
                        ">{{ item.no_hp }}</span>
                            </td>
                            <td v-if="userType == 'admin'">
                              <b-button v-if="item.file_identitas != null" @click="newTab(item.file_identitas)"
                                size="sm" variant="success">
                                <v-icon style="color:white;" small>
                                  mdi-eye
                                </v-icon>
                                Lihat File
                              </b-button>
                            </td>
                          </tr>
                        </template>
                      </tbody>
                    </table>
                  </div>

                  <!--end::Table-->
                  <div class="mt-5" v-if="structure_organization">
                    <b-row v-if="userType == 'admin'" class="d-flex align-items-center">
                      <b-col sm="4">
                        <p style="font-weight: 600; margin:0px;">SK Susunan Pengurus</p>
                      </b-col>:<b-col sm="6">
                        <b-button
                          v-if="userType == 'admin' && info_organization.file_sk_pengurus != null && info_organization.file_sk_pengurus != ''"
                          @click="newTab(info_organization.file_sk_pengurus)" size="sm" variant="success">
                          <v-icon style="color:white;" small>mdi-eye</v-icon>Lihat File Scan
                        </b-button>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col sm="4">
                        <p style="font-weight: 600">Daftar Pendiri Organisasi</p>
                      </b-col>:<b-col sm="6">
                        <span v-for="(item,index) in structure_organization.listPengurus" :key="index">
                          <span
                            v-if="item.masterKategoriPengurus.kode_kategori_pengurus == 'pendiri'">{{item ? item.nama_pengurus : ''}};</span>
                        </span>
                      </b-col>
                    </b-row>
                    <b-row v-if="userType == 'admin'">
                      <b-col sm="4">
                        <p style="font-weight: 600">NIK daftar Pendiri Organisasi</p>
                      </b-col>:<b-col sm="6">
                        <span v-for="(item,index) in structure_organization.listPengurus" :key="index">
                          <span
                            v-if="item.masterKategoriPengurus.kode_kategori_pengurus == 'pendiri'">{{item.identitas}};</span>
                        </span>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col sm="4">
                        <p style="font-weight: 600">Daftar Pembina Organisasi</p>
                      </b-col>:<b-col sm="6">
                        <span v-for="(item,index) in structure_organization.listPengurus" :key="index">
                          <span
                            v-if="item.masterKategoriPengurus.kode_kategori_pengurus == 'pembina'">{{item ? item.nama_pengurus : ''}};</span>
                        </span>
                      </b-col>
                    </b-row>
                    <b-row v-if="userType == 'admin'">
                      <b-col sm="4">
                        <p style="font-weight: 600">NIK daftar Pembina Organisasi</p>
                      </b-col>:<b-col sm="6">
                        <span v-for="(item,index) in structure_organization.listPengurus" :key="index">
                          <span
                            v-if="item.masterKategoriPengurus.kode_kategori_pengurus == 'pembina'">{{item.identitas}};</span>
                        </span>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col sm="4">
                        <p style="font-weight: 600">Daftar Penasehat Organisasi</p>
                      </b-col>:<b-col sm="6">
                        <span v-for="(item,index) in structure_organization.listPengurus" :key="index">
                          <span
                            v-if="item.masterKategoriPengurus.kode_kategori_pengurus == 'penasehat'">{{item ? item.nama_pengurus : ''}};</span>
                        </span>
                      </b-col>
                    </b-row>
                    <b-row v-if="userType == 'admin'">
                      <b-col sm="4">
                        <p style="font-weight: 600">NIK daftar Penasehat Organisasi</p>
                      </b-col>:<b-col sm="6">
                        <span v-for="(item,index) in structure_organization.listPengurus" :key="index">
                          <span
                            v-if="item.masterKategoriPengurus.kode_kategori_pengurus == 'penasehat'">{{item.identitas}};</span>
                        </span>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col sm="4">
                        <p style="font-weight: 600">Daftar Pelindung Organisasi</p>
                      </b-col>:<b-col sm="6">
                        <span v-for="(item,index) in structure_organization.listPengurus" :key="index">
                          <span
                            v-if="item.masterKategoriPengurus.kode_kategori_pengurus == 'pelindung'">{{item ? item.nama_pengurus : ''}};</span>
                        </span>
                      </b-col>
                    </b-row>
                    <b-row v-if="userType == 'admin'">
                      <b-col sm="4">
                        <p style="font-weight: 600">NIK daftar Pelindung Organisasi</p>
                      </b-col>:<b-col sm="6">
                        <span v-for="(item,index) in structure_organization.listPengurus" :key="index">
                          <span
                            v-if="item.masterKategoriPengurus.kode_kategori_pengurus == 'pelindung'">{{item.identitas}};</span>
                        </span>
                      </b-col>
                    </b-row>

                  </div>
                </div>
              </b-tab>
              <b-tab v-if="userType == 'admin'" title="Dokumen Ormas" style="background-color:white;margin-top:0px;">
                <div class="table-responsive">
                  <table class="
                    table
                    table-head-custom
                    table-vertical-center
                    table-head-bg
                    table-bordered
                  ">
                    <thead>
                      <tr class="text-left text-capitalize">
                        <th style="width: 20px" class="text-capitalize">No</th>
                        <th style="min-width: 50px;" class="text-capitalize">Deskripsi</th>
                        <th style="min-width: 200px" class="text-capitalize">Aksi</th>
                      </tr>
                    </thead>
                    <tbody style="background:#FAFAFA;">
                      <template v-for="(item, i) in document_organization">
                        <tr v-bind:key="i">
                          <td>
                            <span class="
                        text-dark-75
                        font-weight-bolder
                        d-block
                        font-size-lg
                      ">{{ i + 1 }}</span>
                          </td>
                          <td style="max-width:50%;">
                            <span class="
                        text-dark-75
                        text-left
                        font-weight-bolder
                        d-block
                        text-left
                        font-size-lg
                      ">{{ item.nama_jenis_dokumen }}</span>
                          </td>
                          <td class="d-flex justify-content-center">
                            <section v-if="item.dokumenOrmas">
                              <span v-for="(nn,ni) in item.dokumenOrmas.slice(0,1)" :key="ni">
                                <b-button size="sm" variant="success" @click="newTabDoc(item.dokumenOrmas)">
                                  <v-icon style="color:white;" small>mdi-eye</v-icon><span>Lihat</span>
                                </b-button>
                              </span>
                            </section>
                            <b-button v-if="!item.dokumenOrmas" disabled size="sm" variant="success"
                              @click="newTabDoc(item.dokumenOrmas)">
                              <v-icon style="color:white;" small>mdi-eye</v-icon><span>Lihat</span>
                            </b-button>
                          </td>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                </div>
              </b-tab>
              <b-tab title="Rekening" style="background-color:white;margin-top:0px;">
                <div class="table-responsive">
                  <table class="
                    table
                    table-head-custom
                    table-vertical-center
                    table-head-bg
                    table-bordered
                  ">
                    <thead>
                      <tr class="text-left">
                        <th style="width: 20px">No</th>
                        <th style="min-width: 50px" class="text-capitalize">Nomor Rekening</th>
                        <th style="min-width: 50px" class="text-capitalize">Nama Bank</th>
                        <th style="min-width: 50px" class="text-capitalize">Pemilik rekening</th>
                        <th style="min-width: 50px" class="text-capitalize">Jenis rekening</th>
                      </tr>
                    </thead>
                    <tbody v-if="bank_account_organization.length == 0">
                      <template>
                        <tr>
                          <td colspan="6"> -- Tidak ada rekening -- </td>
                        </tr>
                      </template>
                    </tbody>
                    <tbody style="background:#FAFAFA;">
                      <template v-for="(item, i) in bank_account_organization">
                        <tr v-bind:key="i">
                          <td>
                            <span class="
                        text-dark-75
                        font-weight-bolder
                        d-block
                        font-size-lg
                      ">{{ i + 1 }}</span>
                          </td>
                          <td>
                            <span class="
                        text-dark-75
                        text-left
                        font-weight-bolder
                        d-block
                        font-size-lg
                      ">{{item.no_rekening}}</span>
                          </td>
                          <td>
                            <span class="
                        text-dark-75
                        font-weight-bolder
                        text-left
                        d-block
                        font-size-lg
                      ">{{item.nama_bank}}</span>
                          </td>
                          <td>
                            <span class="
                        text-dark-75
                        font-weight-bolder
                        text-left
                        d-block
                        font-size-lg
                      ">{{item.nama_pemilik_rekening}}</span>
                          </td>
                          <td>
                            <span v-if="item.masterJenisRekening" class="
                        text-dark-75
                        text-left
                        font-weight-bolder
                        d-block
                        font-size-lg
                      ">{{item.masterJenisRekening.nama}}</span>
                          </td>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                </div>
              </b-tab>
              <b-tab title="Cabang Ormas" v-if="isOrmas" style="background-color:white;margin-top:0px;">
                <div class="table-responsive" style="border-radius:15px;">
                  <table class="
                  table
                  table-head-custom
                  table-vertical-center
                  table-head-bg
                  table-bordered
                " style="font-size:14px !important;">
                    <thead>
                      <tr class="text-left">
                        <th style="width: 20px" class="text-capitalize">No.</th>
                        <th style="min-width: 50px" class="text-capitalize">Status Sekretariat</th>
                        <th style="min-width: 50px" class="text-capitalize">Alamat</th>
                        <th style="min-width: 50px" class="text-capitalize">Kabupaten/Kota</th>
                        <th style="min-width: 50px" class="text-capitalize">Provinsi</th>
                        <th style="min-width: 50px" class="text-capitalize">Status</th>
                        <th style="min-width: 50px" class="text-capitalize">Aksi</th>
                      </tr>
                    </thead>
                    <tbody v-if="listCabang.length == 0">
                      <template>
                        <tr>
                          <td colspan="6"> -- Tidak ada cabang -- </td>
                        </tr>
                      </template>
                    </tbody>
                    <tbody style="background:white;">
                      <template v-for="(item, i) in listCabang">
                        <tr v-bind:key="i">
                          <td>
                            <span class="
                            text-dark-75
                            font-weight-bolder
                            d-block
                            font-size-lg
                          ">{{ i + 1}}</span>
                          </td>
                          <td>
                            <span class="
                            text-dark-75
                            text-left
                            font-weight-bolder
                            d-block
                            font-size-lg
                          ">{{ item.masterLokasiSekretariat.nama_lokasi}}</span>
                          </td>
                          <td>
                            <span class="
                            text-dark-75
                            font-weight-bolder
                            d-block
                            text-left
                            font-size-lg
                          " v-if="item.alamat_sekretariat">{{ item.alamat_sekretariat.length > 30 ?
                          item.alamat_sekretariat.substring(0,30) + "..." : item.alamat_sekretariat }}</span>
                          </td>
                          <td>
                            <span class="
                            text-dark-75
                            font-weight-bolder
                            d-block
                            text-left
                            font-size-lg
                          " v-if="item.wilayahKabupaten">{{ item.wilayahKabupaten.nama }}</span>
                          </td>
                          <td>
                            <span class="
                            text-dark-75
                            text-left
                            font-weight-bolder
                            d-block
                            font-size-lg
                          " v-if="item.wilayahProvinsi">{{ item.wilayahProvinsi.nama }}</span>
                          </td>
                          <td>
                            <span class="
                            text-dark-75
                            font-weight-bolder
                            text-left
                            d-block
                            font-size-lg
                          ">{{ item.status_cabang == 0 ? 'Belum Lapor' : 'Sudah Lapor' }}</span>
                          </td>
                          <td>
                            <b-button variant="success" size="sm" @click="getDetailCabang(item.id)">
                              >> Detail
                            </b-button>
                          </td>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                </div>
              </b-tab>
              <b-tab title="Pelaporan Kegiatan" style="background-color:white;margin-top:0px;">
                <div class="table-responsive">
                  <table class="
                    table
                    table-head-custom
                    table-vertical-center
                    table-head-bg
                    table-bordered
                  ">
                    <thead>
                      <tr class="text-left">
                        <th style="width: 20px" class="text-capitalize">No</th>
                        <th style="min-width: 50px" class="text-capitalize">Nama Kegiatan</th>
                        <th style="min-width: 50px" class="text-capitalize">Jenis Kegiatan</th>
                        <th style="min-width: 50px" class="text-capitalize">Tempat Kegiatan</th>
                        <th style="min-width: 50px" class="text-capitalize">Tanggal Kegiatan</th>
                        <th style="min-width: 50px" class="text-capitalize">Sumber Dana</th>
                        <th style="min-width: 50px" class="text-capitalize">Aksi</th>
                      </tr>
                    </thead>
                    <tbody v-if="listKegiatanOrmas.length == 0">
                      <template>
                        <tr>
                          <td colspan="6"> -- Tidak ada kegiatan -- </td>
                        </tr>
                      </template>
                    </tbody>
                    <tbody style="background:#FAFAFA;">
                      <template v-for="(item, i) in listKegiatanOrmas">
                        <tr v-bind:key="i">
                          <td>
                            <span class="
                        text-dark-75
                        font-weight-bolder
                        d-block
                        text-left
                        font-size-lg
                      ">{{ i + 1 }}</span>
                          </td>
                          <td>
                            <span class="
                        text-dark-75
                        font-weight-bolder
                        d-block
                        text-left
                        font-size-lg
                      ">{{ item.nama_kegiatan}}</span>
                          </td>
                          <td>
                            <span class="
                        text-dark-75
                        font-weight-bolder
                        d-block
                        text-left
                        font-size-lg
                      ">{{item.masterJenisKegiatan.nama_jenis_kegiatan}}</span>
                          </td>
                          <td>
                            <span class="
                        text-dark-75
                        font-weight-bolder
                        d-block
                        text-left
                        font-size-lg
                      ">{{item.tempat_kegiatan}}</span>
                          </td>
                          <td>
                            <span class="
                        text-dark-75
                        font-weight-bolder
                        d-block
                        text-left
                        font-size-lg
                      ">{{ item.tanggal_kegiatan | moment('DD-MM-YYYY')}}</span>
                          </td>
                          <td>
                            <span class="
                        text-dark-75
                        font-weight-bolder
                        d-block
                        text-left
                        font-size-lg
                      ">{{item.masterSumberDana.nama_sumber_dana}}</span>
                          </td>
                          <td>
                            <b-button variant="success" size="sm" @click="showDetailKegiatan(item)">
                              >> Detail
                            </b-button>
                          </td>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                </div>
              </b-tab>
              <b-tab title="Riwayat Permohonan" style="background-color:white;margin-top:0px;">
                <div class="table-responsive">
                  <table class="
                    table
                    table-head-custom
                    table-vertical-center
                    table-head-bg
                    table-bordered
                  ">
                    <thead>
                      <tr class="text-left">
                        <th style="width: 20px" class="text-capitalize">No</th>
                        <th style="min-width: 50px" class="text-capitalize">Jenis Perubahan</th>
                        <th style="min-width: 50px" class="text-capitalize">Tanggal Pengajuan</th>
                        <th style="min-width: 50px" class="text-capitalize">Tanggal Persetujuan</th>
                        <th style="min-width: 50px" class="text-capitalize">Status Permohonan</th>
                        <th style="min-width: 50px" class="text-capitalize">Aksi</th>
                      </tr>
                    </thead>
                    <tbody v-if="listRiwayat.length == 0">
                      <template>
                        <tr>
                          <td colspan="6"> -- Tidak ada permohonan -- </td>
                        </tr>
                      </template>
                    </tbody>
                    <tbody v-else style="background:white;">
                      <template v-for="(item, i) in listRiwayat">
                        <tr v-bind:key="i">
                          <td>
                            <span class="
                            text-dark-75
                            font-weight-bolder
                            d-block
                            font-size-lg
                          ">{{ i + 1}}</span>
                          </td>
                          <td class="text-left">
                            <span v-for="val in item.mJenisPerubahanValues" :key="val" class="
                            text-dark-75
                            font-weight-bolder
                            text-left
                            font-size-lg
                          "> {{val}},
                            </span>
                          </td>
                          <td class="text-left">
                            <span class="
                            text-dark-75
                            font-weight-bolder
                            d-block
                            text-left
                            font-size-lg
                          ">{{ item.tanggalPerubahan | moment('DD MMMM YYYY') }}</span>
                          </td>
                          <td class="text-left">
                            <span class="
                            text-dark-75
                            font-weight-bolder
                            d-block
                            text-left
                            font-size-lg
                          ">{{ item.tanggalPersetujuan | moment('DD MMMM YYYY')}}</span>
                          </td>
                          <td class="text-left">
                            <span class="
                            text-dark-75
                            text-left
                            font-weight-bolder
                            d-block
                            font-size-lg
                          ">{{ item.statusValues }}</span>
                          </td>
                          <td>
                            <section>
                              <b-button variant="success" size="sm" style="width:80px;" @click="detailPerubahan(item)">
                                >> Detail
                              </b-button>
                            </section>
                          </td>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                </div>
              </b-tab>
              <b-tab disabled title="Riwayat Perubahan" v-if="this.info_organization.m_jenis_akta_id == 2" style="background-color:white;margin-top:0px;">
                
              </b-tab>
            </b-tabs>
          </b-row>
        </b-container>
      </div>
    </b-modal>

    <b-modal id="modal-detail-riwayat" ok-only centered size="xl" content-class="shadow" hide-header hide-footer
      style="background-color:#FAFAFA;">
      <div style="background-color:#FAFAFA;" v-if="det && ormasIdentify">
        <b-container>
          <b-row class="pb-5 mb-2">
            <b-col cols="12">
              <b-row class="pt-5 mt-2">
                <b-col class="d-flex justify-content-between">
                  <b style="font-size:20px;">
                    Detail Perubahan Ormas
                  </b>
                  <div>
                    <b-button variant="outline-secondary" style="background: #FFFFFF;" size="sm"
                      @click="$bvModal.hide('modal-detail-riwayat')">
                      <v-icon class="pr-3">mdi-close</v-icon>
                      Tutup
                    </b-button>
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>


          <b-row class="mb-2">
            <b-col cols="2" style="font-weight:600;">
              Jenis Perubahan
            </b-col>
            <b-col class="d-flex">
              : &nbsp;<span v-for="(item,index) in det.jenisPerubahan.mJenisPerubahanIds" :key="index">
                {{ item == 1 ? 'Profil, ' : '' }}
                {{ item == 2 ? 'Struktur, ' : '' }}
                {{ item == 3 ? 'Rekening, ' : '' }}
                {{ item == 4 ? 'Cabang' : '' }}
              </span>
            </b-col>
          </b-row>
          <b-row class="mb-2">
            <b-col cols="2" style="font-weight:600;">
              Tanggal Pengajuan
            </b-col>
            <b-col>
              : {{ det.jenisPerubahan.tanggalPerubahan | moment('DD MMMM YYYY')}}
            </b-col>
          </b-row>
          <b-row class="mb-2">
            <b-col cols="2" style="font-weight:600;">
              Tanggal Persetujuan
            </b-col>
            <b-col>
              : {{ det.jenisPerubahan.tanggalPerubahan | moment('DD MMMM YYYY')}}
            </b-col>
          </b-row>
          <b-row class="mb-2">
            <b-col cols="2" style="font-weight:600;">
              Status Permohonan
            </b-col>
            <b-col>
              : {{ det.jenisPerubahan.status == 0 || det.jenisPerubahan.status == 4 ? 'Sedang Ditinjau' : '' }}
              {{ det.jenisPerubahan.status == 1 ? 'Diterima' : '' }}
              {{ det.jenisPerubahan.status == 2 ? 'Ditolak' : '' }}
            </b-col>
          </b-row>

          <b-tabs pills card justified v-model="tabDetailRiwayat" class="pt-5" style="border-radius:10px;">

            <b-tab title="Data Saat Ini" style="background-color:white;margin-top:0px;">
              <b-row class="mb-3">
                <b-col>
                  <b style="font-size:16px;">
                    Profil
                  </b>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="7">
                  <b-row class="mb-2">
                    <b-col cols="4" style="font-weight:600;">
                      Nomor Telepon
                    </b-col>
                    <b-col v-if="currentData.profil">
                      : {{ currentData.profil.no_telp}}
                    </b-col>
                  </b-row>
                  <b-row class="mb-2">
                    <b-col cols="4" style="font-weight:600;">
                      Nomor Fax
                    </b-col>
                    <b-col v-if="currentData.profil">
                      : {{ currentData.profil.no_fax}}
                    </b-col>
                  </b-row>
                  <b-row class="mb-2">
                    <b-col cols="4" style="font-weight:600;">
                      Ciri Khusus
                    </b-col>
                    <b-col v-if="currentData.profil">
                      : {{ currentData.profil.masterCiriKhusus.nama_ciri_khusus}}
                    </b-col>
                  </b-row>
                  <b-row class="mb-2">
                    <b-col cols="4" style="font-weight:600;">
                      Bidang Kegiatan
                    </b-col>
                    <b-col class="d-flex">
                      : &nbsp;<ul class="ml-5" v-if="currentData.profil && currentData.profil.bidangKegiatans">
                        <li v-for="(item,index) in currentData.profil.bidangKegiatans" :key="index">{{
                        item.masterJenisOrmas.nama_jenis_ormas }}</li>
                      </ul>
                    </b-col>
                  </b-row>
                  <b-row class="mb-2">
                    <b-col cols="4" style="font-weight:600;">
                      Wadah Berhimpun
                    </b-col>
                    <b-col v-if="currentData.berhimpunOtonom && currentData.berhimpunOtonom.wadahBerhimpun">
                      : {{ currentData.berhimpunOtonom.wadahBerhimpun.afiliasiOrmas.nama_ormas}}
                    </b-col>
                  </b-row>

                  <b-row class="mb-3 mt-5">
                    <b-col style="font-weight:600;">
                      Badan Otonom / Sayap Organisasi
                    </b-col>
                  </b-row>

                  <b-row class="mb-2">
                    <b-col>
                      <div class="table-responsive">
                        <table class="
                                  table
                                  table-head-custom
                                  table-vertical-center
                                  table-head-bg
                                  table-bordered
                                ">
                          <thead>
                            <tr class="text-left">
                              <th style="width: 20px" class="text-capitalize">No.</th>
                              <th style="min-width: 190px" class="text-capitalize">Nama Organisasi</th>
                              <th style="min-width: 100px" class="text-capitalize">Nomor Registrasi</th>
                            </tr>
                          </thead>
                          <tbody style="background:#FAFAFA;"
                            v-if="currentData.berhimpunOtonom && currentData.berhimpunOtonom.badanOtonom && currentData.berhimpunOtonom.badanOtonom.length > 0">
                            <template v-for="(item, i) in currentData.berhimpunOtonom.badanOtonom">
                              <tr v-bind:key="i">
                                <td>
                                  <span style="width: 20px" class="
                                    text-dark-75
                                    font-weight-bolder
                                    d-block
                                    font-size-lg
                                  ">{{ i + 1 }}</span>
                                </td>
                                <td>
                                  <span class="
                                    text-dark-75
                                    font-weight-bolder
                                    d-block
                                    text-left
                                    font-size-lg
                                  ">{{ item.afiliasiOrmas.nama_ormas }}</span>
                                </td>
                                <td>
                                  <span class="
                                    text-dark-75
                                    font-weight-bolder
                                    d-block
                                    text-left
                                    font-size-lg
                                  ">{{ item.afiliasiOrmas.kode_transaksi }}</span>
                                </td>
                              </tr>
                            </template>
                          </tbody>
                        </table>
                      </div>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col>
                  <b-row class="d-flex justify-content-center" v-if="currentData.profil">
                    <img v-if="currentData.profil.file_logo !== null"
                      :src="fileURL + '/' + currentData.profil.file_logo"
                      style="width: 60%; margin-bottom: 20px; margin-left: 8px;opacity:20%;" alt="abstract"
                      @error="replaceByDefault" />
                    <img v-else src="https://alppetro.co.id/dist/assets/images/default.jpg"
                      style="width: 60%; margin-bottom: 20px; margin-left: 8px;opacity:20%;" alt="abstract" />

                    <img v-if="currentData.profil.file_bendera !== null"
                      :src="fileURL + '/' + currentData.profil.file_bendera"
                      style="width: 60%; margin-bottom: 20px; margin-left: 8px;opacity:20%;" alt="abstract"
                      @error="replaceByDefault" />
                    <img v-else src="https://alppetro.co.id/dist/assets/images/default.jpg"
                      style="width: 60%; margin-bottom: 20px; margin-left: 8px;opacity:20%;" alt="abstract" />
                  </b-row>
                </b-col>
              </b-row>

              <b-row class="mb-3">
                <b-col>
                  <b style="font-size:16px;">
                    Struktur Organisasi
                  </b>
                </b-col>
              </b-row>
              <div class="card-body pt-0 pb-3 pl-0 pr-0">
                <div class="tab-content">
                  <!--begin::Table-->
                  <div class="table-responsive">
                    <table class="
                  table
                  table-head-custom
                  table-vertical-center
                  table-head-bg
                  table-bordered
                ">
                      <thead>
                        <tr class="text-left">
                          <th style="width: 100px; padding-left:0px; padding-right: 0px;" class="text-capitalize">Foto
                          </th>
                          <th style="width: 170px; padding-left:0px; padding-right: 0px;" class="text-capitalize">Nama
                          </th>
                          <th style="width: 200px; padding-left:0px; padding-right: 0px;" class="text-capitalize">Masa
                            Bakti</th>
                          <th style="width: 90px; padding-left:0px; padding-right: 0px;" class="text-capitalize">Jabatan
                          </th>
                          <th style="width: 100px; padding-left:0px; padding-right: 0px;" class="text-capitalize">No.
                            Identitas</th>
                          <th style="width: 100px; padding-left:0px; padding-right: 0px;" class="text-capitalize">
                            Tanggal Lahir</th>
                          <th style="width: 100px; padding-left:0px; padding-right: 0px;" class="text-capitalize">No. HP
                          </th>
                        </tr>
                      </thead>
                      <tbody style="background:#FAFAFA;">
                        <template v-for="(item, i) in pengurus">
                          <tr v-bind:key="i">
                            <td>
                              <img v-if="item.foto !== null && item.foto !== ''" :src="item.foto"
                                style="width:64px;height:64px;" />
                              <img v-else
                                src="/assets/images/default-thumbnail.jpg"
                                style="width: 60%; margin-bottom: 20px; margin-left: 8px;opacity:20%;" alt="abstract" />
                            </td>
                            <td>
                              <span class="
                            text-dark-75
                            font-weight-bolder
                            d-block
                            text-left
                            font-size-lg
                          ">{{ item ? item.nama_pengurus : '' }}</span>
                            </td>
                            <td>
                              <span class="
                            text-dark-75
                            font-weight-bolder
                            d-block
                            text-left
                            font-size-lg
                          ">{{ item.periode_mulai | moment("DD/MM/YYYY") }} - {{ item.periode_selesai |
                          moment("DD/MM/YYYY") }}</span>
                            </td>
                            <td>
                              <span class="
                            text-dark-75
                            text-left
                            font-weight-bolder
                            d-block
                            font-size-lg
                          ">{{ item.masterJabatan ? item.masterJabatan.nama_jabatan : '-' }}</span>
                            </td>
                            <td>
                              <span class="
                            text-left
                            text-dark-75
                            font-weight-bolder
                            d-block
                            font-size-lg
                          ">{{ item.identitas }}</span>
                            </td>
                            <td>
                              <span class="
                            text-dark-75
                            text-left
                            font-weight-bolder
                            d-block
                            font-size-lg
                          ">{{item.tanggal_lahir | moment("DD-MM-YYYY")}}</span>
                            </td>
                            <td>
                              <span class="
                            text-dark-75
                            font-weight-bolder
                            text-left
                            d-block
                            font-size-lg
                          ">{{ item.no_hp }}</span>
                            </td>
                          </tr>
                        </template>
                      </tbody>
                    </table>
                  </div>

                  <!--end::Table-->
                  <div class="mt-5" v-if="currentData.listPengurus.length > 0">
                    <b-row class="d-flex align-items-center mb-5">
                      <b-col sm="4">
                        <p style="font-weight: 600; margin:0px;">SK Susunan Pengurus</p>
                      </b-col>:<b-col sm="6">
                        <b-button size="sm" variant="success">
                          <v-icon style="color:white;" small>mdi-clipboard</v-icon>Lihat File Unggahan
                        </b-button>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col sm="4">
                        <p style="font-weight: 600">Daftar Pendiri Organisasi</p>
                      </b-col>:<b-col sm="6">
                        <span v-for="(item,index) in currentData.listPengurus" :key="index">
                          <span
                            v-if="item.masterKategoriPengurus.kode_kategori_pengurus == 'pendiri'">{{item.namaPengurus}};</span>
                        </span>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col sm="4">
                        <p style="font-weight: 600">NIK daftar Pendiri Organisasi</p>
                      </b-col>:<b-col sm="6">
                        <span v-for="(item,index) in currentData.listPengurus" :key="index">
                          <span
                            v-if="item.masterKategoriPengurus.kode_kategori_pengurus == 'pendiri'">{{item.identitas}};</span>
                        </span>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col sm="4">
                        <p style="font-weight: 600">Daftar Pembina Organisasi</p>
                      </b-col>:<b-col sm="6">
                        <span v-for="(item,index) in currentData.listPengurus" :key="index">
                          <span
                            v-if="item.masterKategoriPengurus.kode_kategori_pengurus == 'pembina'">{{item.namaPengurus}};</span>
                        </span>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col sm="4">
                        <p style="font-weight: 600">NIK daftar Pembina Organisasi</p>
                      </b-col>:<b-col sm="6">
                        <span v-for="(item,index) in currentData.listPengurus" :key="index">
                          <span
                            v-if="item.masterKategoriPengurus.kode_kategori_pengurus == 'pembina'">{{item.identitas}};</span>
                        </span>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col sm="4">
                        <p style="font-weight: 600">Daftar Penasehat Organisasi</p>
                      </b-col>:<b-col sm="6">
                        <span v-for="(item,index) in currentData.listPengurus" :key="index">
                          <span
                            v-if="item.masterKategoriPengurus.kode_kategori_pengurus == 'penasehat'">{{item.namaPengurus}};</span>
                        </span>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col sm="4">
                        <p style="font-weight: 600">NIK daftar Penasehat Organisasi</p>
                      </b-col>:<b-col sm="6">
                        <span v-for="(item,index) in currentData.listPengurus" :key="index">
                          <span
                            v-if="item.masterKategoriPengurus.kode_kategori_pengurus == 'penasehat'">{{item.identitas}};</span>
                        </span>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col sm="4">
                        <p style="font-weight: 600">Daftar Pelindung Organisasi</p>
                      </b-col>:<b-col sm="6">
                        <span v-for="(item,index) in currentData.listPengurus" :key="index">
                          <span
                            v-if="item.masterKategoriPengurus.kode_kategori_pengurus == 'pelindung'">{{item.namaPengurus}};</span>
                        </span>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col sm="4">
                        <p style="font-weight: 600">NIK daftar Pelindung Organisasi</p>
                      </b-col>:<b-col sm="6">
                        <span v-for="(item,index) in currentData.listPengurus" :key="index">
                          <span
                            v-if="item.masterKategoriPengurus.kode_kategori_pengurus == 'pelindung'">{{item.identitas}};</span>
                        </span>
                      </b-col>
                    </b-row>

                  </div>
                </div>
              </div>

              <b-row class="mb-3 mt-3">
                <b-col>
                  <b style="font-size:16px">
                    Rekening
                  </b>
                </b-col>
              </b-row>
              <div class="table-responsive">
                <table class="
                  table
                  table-head-custom
                  table-vertical-center
                  table-head-bg
                  table-bordered
                ">
                  <thead>
                    <tr class="text-left">
                      <th style="width: 20px">No</th>
                      <th style="min-width: 50px" class="text-capitalize">Nomor Rekening</th>
                      <th style="min-width: 50px" class="text-capitalize">Nama Bank</th>
                      <th style="min-width: 50px" class="text-capitalize">Pemilik rekening</th>
                      <th style="min-width: 50px" class="text-capitalize">Jenis rekening</th>
                    </tr>
                  </thead>
                  <tbody v-if="currentData.rekening.length == 0">
                    <template>
                      <tr>
                        <td colspan="6"> -- Tidak ada rekening -- </td>
                      </tr>
                    </template>
                  </tbody>
                  <tbody style="background:#FAFAFA;">
                    <template v-for="(item, i) in currentData.rekening">
                      <tr v-bind:key="i">
                        <td>
                          <span class="
                      text-dark-75
                      font-weight-bolder
                      d-block
                      font-size-lg
                    ">{{ i + 1 }}</span>
                        </td>
                        <td>
                          <span class="
                      text-dark-75
                      text-left
                      font-weight-bolder
                      d-block
                      font-size-lg
                    ">{{ item.no_rekening}}</span>
                        </td>
                        <td>
                          <span class="
                      text-dark-75
                      font-weight-bolder
                      text-left
                      d-block
                      font-size-lg
                    ">{{ item.nama_bank}}</span>
                        </td>
                        <td>
                          <span class="
                      text-dark-75
                      font-weight-bolder
                      text-left
                      d-block
                      font-size-lg
                    ">{{item.nama_pemilik_rekening}}</span>
                        </td>
                        <td>
                          <span class="
                      text-dark-75
                      text-left
                      font-weight-bolder
                      d-block
                      font-size-lg
                    ">{{item.id_jenis_rekening}}</span>
                        </td>
                      </tr>
                    </template>
                  </tbody>
                </table>
              </div>

              <b-row class="mb-3 mt-8">
                <b-col>
                  <b style="font-size:16px">
                    Cabang Ormas
                  </b>
                </b-col>
              </b-row>
              <div class="table-responsive">
                <table class="
                  table
                  table-head-custom
                  table-vertical-center
                  table-head-bg
                  table-bordered
                ">
                  <thead>
                    <tr class="text-left">
                      <th style="width: 20px">No</th>
                      <th style="min-width: 50px" class="text-capitalize">Status Sekretariat</th>
                      <th style="min-width: 50px" class="text-capitalize">Alamat</th>
                      <th style="min-width: 50px" class="text-capitalize">Kabupaten / Kota</th>
                      <th style="min-width: 50px" class="text-capitalize">Provinsi</th>
                    </tr>
                  </thead>
                  <tbody style="background:#FAFAFA;">
                    <template v-for="(item, i) in currentData.cabangOrmas">
                      <tr v-bind:key="i">
                        <td>
                          <span class="
                      text-dark-75
                      font-weight-bolder
                      d-block
                      font-size-lg
                    ">{{ i + 1 }}</span>
                        </td>
                        <td>
                          <span class="
                      text-dark-75
                      text-left
                      font-weight-bolder
                      d-block
                      font-size-lg
                    ">{{ item.masterLokasiSekretariat.nama_lokasi}}</span>
                        </td>
                        <td>
                          <span class="
                      text-dark-75
                      font-weight-bolder
                      text-left
                      d-block
                      font-size-lg
                    ">{{ item.alamat_sekretariat}}</span>
                        </td>
                        <td>
                          <span class="
                      text-dark-75
                      font-weight-bolder
                      text-left
                      d-block
                      font-size-lg
                    ">{{ item.wilayahKabupaten.nama}}</span>
                        </td>
                        <td>
                          <span class="
                      text-dark-75
                      text-left
                      font-weight-bolder
                      d-block
                      font-size-lg
                    ">{{ item.wilayahProvinsi.nama}}</span>
                        </td>
                      </tr>
                    </template>
                  </tbody>
                </table>
              </div>
            </b-tab>

            <b-tab title="Data Yang Diubah" style="background-color:white;margin-top:0px;">
              <b-row class="mb-3" v-if="changedData.profile">
                <b-col>
                  <b style="font-size:16px;">
                    Profil
                  </b>
                </b-col>
              </b-row>
              <b-row v-if="changedData.profile">
                <b-col cols="7">
                  <b-row class="mb-2">
                    <b-col cols="4" style="font-weight:600;">
                      Nomor Telepon
                    </b-col>
                    <b-col v-if="changedData.profile">
                      : {{ changedData.profile.noTelp}}
                    </b-col>
                  </b-row>
                  <b-row class="mb-2">
                    <b-col cols="4" style="font-weight:600;">
                      Nomor Fax
                    </b-col>
                    <b-col v-if="changedData.profile">
                      : {{ changedData.profile.noFax}}
                    </b-col>
                  </b-row>
                  <b-row class="mb-2">
                    <b-col cols="4" style="font-weight:600;">
                      Ciri Khusus
                    </b-col>
                    <b-col v-if="changedData.profile">
                      : {{ changedData.profile.masterCiriKhusus.nama_ciri_khusus}}
                    </b-col>
                  </b-row>
                  <b-row class="mb-2" v-if="changedData.profile && changedData.profile.bidangKegiatans">
                    <b-col cols="4" style="font-weight:600;">
                      Bidang Kegiatan
                    </b-col>
                    <b-col class="d-flex">
                      : &nbsp;<ul class="ml-5">
                        <li v-for="(item,index) in changedData.profile.bidangKegiatans" :key="index">{{
                        item.masterJenisOrmas.nama_jenis_ormas }}</li>
                      </ul>
                    </b-col>
                  </b-row>
                  <b-row class="mb-2" v-if="changedData.berhimpunOtonom">
                    <b-col cols="4" style="font-weight:600;">
                      Wadah Berhimpun
                    </b-col>
                    <b-col>
                      : {{ changedData.berhimpunOtonom.wadahBerhimpun.dataOrmas.nama_ormas}}
                    </b-col>
                  </b-row>

                  <b-row class="mb-3 mt-5" v-if="changedData.berhimpunOtonom">
                    <b-col style="font-weight:600;">
                      Badan Otonom / Sayap Organisasi
                    </b-col>
                  </b-row>

                  <b-row class="mb-2" v-if="changedData.berhimpunOtonom">
                    <b-col>
                      <div class="table-responsive">
                        <table class="
                                  table
                                  table-head-custom
                                  table-vertical-center
                                  table-head-bg
                                  table-bordered
                                ">
                          <thead>
                            <tr class="text-left">
                              <th style="width: 20px" class="text-capitalize">No.</th>
                              <th style="min-width: 190px" class="text-capitalize">Nama Organisasi</th>
                              <th style="min-width: 100px" class="text-capitalize">Nomor Registrasi</th>
                            </tr>
                          </thead>
                          <tbody style="background:#FAFAFA;" v-if="changedData.berhimpunOtonom.badanOtonom.length > 0">
                            <template v-for="(item, i) in changedData.berhimpunOtonom.badanOtonom">
                              <tr v-bind:key="i">
                                <td>
                                  <span style="width: 20px" class="
                                    text-dark-75
                                    font-weight-bolder
                                    d-block
                                    font-size-lg
                                  ">{{ i + 1 }}</span>
                                </td>
                                <td>
                                  <span class="
                                    text-dark-75
                                    font-weight-bolder
                                    d-block
                                    text-left
                                    font-size-lg
                                  ">{{ item.dataOrmas.nama_ormas }}</span>
                                </td>
                                <td>
                                  <span class="
                                    text-dark-75
                                    font-weight-bolder
                                    d-block
                                    text-left
                                    font-size-lg
                                  ">{{ ['tidak tersedia'] }}</span>
                                </td>
                              </tr>
                            </template>
                          </tbody>
                        </table>
                      </div>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col>
                  <b-row class="d-flex justify-content-center" v-if="changedData.profile">
                    <img v-if="changedData.profile.file_logo !== null"
                      :src="fileURL + '/' + changedData.profile.file_logo"
                      style="width: 60%; margin-bottom: 20px; margin-left: 8px;opacity:20%;" alt="abstract"
                      @error="replaceByDefault" />
                    <img v-else src="https://alppetro.co.id/dist/assets/images/default.jpg"
                      style="width: 60%; margin-bottom: 20px; margin-left: 8px;opacity:20%;" alt="abstract" />

                    <img v-if="changedData.profile.file_bendera !== null"
                      :src="fileURL + '/' + changedData.profile.file_bendera"
                      style="width: 60%; margin-bottom: 20px; margin-left: 8px;opacity:20%;" alt="abstract"
                      @error="replaceByDefault" />
                    <img v-else src="https://alppetro.co.id/dist/assets/images/default.jpg"
                      style="width: 60%; margin-bottom: 20px; margin-left: 8px;opacity:20%;" alt="abstract" />
                  </b-row>
                </b-col>
              </b-row>

              <b-row class="mb-3" v-if="changedData.listPengurus">
                <b-col>
                  <b style="font-size:16px;">
                    Struktur Organisasi
                  </b>
                </b-col>
              </b-row>
              <div class="card-body pt-0 pb-3 pl-0 pr-0" v-if="changedData.listPengurus">
                <div class="tab-content">
                  <!--begin::Table-->
                  <div class="table-responsive">
                    <table class="
                  table
                  table-head-custom
                  table-vertical-center
                  table-head-bg
                  table-bordered
                ">
                      <thead>
                        <tr class="text-left">
                          <th style="width: 100px; padding-left:0px; padding-right: 0px;" class="text-capitalize">Foto
                          </th>
                          <th style="width: 170px; padding-left:0px; padding-right: 0px;" class="text-capitalize">Nama
                          </th>
                          <th style="width: 200px; padding-left:0px; padding-right: 0px;" class="text-capitalize">Masa
                            Bakti</th>
                          <th style="width: 90px; padding-left:0px; padding-right: 0px;" class="text-capitalize">Jabatan
                          </th>
                          <th style="width: 100px; padding-left:0px; padding-right: 0px;" class="text-capitalize">No.
                            Identitas</th>
                          <th style="width: 100px; padding-left:0px; padding-right: 0px;" class="text-capitalize">
                            Tanggal Lahir</th>
                          <th style="width: 100px; padding-left:0px; padding-right: 0px;" class="text-capitalize">No. HP
                          </th>
                        </tr>
                      </thead>
                      <tbody style="background:#FAFAFA;">
                        <template v-for="(item, i) in pengurus">
                          <tr v-bind:key="i">
                            <td>
                              <img v-if="item.foto !== null && item.foto !== ''" :src="item.foto"
                                style="width:64px;height:64px;" />
                              <img v-else
                                src="/assets/images/default-thumbnail.jpg"
                                style="width: 60%; margin-bottom: 20px; margin-left: 8px;opacity:20%;" alt="abstract" />
                            </td>
                            <td>
                              <span class="
                            text-dark-75
                            font-weight-bolder
                            d-block
                            text-left
                            font-size-lg
                          ">{{ item ? item.nama_pengurus : '' }}</span>
                            </td>
                            <td>
                              <span class="
                            text-dark-75
                            font-weight-bolder
                            d-block
                            text-left
                            font-size-lg
                          ">{{ item.periode_mulai | moment("DD/MM/YYYY") }} - {{ item.periode_selesai |
                          moment("DD/MM/YYYY") }}</span>
                            </td>
                            <td>
                              <span class="
                            text-dark-75
                            text-left
                            font-weight-bolder
                            d-block
                            font-size-lg
                          ">{{ item.masterJabatan ? item.masterJabatan.nama_jabatan : '-' }}</span>
                            </td>
                            <td>
                              <span class="
                            text-left
                            text-dark-75
                            font-weight-bolder
                            d-block
                            font-size-lg
                          ">{{ item.identitas }}</span>
                            </td>
                            <td>
                              <span class="
                            text-dark-75
                            text-left
                            font-weight-bolder
                            d-block
                            font-size-lg
                          ">{{item.tanggal_lahir | moment("DD-MM-YYYY")}}</span>
                            </td>
                            <td>
                              <span class="
                            text-dark-75
                            font-weight-bolder
                            text-left
                            d-block
                            font-size-lg
                          ">{{ item.no_hp }}</span>
                            </td>
                          </tr>
                        </template>
                      </tbody>
                    </table>
                  </div>

                  <!--end::Table-->
                  <div class="mt-5" v-if="changedData.listPengurus && changedData.listPengurus.length > 0">
                    <b-row class="d-flex align-items-center mb-5">
                      <b-col sm="4">
                        <p style="font-weight: 600; margin:0px;">SK Susunan Pengurus</p>
                      </b-col>:<b-col sm="6">
                        <b-button size="sm" variant="success">
                          <v-icon style="color:white;" small>mdi-clipboard</v-icon>Lihat File Unggahan
                        </b-button>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col sm="4">
                        <p style="font-weight: 600">Daftar Pendiri Organisasi</p>
                      </b-col>:<b-col sm="6">
                        <span v-for="(item,index) in changedData.listPengurus" :key="index">
                          <span
                            v-if="item.masterKategoriPengurus.kode_kategori_pengurus == 'pendiri'">{{item.namaPengurus}};</span>
                        </span>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col sm="4">
                        <p style="font-weight: 600">NIK daftar Pendiri Organisasi</p>
                      </b-col>:<b-col sm="6">
                        <span v-for="(item,index) in changedData.listPengurus" :key="index">
                          <span
                            v-if="item.masterKategoriPengurus.kode_kategori_pengurus == 'pendiri'">{{item.identitas}};</span>
                        </span>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col sm="4">
                        <p style="font-weight: 600">Daftar Pembina Organisasi</p>
                      </b-col>:<b-col sm="6">
                        <span v-for="(item,index) in changedData.listPengurus" :key="index">
                          <span
                            v-if="item.masterKategoriPengurus.kode_kategori_pengurus == 'pembina'">{{item.namaPengurus}};</span>
                        </span>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col sm="4">
                        <p style="font-weight: 600">NIK daftar Pembina Organisasi</p>
                      </b-col>:<b-col sm="6">
                        <span v-for="(item,index) in changedData.listPengurus" :key="index">
                          <span
                            v-if="item.masterKategoriPengurus.kode_kategori_pengurus == 'pembina'">{{item.identitas}};</span>
                        </span>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col sm="4">
                        <p style="font-weight: 600">Daftar Penasehat Organisasi</p>
                      </b-col>:<b-col sm="6">
                        <span v-for="(item,index) in changedData.listPengurus" :key="index">
                          <span
                            v-if="item.masterKategoriPengurus.kode_kategori_pengurus == 'penasehat'">{{item.namaPengurus}};</span>
                        </span>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col sm="4">
                        <p style="font-weight: 600">NIK daftar Penasehat Organisasi</p>
                      </b-col>:<b-col sm="6">
                        <span v-for="(item,index) in changedData.listPengurus" :key="index">
                          <span
                            v-if="item.masterKategoriPengurus.kode_kategori_pengurus == 'penasehat'">{{item.identitas}};</span>
                        </span>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col sm="4">
                        <p style="font-weight: 600">Daftar Pelindung Organisasi</p>
                      </b-col>:<b-col sm="6">
                        <span v-for="(item,index) in changedData.listPengurus" :key="index">
                          <span
                            v-if="item.masterKategoriPengurus.kode_kategori_pengurus == 'pelindung'">{{item.namaPengurus}};</span>
                        </span>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col sm="4">
                        <p style="font-weight: 600">NIK daftar Pelindung Organisasi</p>
                      </b-col>:<b-col sm="6">
                        <span v-for="(item,index) in changedData.listPengurus" :key="index">
                          <span
                            v-if="item.masterKategoriPengurus.kode_kategori_pengurus == 'pelindung'">{{item.identitas}};</span>
                        </span>
                      </b-col>
                    </b-row>

                  </div>
                </div>
              </div>

              <b-row class="mb-3 mt-3" v-if="changedData.rekening">
                <b-col>
                  <b style="font-size:16px">
                    Rekening
                  </b>
                </b-col>
              </b-row>
              <div class="table-responsive" v-if="changedData.rekening">
                <table class="
                  table
                  table-head-custom
                  table-vertical-center
                  table-head-bg
                  table-bordered
                ">
                  <thead>
                    <tr class="text-left">
                      <th style="width: 20px">No</th>
                      <th style="min-width: 50px" class="text-capitalize">Nomor Rekening</th>
                      <th style="min-width: 50px" class="text-capitalize">Nama Bank</th>
                      <th style="min-width: 50px" class="text-capitalize">Pemilik rekening</th>
                      <th style="min-width: 50px" class="text-capitalize">Jenis rekening</th>
                    </tr>
                  </thead>
                  <tbody v-if="changedData.rekening.length == 0">
                    <template>
                      <tr>
                        <td colspan="6"> -- Tidak ada rekening -- </td>
                      </tr>
                    </template>
                  </tbody>
                  <tbody style="background:#FAFAFA;">
                    <template v-for="(item, i) in changedData.rekening">
                      <tr v-bind:key="i">
                        <td>
                          <span class="
                      text-dark-75
                      font-weight-bolder
                      d-block
                      font-size-lg
                    ">{{ i + 1 }}</span>
                        </td>
                        <td>
                          <span class="
                      text-dark-75
                      text-left
                      font-weight-bolder
                      d-block
                      font-size-lg
                    ">{{ item.noRekening}}</span>
                        </td>
                        <td>
                          <span class="
                      text-dark-75
                      font-weight-bolder
                      text-left
                      d-block
                      font-size-lg
                    ">{{ item.namaBank}}</span>
                        </td>
                        <td>
                          <span class="
                      text-dark-75
                      font-weight-bolder
                      text-left
                      d-block
                      font-size-lg
                    ">{{item.namaPemilikRekening}}</span>
                        </td>
                        <td>
                          <span class="
                      text-dark-75
                      text-left
                      font-weight-bolder
                      d-block
                      font-size-lg
                    ">{{item.id_jenis_rekening}}</span>
                        </td>
                      </tr>
                    </template>
                  </tbody>
                </table>
              </div>

              <b-row class="mb-3 mt-8" v-if="changedData.cabangOrmas">
                <b-col>
                  <b style="font-size:16px">
                    Cabang Ormas
                  </b>
                </b-col>
              </b-row>
              <div class="table-responsive" v-if="changedData.cabangOrmas">
                <table class="
                  table
                  table-head-custom
                  table-vertical-center
                  table-head-bg
                  table-bordered
                ">
                  <thead>
                    <tr class="text-left">
                      <th style="width: 20px">No</th>
                      <th style="min-width: 50px" class="text-capitalize">Status Sekretariat</th>
                      <th style="min-width: 50px" class="text-capitalize">Alamat</th>
                      <th style="min-width: 50px" class="text-capitalize">Kabupaten / Kota</th>
                      <th style="min-width: 50px" class="text-capitalize">Provinsi</th>
                    </tr>
                  </thead>
                  <tbody style="background:#FAFAFA;">
                    <template v-for="(item, i) in changedData.cabangOrmas">
                      <tr v-bind:key="i">
                        <td>
                          <span class="
                      text-dark-75
                      font-weight-bolder
                      d-block
                      font-size-lg
                    ">{{ i + 1 }}</span>
                        </td>
                        <td>
                          <span class="
                      text-dark-75
                      text-left
                      font-weight-bolder
                      d-block
                      font-size-lg
                    ">{{ item.masterLokasiSekretariat.nama_lokasi}}</span>
                        </td>
                        <td>
                          <span class="
                      text-dark-75
                      font-weight-bolder
                      text-left
                      d-block
                      font-size-lg
                    ">{{ item.alamatSekretariat}}</span>
                        </td>
                        <td>
                          <span class="
                      text-dark-75
                      font-weight-bolder
                      text-left
                      d-block
                      font-size-lg
                    ">{{ item.wilayahKabupatenKota.nama}}</span>
                        </td>
                        <td>
                          <span class="
                      text-dark-75
                      text-left
                      font-weight-bolder
                      d-block
                      font-size-lg
                    ">{{ item.wilayahProvinsi.nama}}</span>
                        </td>
                      </tr>
                    </template>
                  </tbody>
                </table>
              </div>
            </b-tab>

          </b-tabs>

        </b-container>
      </div>
    </b-modal>

    <b-modal id="modal-profil-tidak-terdaftar" ok-only centered size="xl" content-class="shadow" hide-header
      hide-footer>
      <div>
        <b-container fluid class="pl-0 pr-0">
          <b-row class="pt-5 mt-2 pr-0 pl-0">
            <b-col class="d-flex justify-content-between pr-0 pl-0">
              <b style="font-size:20px;">
                {{tabDetailTT == 0 ?'Profil Ormas' : ''}}
                {{tabDetailTT == 1 ?'Info Pendirian Ormas' : ''}}
                {{tabDetailTT == 2 ?'Struktur Organisasi' : ''}}
                {{tabDetailTT == 3 ?'Dokumen Ormas' : ''}}
                {{tabDetailTT == 4 ?'Rekening Ormas' : ''}}
                {{tabDetailTT == 5 ?'Cabang Ormas' : ''}}
              </b>
              <div>
                <b-button variant="outline-secondary" style="background: #FFFFFF;" size="sm" class="mr-4"
                  @click="$bvModal.hide('modal-profil-tidak-terdaftar')">
                  <v-icon class="pr-3">mdi-close</v-icon>
                  Tutup
                </b-button>
                <button style="color:white;" class="btn font-weight-bolder font-size-sm bg-hitam"
                  data-wizard-type="action-submit" v-if="isOrmasUnreg" @click="cetakDetailUnreg(info_organizationtt.id)">
                  <i class="fas fa-file-pdf" style="color:white;"></i>Cetak PDF
                </button>
              </div>
            </b-col>
          </b-row>
          <b-row class="pt-5">
            <b-tabs pills card justified v-model="tabDetailTT" style="border-radius:10px;width:100%;">

              <b-tab title="Profil" style="background-color:white;margin-top:0px;">
                <b-row>
                  <b-col cols="7">
                    <b-row>
                      <b-col sm="3">
                        <p style="font-weight: 600">{{ "Nama Ormas" }}</p>
                      </b-col>:<b-col sm="6">{{ info_organizationtt.nama_ormas}}</b-col>
                    </b-row>
                    <b-row>
                      <b-col sm="3">
                        <p style="font-weight: 600">{{ "Singkatan" }}</p>
                      </b-col>:<b-col sm="6">{{ info_organizationtt.nama_singkat}}</b-col>
                    </b-row>
                    <b-row>
                      <b-col sm="3">
                        <p style="font-weight: 600">
                          {{ "Status Sekretariat" }}
                        </p>
                      </b-col>:<b-col sm="6" v-if="info_organizationtt.masterLokasiSekretariat">{{
                      info_organizationtt.masterLokasiSekretariat.nama_lokasi }}</b-col>
                    </b-row>
                    <b-row>
                      <b-col sm="3">
                        <p style="font-weight: 600">
                          {{ "Alamat sekretariat" }}
                        </p>
                      </b-col>:<b-col sm="6">{{ info_organizationtt.alamat_sekretariat }}</b-col>
                    </b-row>
                    <b-row>
                      <b-col sm="3">
                        <p style="font-weight: 600">{{ "Kabupaten/Kota" }}</p>
                      </b-col>:<b-col sm="6"
                        v-if="info_organizationtt.masterKabupatenKota && info_organizationtt.masterKabupatenKota.nama">
                        {{ info_organization.masterKabupatenKota.nama }}</b-col>
                      <b-col sm="6" v-else>{{ info_organizationtt.kabupaten_kota_sekretariat }}</b-col>
                    </b-row>
                    <b-row>
                      <b-col sm="3">
                        <p style="font-weight: 600">{{ "Provinsi" }}</p>
                      </b-col>:<b-col sm="6" v-if="info_organizationtt.masterProvinsi">{{
                      info_organizationtt.masterProvinsi.nama }}</b-col>
                      <b-col sm="6" v-else>{{ info_organizationtt.provinsi_sekretariat }}</b-col>
                    </b-row>
                    <b-row v-if="userType == 'admin'">
                      <b-col sm="3">
                        <p style="font-weight: 600">{{ "Nomor Telepon" }}</p>
                      </b-col>:<b-col sm="6">{{ info_organizationtt.no_telp }}</b-col>
                    </b-row>
                    <b-row v-if="userType == 'admin'">
                      <b-col sm="3">
                        <p style="font-weight: 600">{{ "Nomor FAX" }}</p>
                      </b-col>:<b-col sm="6">{{ info_organizationtt.no_fax }}</b-col>
                    </b-row>
                    <b-row>
                      <b-col sm="3">
                        <p style="font-weight: 600">{{ "Email" }}</p>
                      </b-col>:<b-col sm="6">{{ info_organizationtt.email }}</b-col>
                    </b-row>
                    <b-row>
                      <b-col sm="3">
                        <p style="font-weight: 600">
                          {{ "Tujuan organisasi" }}
                        </p>
                      </b-col>:
                      <b-col sm="6">
                        {{info_organizationtt.tujuanOrmas}}
                      </b-col>
                    </b-row>

                    <b-row class="d-flex align-items-center">
                      <b-col sm="3">
                        <p style="font-weight: 600; margin:0px;">{{ "NPWP" }}</p>
                      </b-col>:<b-col sm="6">{{ info_organizationtt.npwp }}
                        <b-button
                          v-if="userType == 'admin' && info_organizationtt.file_npwp != null && info_organizationtt.file_npwp != ''"
                          @click="newTab(fileURL + info_organizationtt.file_npwp)" size="sm" variant="success">
                          <v-icon style="color:white;" small>mdi-eye</v-icon>Lihat File Scan
                        </b-button>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col sm="3">
                        <p style="font-weight: 600">{{ "Ciri khusus" }}</p>
                      </b-col>:<b-col sm="6">{{ info_organizationtt.masterCiriKhusus.nama_ciri_khusus }}</b-col>
                    </b-row>
                    <b-row>
                      <b-col sm="3">
                        <p style="font-weight: 600">{{ "Bidang Kegiatan" }}</p>
                      </b-col>:<b-col sm="6">
                        <ul class="ml-5">
                          <li v-for="(item,index) in info_organizationtt.bidangKegiatans" :key="index">{{
                          item.nama_jenis_ormas }}</li>
                        </ul>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col sm="3">
                        <p style="font-weight: 600">{{ "Wadah Berhimpun" }}</p>
                      </b-col>:<b-col sm="6"
                        v-if="info_organizationtt.wadahBerhimpun && info_organizationtt.wadahBerhimpun.nama_ormas">
                        {{info_organizationtt.wadahBerhimpun.nama_ormas}}
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col sm="3">
                        <p style="font-weight: 600">{{ "Badan Otonom / Sayap Organisasi" }}</p>
                      </b-col>:<b-col sm="6">
                        <ul class="ml-5"
                          v-if="info_organizationtt.berhimpunOtonom && info_organizationtt.berhimpunOtonom.length > 0">
                          <span v-for="(item,index) in info_organizationtt.berhimpunOtonom" :key="index" style="white-space: pre-wrap">
                            <li>{{ item.nama_ormas }}</li>
                          </span>
                        </ul>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="5">
                    <b-row class="text-right">
                      <img v-if="info_organizationtt.file_logo !== null"
                        :src="fileURL + '/' + info_organizationtt.file_logo"
                        style="width: 60%; margin-bottom: 20px; margin-left: 8px;opacity:20%;" alt="abstract"
                        @error="replaceByDefault" />
                      <img v-else src="https://alppetro.co.id/dist/assets/images/default.jpg"
                        style="width: 60%; margin-bottom: 20px; margin-left: 8px;opacity:20%;" alt="abstract" />

                      <img v-if="info_organizationtt.file_bendera !== null"
                        :src="fileURL + '/' + info_organizationtt.file_bendera"
                        style="width: 60%; margin-bottom: 20px; margin-left: 8px;opacity:20%;" alt="abstract"
                        @error="replaceByDefault" />
                      <img v-else src="https://alppetro.co.id/dist/assets/images/default.jpg"
                        style="width: 60%; margin-bottom: 20px; margin-left: 8px;opacity:20%;" alt="abstract" />
                    </b-row>
                  </b-col>
                </b-row>
              </b-tab>
              <b-tab title="Info Pendirian" style="background-color:white;margin-top:0px;">
                <b-row>
                  <b-col cols="7">
                    <b-row>
                      <b-col sm="4">
                        <p style="font-weight: 600">Nama Notaris</p>
                      </b-col>:<b-col sm="6">{{ founder_organizationtt.namaNotaris}} </b-col>
                    </b-row>
                    <b-row class="d-flex align-items-center">
                      <b-col sm="4">
                        <p style="font-weight: 600; margin:0px;">Nomor AKTA Pendirian yang ini loh</p>
                      </b-col>:<b-col sm="6">{{ founder_organizationtt.nomorAkta }} </b-col>
                    </b-row>
                    <b-row>
                      <b-col sm="4">
                        <p style="font-weight: 600">Tanggal AKTA Pendirian</p>
                      </b-col>:<b-col sm="6">{{ founder_organizationtt.tanggalAkta | moment("DD - MM - YYYY") }}
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col sm="4">
                        <p style="font-weight: 600">Tempat Pendirian</p>
                      </b-col>:<b-col sm="6">{{ founder_organizationtt.tempatPendirian }} </b-col>
                    </b-row>
                    <b-row>
                      <b-col sm="4">
                        <p style="font-weight: 600">Tanggal Pendirian</p>
                      </b-col>:<b-col sm="6">{{ founder_organizationtt.tanggalPendirian | moment("DD - MM - YYYY") }}
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col sm="4">
                        <p style="font-weight: 600; margin:0px;">Nomor Surat Pendaftaran</p>
                      </b-col>:<b-col sm="6">{{ founder_organizationtt.nomorSuratPendaftaran }}
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col sm="4">
                        <p style="font-weight: 600">Tanggal Surat Pendaftaran</p>
                      </b-col>:<b-col sm="6">{{ founder_organizationtt.tanggalSuratPendaftaran | moment("DD - MM - YYYY") }} </b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="5">
                    <b-row>
                      <img v-if="info_organizationtt.file_logo !== null"
                        :src="fileURL + '/' + info_organizationtt.file_logo"
                        style="width: 60%; margin-bottom: 20px; margin-left: 8px;opacity:20%;" alt="abstract"
                        @error="replaceByDefault" />
                      <img v-else src="https://alppetro.co.id/dist/assets/images/default.jpg"
                        style="width: 60%; margin-bottom: 20px; margin-left: 8px;opacity:20%;" alt="abstract" />

                      <img v-if="info_organizationtt.file_bendera !== null"
                        :src="fileURL + '/' + info_organizationtt.file_bendera"
                        style="width: 60%; margin-bottom: 20px; margin-left: 8px;opacity:20%;" alt="abstract"
                        @error="replaceByDefault" />
                      <img v-else src="https://alppetro.co.id/dist/assets/images/default.jpg"
                        style="width: 60%; margin-bottom: 20px; margin-left: 8px;opacity:20%;" alt="abstract" />
                    </b-row>
                  </b-col>
                </b-row>
              </b-tab>
              <b-tab title="Struktur Ormas" style="background-color:white;margin-top:0px;">
                <div class="tab-content">
                  <!--begin::Table-->
                  <div class="table-responsive">
                    <table class="
                  table
                  table-head-custom
                  table-vertical-center
                  table-head-bg
                  table-bordered
                ">
                      <thead>
                        <tr class="text-left">
                          <th style="width: 100px; padding-left:0px; padding-right: 0px;" class="text-capitalize">Foto
                          </th>
                          <th style="width: 170px; padding-left:0px; padding-right: 0px;" class="text-capitalize">Nama
                          </th>
                          <th style="width: 200px; padding-left:0px; padding-right: 0px;" class="text-capitalize">Masa
                            Bakti</th>
                          <th style="width: 90px; padding-left:0px; padding-right: 0px;" class="text-capitalize">Jabatan
                          </th>
                          <th v-if="userType == 'admin'" style="width: 100px; padding-left:0px; padding-right: 0px;"
                            class="text-capitalize">No. Identitas</th>
                          <th style="width: 100px; padding-left:0px; padding-right: 0px;" class="text-capitalize">
                            Tanggal Lahir</th>
                          <th v-if="userType == 'admin'" style="width: 100px; padding-left:0px; padding-right: 0px;"
                            class="text-capitalize">No. HP</th>
                        </tr>
                      </thead>
                      <tbody style="background:#FAFAFA;">
                        <template v-for="(item, i) in structure_organizationtt.pengurusInti">
                          <tr v-bind:key="i">
                            <td>
                              <img v-if="item.foto !== null && item.foto !== ''" :src="item.foto"
                                style="width:64px;height:64px;" />
                              <img v-else
                                src="/assets/images/default-thumbnail.jpg"
                                style="width: 60%; margin-bottom: 20px; margin-left: 8px;opacity:20%;" alt="abstract" />
                            </td>
                            <td>
                              <span class="
                            text-dark-75
                            font-weight-bolder
                            d-block
                            text-left
                            font-size-lg
                          ">{{ item ? item.nama_pengurus : '' }}</span>
                            </td>
                            <td>
                              <span class="
                            text-dark-75
                            font-weight-bolder
                            d-block
                            text-left
                            font-size-lg
                          ">{{ item.periode_mulai | moment("DD/MM/YYYY") }} - {{ item.periode_selesai |
                          moment("DD/MM/YYYY") }}</span>
                            </td>
                            <td>
                              <span class="
                            text-dark-75
                            text-left
                            font-weight-bolder
                            d-block
                            font-size-lg
                          ">{{ item.masterJabatan ? item.masterJabatan.nama_jabatan : '-' }}</span>
                            </td>
                            <td v-if="userType == 'admin'">
                              <span class="
                            text-left
                            text-dark-75
                            font-weight-bolder
                            d-block
                            font-size-lg
                          ">{{ item.identitas }}</span>
                            </td>
                            <td>
                              <span class="
                            text-dark-75
                            text-left
                            font-weight-bolder
                            d-block
                            font-size-lg
                          ">{{item.tanggal_lahir | moment("DD-MM-YYYY")}}</span>
                            </td>
                            <td v-if="userType == 'admin'">
                              <span class="
                            text-dark-75
                            font-weight-bolder
                            text-left
                            d-block
                            font-size-lg
                          ">{{ item.no_hp }}</span>
                            </td>
                          </tr>
                        </template>
                      </tbody>
                    </table>
                  </div>

                  <!--end::Table-->
                  <div class="mt-5" v-if="structure_organizationtt">
                    <b-row class="d-flex align-items-center">
                      <b-col sm="4">
                        <p style="font-weight: 600; margin:0px;">SK Susunan Pengurus</p>
                      </b-col>:<b-col sm="6">
                        <b-button
                          v-if="info_organizationtt.file_sk_pengurus != null && info_organizationtt.file_sk_pengurus != ''"
                          @click="newTab(fileURL + info_organizationtt.file_sk_pengurus)" size="sm" variant="success">
                          <v-icon style="color:white;" small>mdi-clipboard</v-icon>Lihat File Unggahan
                        </b-button>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col sm="4">
                        <p style="font-weight: 600">Daftar Pendiri Organisasi</p>
                      </b-col>:<b-col sm="6">
                        <span v-for="(item,index) in structure_organizationtt.listPengurus" :key="index">
                          <span
                            v-if="item.masterKategoriPengurus.kode_kategori_pengurus == 'pendiri'">{{item ? item.nama_pengurus : ''}};</span>
                        </span>
                      </b-col>
                    </b-row>
                    <b-row v-if="userType == 'admin'">
                      <b-col sm="4">
                        <p style="font-weight: 600">NIK daftar Pendiri Organisasi</p>
                      </b-col>:<b-col sm="6">
                        <span v-for="(item,index) in structure_organizationtt.listPengurus" :key="index">
                          <span
                            v-if="item.masterKategoriPengurus.kode_kategori_pengurus == 'pendiri'">{{item.identitas}};</span>
                        </span>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col sm="4">
                        <p style="font-weight: 600">Daftar Pembina Organisasi</p>
                      </b-col>:<b-col sm="6">
                        <span v-for="(item,index) in structure_organizationtt.listPengurus" :key="index">
                          <span
                            v-if="item.masterKategoriPengurus.kode_kategori_pengurus == 'pembina'">{{item ? item.nama_pengurus : ''}};</span>
                        </span>
                      </b-col>
                    </b-row>
                    <b-row v-if="userType == 'admin'">
                      <b-col sm="4">
                        <p style="font-weight: 600">NIK daftar Pembina Organisasi</p>
                      </b-col>:<b-col sm="6">
                        <span v-for="(item,index) in structure_organizationtt.listPengurus" :key="index">
                          <span
                            v-if="item.masterKategoriPengurus.kode_kategori_pengurus == 'pembina'">{{item.identitas}};</span>
                        </span>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col sm="4">
                        <p style="font-weight: 600">Daftar Penasehat Organisasi</p>
                      </b-col>:<b-col sm="6">
                        <span v-for="(item,index) in structure_organizationtt.listPengurus" :key="index">
                          <span
                            v-if="item.masterKategoriPengurus.kode_kategori_pengurus == 'penasehat'">{{item ? item.nama_pengurus : ''}};</span>
                        </span>
                      </b-col>
                    </b-row>
                    <b-row v-if="userType == 'admin'">
                      <b-col sm="4">
                        <p style="font-weight: 600">NIK daftar Penasehat Organisasi</p>
                      </b-col>:<b-col sm="6">
                        <span v-for="(item,index) in structure_organizationtt.listPengurus" :key="index">
                          <span
                            v-if="item.masterKategoriPengurus.kode_kategori_pengurus == 'penasehat'">{{item.identitas}};</span>
                        </span>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col sm="4">
                        <p style="font-weight: 600">Daftar Pelindung Organisasi</p>
                      </b-col>:<b-col sm="6">
                        <span v-for="(item,index) in structure_organizationtt.listPengurus" :key="index">
                          <span
                            v-if="item.masterKategoriPengurus.kode_kategori_pengurus == 'pelindung'">{{item ? item.nama_pengurus : ''}};</span>
                        </span>
                      </b-col>
                    </b-row>
                    <b-row v-if="userType == 'admin'">
                      <b-col sm="4">
                        <p style="font-weight: 600">NIK daftar Pelindung Organisasi</p>
                      </b-col>:<b-col sm="6">
                        <span v-for="(item,index) in structure_organizationtt.listPengurus" :key="index">
                          <span
                            v-if="item.masterKategoriPengurus.kode_kategori_pengurus == 'pelindung'">{{item.identitas}};</span>
                        </span>
                      </b-col>
                    </b-row>

                  </div>
                </div>
              </b-tab>
              <b-tab v-if="userType == 'admin'" title="Dokumen Ormas" style="background-color:white;margin-top:0px;">
                <div class="table-responsive">
                  <table class="
                      table
                      table-head-custom
                      table-vertical-center
                      table-head-bg
                      table-bordered
                    ">
                    <thead>
                      <tr class="text-left text-capitalize">
                        <th style="width: 20px" class="text-capitalize">No</th>
                        <th style="min-width: 50px;" class="text-capitalize">Deskripsi</th>
                        <th style="min-width: 200px" class="text-capitalize">Aksi</th>
                      </tr>
                    </thead>
                    <tbody style="background:#FAFAFA;">
                      <template v-for="(item, i) in listDokumen">
                        <tr v-bind:key="i">
                          <td>
                            <span class="
                          text-dark-75
                          font-weight-bolder
                          d-block
                          font-size-lg
                        ">{{ i + 1 }}</span>
                          </td>
                          <td class="text-left">
                            {{ item.namaJenisDokumen}}
                          </td>
                          <td class="d-flex justify-content-center">
                            <!-- Download Button -->
                            <b-button v-if="cekUploaded(item.id)" @click="downloadItemTT(item.id)"
                              size="sm" variant="primary" class="mr-3">
                              <v-icon style="color:white;" small>mdi-download</v-icon>Unduh
                            </b-button>
                            <b-button v-if="!cekUploaded(item.id)" disabled
                              size="sm" variant="primary" class="mr-3">
                              <v-icon style="color:white;" small>mdi-download</v-icon>Unduh
                            </b-button>

                            <!-- Preview Button -->
                            <b-button v-if="cekUploaded(item.id)" variant="success" size="sm" @click="seeUploaded(item.id)">
                              <v-icon style="color:white;" small>mdi-eye</v-icon><span>Lihat</span>
                            </b-button>
                            <b-button v-if="!cekUploaded(item.id)" disabled variant="success" size="sm">
                              <v-icon style="color:white;" small>mdi-eye</v-icon><span>Lihat</span>
                            </b-button>
                          </td>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                </div>
              </b-tab>
              <b-tab title="Rekening" style="background-color:white;margin-top:0px;">
                <div class="table-responsive">
                  <table class="
                      table
                      table-head-custom
                      table-vertical-center
                      table-head-bg
                      table-bordered
                    ">
                    <thead>
                      <tr class="text-left">
                        <th style="width: 20px">No</th>
                        <th style="min-width: 50px" class="text-capitalize">Nomor Rekening</th>
                        <th style="min-width: 50px" class="text-capitalize">Nama Bank</th>
                        <th style="min-width: 50px" class="text-capitalize">Pemilik rekening</th>
                        <th style="min-width: 50px" class="text-capitalize">Jenis rekening</th>
                      </tr>
                    </thead>
                    <tbody v-if="bank_account_organizationtt.length == 0">
                      <template>
                        <tr>
                          <td colspan="6"> -- Tidak ada rekening -- </td>
                        </tr>
                      </template>
                    </tbody>
                    <tbody style="background:#FAFAFA;">
                      <template v-for="(item, i) in bank_account_organizationtt">
                        <tr v-bind:key="i">
                          <td>
                            <span class="
                          text-dark-75
                          font-weight-bolder
                          d-block
                          font-size-lg
                        ">{{ i + 1 }}</span>
                          </td>
                          <td>
                            <span class="
                          text-dark-75
                          text-left
                          font-weight-bolder
                          d-block
                          font-size-lg
                        ">{{item.no_rekening}}</span>
                          </td>
                          <td>
                            <span class="
                          text-dark-75
                          font-weight-bolder
                          text-left
                          d-block
                          font-size-lg
                        ">{{item.nama_bank}}</span>
                          </td>
                          <td>
                            <span class="
                          text-dark-75
                          font-weight-bolder
                          text-left
                          d-block
                          font-size-lg
                        ">{{item.nama_pemilik_rekening}}</span>
                          </td>
                          <td>
                            <span v-if="item.masterJenisRekening && item.masterJenisRekening.nama" class="
                          text-dark-75
                          text-left
                          font-weight-bolder
                          d-block
                          font-size-lg
                        ">{{item.masterJenisRekening.nama}}</span>
                          </td>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                </div>
              </b-tab>
              <b-tab title="Cabang Ormas" style="background-color:white;margin-top:0px;">
                <div class="table-responsive" style="border-radius:15px;">
                  <table class="
                    table
                    table-head-custom
                    table-vertical-center
                    table-head-bg
                    table-bordered
                  " style="font-size:14px !important;">
                    <thead>
                      <tr class="text-left">
                        <th style="width: 20px" class="text-capitalize">No.</th>
                        <th style="min-width: 50px" class="text-capitalize">Status Sekretariat</th>
                        <th style="min-width: 50px" class="text-capitalize">Alamat</th>
                        <th style="min-width: 50px" class="text-capitalize">Kabupaten/Kota</th>
                        <th style="min-width: 50px" class="text-capitalize">Provinsi</th>
                        <th style="min-width: 50px" class="text-capitalize">Status</th>
                        <th style="min-width: 50px" class="text-capitalize">Aksi</th>
                      </tr>
                    </thead>
                    <tbody style="background:white;">
                      <template v-for="(item, i) in listCabangtt">
                        <tr v-bind:key="i">
                          <td>
                            <span class="
                              text-dark-75
                              font-weight-bolder
                              d-block
                              font-size-lg
                            ">{{ i + 1}}</span>
                          </td>
                          <td>
                            <span class="
                              text-dark-75
                              text-left
                              font-weight-bolder
                              d-block
                              font-size-lg
                            ">{{ item.m_lokasi_sekretariat_id}}</span>
                          </td>
                          <td>
                            <span class="
                              text-dark-75
                              font-weight-bolder
                              d-block
                              text-left
                              font-size-lg
                            " v-if="item.alamat_sekretariat">{{ item.alamat_sekretariat.length > 30 ?
                            item.alamat_sekretariat.substring(0,30) + "..." : item.alamat_sekretariat }}</span>
                          </td>
                          <td>
                            <span class="
                              text-dark-75
                              font-weight-bolder
                              d-block
                              text-left
                              font-size-lg
                            " v-if="item.kabupaten_kota_sekretariat">{{ item.kabupaten_kota_sekretariat}}</span>
                          </td>
                          <td>
                            <span class="
                              text-dark-75
                              text-left
                              font-weight-bolder
                              d-block
                              font-size-lg
                            " v-if="item.provinsi_sekretariat">{{ item.provinsi_sekretariat }}</span>
                          </td>
                          <td>
                            <span class="
                              text-dark-75
                              font-weight-bolder
                              text-left
                              d-block
                              font-size-lg
                            "></span>
                          </td>
                          <td>
                            <b-button variant="success" size="sm" @click="getDetailCabangtt(item.id)">
                              >> Detail
                            </b-button>
                          </td>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                </div>
              </b-tab>
            </b-tabs>
          </b-row>
        </b-container>
      </div>
    </b-modal>
    <!-- modal detail ormas asing -->
    <b-modal id="modal-detailasing-ormas" ok-only centered size="xl" content-class="shadow" hide-header hide-footer>
      <div>
        <b-container fluid class="pl-0 pr-0">
          <b-row class="pt-5 mt-2 mb-4">
            <b-col class="d-flex justify-content-between pr-0">
              <b style="font-size:20px;">
                {{activeTab == 0 ?'Profil Ormas WNA' : ''}}
                {{activeTab == 1 ?'Lokus Kegiatan Ormas WNA' : ''}}
                {{activeTab == 2 ?'Kemitraan Ormas WNA' : ''}}
                {{activeTab == 3 ?'Tenaga Kerja Asing' : ''}}
                {{activeTab == 4 ?'Pelaporan Ormas WNA' : ''}}
              </b>
              <div>
                <b-button variant="outline-secondary" style="background: #FFFFFF;" size="sm" class="mr-4"
                  @click="$bvModal.hide('modal-detailasing-ormas')">
                  <v-icon class="pr-3">mdi-close</v-icon>
                  Tutup
                </b-button>
              </div>
            </b-col>
          </b-row>
          <b-tabs pills card justified v-model="activeTab" style="border-radius:10px;">
            <b-tab active title="Profil" style="background-color:#FAFAFA;margin-top:0px;">
              <b-row>
                <b-col cols="12">
                  <b-row class="pt-3 pb-3 align-items-center">
                    <b-col sm="3">
                      <p style="font-weight: 600; margin:0px;">{{ "Nama Ormas WNA" }}</p>
                    </b-col>
                    :
                    <b-col sm="6">
                      <span class="text-dark-75 font-weight-bolder text-left font-size-lg" style="white-space: pre-wrap">{{ dataOrmasing.namaOrmas }}</span>
                    </b-col>
                  </b-row>
                  <b-row class="pt-3 pb-3 align-items-center">
                    <b-col sm="3">
                      <p style="font-weight: 600; margin:0px;">{{ "Negara" }}</p>
                    </b-col>
                    :
                    <b-col sm="6">
                      <span class="text-dark-75 font-weight-bolder text-left font-size-lg">{{ this.Negara }}</span>
                    </b-col>
                  </b-row>
                  <b-row class="pt-3 pb-3 align-items-center">
                    <b-col sm="3">
                      <p style="font-weight: 600; margin:0px;">{{ "Benua" }}</p>
                    </b-col>
                    :
                    <b-col sm="6">
                      <span class="text-dark-75 font-weight-bolder text-left font-size-lg">{{ dataOrmasing.benua }}</span>
                    </b-col>
                  </b-row>
                  <b-row class="pt-3 pb-3 align-items-center">
                    <b-col sm="3">
                      <p style="font-weight: 600; margin:0px;">{{ "Nomor MSP" }}</p>
                    </b-col>
                    :
                    <b-col sm="6">
                      <span class="text-dark-75 font-weight-bolder text-left font-size-lg">{{ dataOrmasing.nomorMsp }}</span>
                    </b-col>
                  </b-row>
                  <b-row class="pt-3 pb-3 align-items-center">
                    <b-col sm="3">
                      <p style="font-weight: 600; margin:0px;">{{ "Unggah MSP" }}</p>
                    </b-col>
                    :
                    <b-col sm="2">
                      <a v-if="dataOrmasing.fileNomorMsp && dataOrmasing.fileNomorMsp != ''"
                        :href="fileURL + dataOrmasing.fileNomorMsp" target="_blank" type="button">
                        <b-button variant="success" size="sm">
                          Lihat File
                        </b-button>
                      </a>
                    </b-col>
                  </b-row>
                  <b-row class="pt-3 pb-3 d-flex align-items-center">
                    <b-col sm="3">
                      <p style="font-weight: 600; margin:0px;">{{ "Unggah AP" }}</p>
                    </b-col>
                    :
                    <b-col sm="2">
                      <a v-if="dataOrmasing.fileAp && dataOrmasing.fileAp != ''"
                        :href="fileURL + dataOrmasing.fileAp" target="_blank" type="button">
                        <b-button variant="success" size="sm">
                          Lihat File
                        </b-button>
                      </a>
                    </b-col>
                  </b-row>
                  <b-row class="pt-3 pb-3 d-flex align-items-center">
                    <b-col sm="3">
                      <p style="font-weight: 600; margin:0px;">{{ "Unggah RIK" }}</p>
                    </b-col>
                    :
                    <b-col sm="2">
                      <a v-if="dataOrmasing.fileRik && dataOrmasing.fileRik != ''"
                        :href="fileURL + dataOrmasing.fileRik" target="_blank" type="button">
                        <b-button variant="success" size="sm">
                          Lihat File
                        </b-button>
                      </a>
                    </b-col>
                  </b-row>
                  <b-row :class="programOA.length == 1 ? 'pt-3 pb-3 align-items-center' : 'pt-3 pb-3 '">
                    <b-col sm="3">
                      <p style="font-weight: 600; margin:0px;">{{ "Program" }}</p>
                    </b-col>
                    :
                    <b-col sm="8">
                      <div v-for="(i,index) in programOA" :key="index" class="pb-2">
                        <span class="text-dark-75 font-weight-bolder text-left font-size-lg">{{ index + 1}}. {{ i }}</span>
                      </div>
                    </b-col>
                  </b-row>
                  <b-row class="pt-3 pb-3 align-items-center">
                    <b-col sm="3">
                      <p style="font-weight: 600; margin:0px;">{{ "Mulai MSP" }}</p>
                    </b-col>
                    :
                    <b-col sm="6">
                      <span  class="text-dark-75 font-weight-bolder text-left font-size-lg">{{ dataOrmasing.mulaiMsp  | moment('DD - MM - YYYY') }}</span>
                    </b-col>
                  </b-row>
                  <b-row class="pt-3 pb-3 align-items-center">
                    <b-col sm="3">
                      <p style="font-weight: 600; margin:0px;">{{ "Akhir MSP" }}</p>
                    </b-col>
                    :
                    <b-col sm="6">
                      <span class="text-dark-75 font-weight-bolder text-left font-size-lg">{{ dataOrmasing.akhirMsp  | moment('DD - MM - YYYY') }}</span>
                    </b-col>
                  </b-row>
                  <b-row class="pt-3 pb-3 align-items-center">
                    <b-col sm="3">
                      <p style="font-weight: 600; margin:0px;">{{ "Sisa Masa MSP" }}</p>
                    </b-col>
                    :
                    <b-col sm="6">
                      <span class="text-dark-75 font-weight-bolder text-left font-size-lg">{{this.sisaAktif}}</span>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab title="Lokus Kegiatan" style="background-color:#FAFAFA;margin-top:0px;">
              <b-row>
                <b-col cols="12">
                  <b-row class="mt-5">
                    <b-col class="d-flex align-items-center justify-content-between">
                      <span style="font-size:16px; font-weight:bold;"></span>
                    </b-col>
                  </b-row>
                  <div class="table-responsive mt-4" style="border-radius:15px;">
                    <table class="table table-head-custom table-vertical-center table-head-bg table-bordered">
                      <thead>
                        <tr class="text-left">
                          <th style="width: 20px">No</th>
                          <th style="min-width: 50px" class="text-capitalize">Provinsi</th>
                          <th style="min-width: 50px" class="text-capitalize">Kabupaten/Kota</th>
                          <th style="min-width: 50px" class="text-capitalize">Kecamatan</th>
                          <th style="min-width: 50px" class="text-capitalize">Kelurahan/Desa</th>
                          <th style="width: 100px" class="text-capitalize">Aksi</th>
                        </tr>
                      </thead>
                      <tbody v-if="asingLokuses.length == 0">
                        <template v-if="!loadingTable">
                          <tr>
                            <td colspan="6"> -- Tidak ada data -- </td>
                          </tr>
                        </template>
                      </tbody>
                      <tbody style="background:#FAFAFA;" v-if="asingLokuses.length !== 0">
                        <template v-for="(itemLA, indexLA) in asingLokuses">
                          <tr v-bind:key="indexLA">
                            <td>
                              <span class="text-dark-75 font-weight-bolder d-block font-size-lg">
                                {{ indexLA + 1 }}
                              </span>
                            </td>
                            <td>
                              <span class="text-dark-75 font-weight-bolder text-left font-size-lg">{{ itemLA.masterProvinsi }}</span>
                            </td>
                            <td>
                              <span class="text-dark-75 font-weight-bolder text-left font-size-lg">{{ itemLA.masterKabupatenKota }}</span>
                            </td>
                            <td>
                              <span class="text-dark-75 font-weight-bolder text-left font-size-lg">{{ itemLA.masterKecamatan }}</span>
                            </td>
                            <td>
                              <span class="text-dark-75 font-weight-bolder text-left font-size-lg">{{ itemLA.masterKelurahan }}</span>
                            </td>
                            <td class="d-flex justify-content-center">
                              <span class="text-dark-75 font-weight-bolder text-left font-size-lg">
                                <section class="d-flex justify-content-center align-items-center">
                                  <b-button @click="toDetailLokus(itemLA)"
                                    class="d-flex justify-content-center align-items-center" variant="success" size="sm"
                                    style="width:30px;height:30px;margin:5px;">
                                    >>
                                  </b-button>
                                </section>
                              </span>
                            </td>
                          </tr>
                        </template>
                      </tbody>
                    </table>
                  </div>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab title="Kemitraan" style="background-color:#FAFAFA;margin-top:0px;">
              <b-row>
                <input style="display: none;" />
                <b-col cols="12">
                  <b-row style="font-weight: 800;">
                    A. DATA MITRA K/L
                  </b-row>
                  <b-row class="pt-3 pb-3 align-items-center">
                    <b-col sm="3">
                      <p style="font-weight: 600; margin:0px;">{{ "Mitra K/L" }}</p>
                    </b-col>
                    :
                    <b-col sm="6">
                      <span class="text-dark-75 font-weight-bolder text-left font-size-lg">{{ asingKemitraan.mitraKl }}</span>
                    </b-col>
                  </b-row>
                  <b-row class="pt-3 pb-3 align-items-center">
                    <b-col sm="3">
                      <p style="font-weight: 600; margin:0px;">{{ "Nama Penandatangan MSP" }}</p>
                    </b-col>
                    :
                    <b-col sm="6">
                      <span class="text-dark-75 font-weight-bolder text-left font-size-lg">{{ asingKemitraan.mitraPejabat }}</span>
                    </b-col>
                  </b-row>
                  <b-row class="pt-3 pb-3 align-items-center">
                    <b-col sm="3">
                      <p style="font-weight: 600; margin:0px;">{{ "Jabatan Penandatangan MSP" }}</p>
                    </b-col>
                    :
                    <b-col sm="6">
                      <span class="text-dark-75 font-weight-bolder text-left font-size-lg">{{ asingKemitraan.mitraJabatan }}</span>
                    </b-col>
                  </b-row>
                  <b-row style="font-weight: 800;">
                    B. DATA MITRA SKPD
                  </b-row>
                  <div class="table-responsive">
                    <table class="table table-head-custom table-vertical-center table-head-bg table-bordered">
                      <thead>
                        <tr class="text-left">
                          <th style="width: 20px">No</th>
                          <th style="min-width: 50px" class="text-capitalize">Nama SKPD</th>
                          <th style="min-width: 50px" class="text-capitalize">No RKT</th>
                          <th style="min-width: 50px" class="text-capitalize">Nama Pejabat Penandatangan</th>
                          <th style="min-width: 50px" class="text-capitalize">Unggah RKT</th>
                        </tr>
                      </thead>
                      <tbody v-if="asingKemitraanLkpds.length == 0">
                        <template v-if="!loadingTable">
                          <tr>
                            <td colspan="6"> -- Tidak ada data -- </td>
                          </tr>
                        </template>
                      </tbody>
                      <tbody style="background:#FAFAFA;"  v-if="asingKemitraanLkpds.length !== 0">
                        <template v-for="(itemL, indexL) in asingKemitraanLkpds">
                          <tr v-bind:key="indexL">
                            <td>
                              <span class="text-dark-75 font-weight-bolder d-block font-size-lg">
                                {{ indexL + 1 }}
                              </span>
                            </td>
                            <td>
                              <span class="text-dark-75 font-weight-bolder text-left font-size-lg">{{ itemL.nama }}</span>
                            </td>
                            <td>
                              <span class="text-dark-75 font-weight-bolder text-left font-size-lg">{{ itemL.namaPejabat }}</span>
                            </td>
                            <td>
                              <span class="text-dark-75 font-weight-bolder text-left font-size-lg">{{ itemL.noRkt }}</span>
                            </td>
                            <td>
                              <a v-if="itemL.fileRkt && itemL.fileRkt != ''" :href="fileURL + itemL.fileRkt"
                                target="_blank" type="button">
                                <b-button variant="success" size="sm">
                                  Lihat File
                                </b-button>
                              </a>
                            </td>
                          </tr>
                        </template>
                      </tbody>
                    </table>
                  </div>
                  <b-row style="font-weight: 800;">
                    C. DATA MITRA ORMAS LOKAL
                  </b-row>
                  <div class="table-responsive">
                    <table class="table table-head-custom table-vertical-center table-head-bg table-bordered">
                      <thead>
                        <tr class="text-left">
                          <th style="width: 20px">No</th>
                          <th style="width: 252px;" class="text-capitalize">Jenis Ormas Lokal</th>
                          <th style="min-width: 50px" class="text-capitalize">Nama Ormas Lokal</th>
                        </tr>
                      </thead>
                      <tbody v-if="asingKemitraan.ormasLokal.length == 0">
                        <template v-if="!loadingTable">
                          <tr>
                            <td colspan="6"> -- Tidak ada data -- </td>
                          </tr>
                        </template>
                      </tbody>
                      <tbody style="background:#FAFAFA;" v-if="asingKemitraan.ormasLokal.length !== 0">
                        <template v-for="(itemo, indexo) in asingKemitraan.ormasLokal">
                          <tr v-bind:key="indexo">
                            <td>
                              <span class="text-dark-75 font-weight-bolder d-block font-size-lg">
                                {{ indexo + 1 }}
                              </span>
                            </td>
                            <td>
                              <span v-if="itemo.idOrmasLokal === 0" class="text-dark-75 font-weight-bolder text-left font-size-lg">Ormas Berbadan Hukum</span>
                              <span v-if="itemo.idOrmasLokal !== 0" class="text-dark-75 font-weight-bolder text-left font-size-lg">Ormas Tidak Berbadan Hukum</span>
                            </td>
                            <td>
                              <span v-if="itemo.idOrmasLokal === 0" class="text-dark-75 font-weight-bolder text-left font-size-lg">{{  itemo.namaOrmasBh }}</span>
                              <span v-if="itemo.idOrmasLokal !== 0"  class="text-dark-75 font-weight-bolder text-left font-size-lg">{{ namaOrmasSKTAsing[itemo.idOrmasLokal] }}</span>
                            </td>
                          </tr>
                        </template>
                      </tbody>
                    </table>
                  </div>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab title="Tenaga Kerja Asing" style="background-color:#FAFAFA;margin-top:0px;">
              <b-row>
                <input style="display: none;" />
                <b-col cols="12">
                  <div class="table-responsive">
                    <table class="table table-head-custom table-vertical-center table-head-bg table-bordered">
                      <thead>
                        <tr class="text-left">
                          <th style="width: 20px">No</th>
                          <th style="min-width: 50px" class="text-capitalize">Nama Tenaga Kerja</th>
                          <th style="min-width: 50px" class="text-capitalize">Kewarganegaraan</th>
                          <th style="min-width: 50px" class="text-capitalize">Nomor Paspor/KITAP/KITAS</th>
                        </tr>
                      </thead>
                      <tbody v-if="asingAnggotas.length == 0">
                        <template v-if="!loadingTable">
                          <tr>
                            <td colspan="6"> -- Tidak ada data -- </td>
                          </tr>
                        </template>
                      </tbody>
                      <tbody style="background:#FAFAFA;" v-if="asingAnggotas.length !== 0">
                        <template v-for="(itemAng, indexAng) in asingAnggotas">
                          <tr v-bind:key="indexAng">
                            <td>
                              <span class="text-dark-75 font-weight-bolder d-block font-size-lg">
                                {{ indexAng + 1 }}
                              </span>
                            </td>
                            <td>
                              <span class="text-dark-75 font-weight-bolder text-left font-size-lg">{{ itemAng.nama }}</span>
                            </td>
                            <td>
                              <span class="text-dark-75 font-weight-bolder text-left font-size-lg">{{ itemAng.negara }}</span>
                            </td>
                            <td>
                              <span class="text-dark-75 font-weight-bolder text-left font-size-lg">{{ itemAng.nomorIdentitas }}</span>
                            </td>
                          </tr>
                        </template>
                      </tbody>
                    </table>
                  </div>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab title="Pelaporan" style="background-color:#FAFAFA;margin-top:0px;">
              <b-row>
                <input style="display: none;" />
                <b-col cols="12">
                  <div class="table-responsive mt-4" style="border-radius:15px;">
                    <table class="table table-head-custom table-vertical-center table-head-bg table-bordered">
                      <thead>
                        <tr class="text-left">
                          <th style="width: 20px">No</th>
                          <th style="min-width: 50px" class="text-capitalize">Tanggal Laporan</th>
                          <th style="min-width: 50px" class="text-capitalize">Nama Pelapor</th>
                          <th style="min-width: 50px" class="text-capitalize">Jabatan Pelapor</th>
                          <th style="min-width: 50px" class="text-capitalize">Instansi</th>
                          <th style="width: 100px" class="text-capitalize">Aksi</th>
                        </tr>
                      </thead>
                      <tbody v-if="asingPelaporans.length == 0">
                        <template v-if="!loadingTable">
                          <tr>
                            <td colspan="6"> -- Tidak ada data -- </td>
                          </tr>
                        </template>
                      </tbody>
                      <tbody style="background:#FAFAFA;" v-if="asingPelaporans.length !== 0">
                        <template v-for="(item, index) in asingPelaporans">
                          <tr v-bind:key="index">
                            <td>
                              <span class="text-dark-75 font-weight-bolder d-block font-size-lg">
                                {{ index + 1 }}
                              </span>
                            </td>
                            <td>
                              <span class="text-dark-75 font-weight-bolder d-block font-size-lg">{{ item.tanggal  | moment('DD - MM - YYYY') }}</span>
                            </td>
                            <td>
                              <span class="text-dark-75 font-weight-bolder d-block font-size-lg">{{ item.namaPelapor }}</span>
                            </td>
                            <td>
                              <span class="text-dark-75 font-weight-bolder d-block font-size-lg">{{ item.jabatan }}</span>
                            </td>
                            <td>
                              <span class="text-dark-75 font-weight-bolder d-block font-size-lg">{{ item.instansi }}</span>
                            </td>
                            <td class="d-flex justify-content-center">
                              <b-button @click="toDetailPelaporan(item)" class="d-flex justify-content-center align-items-center" variant="success"
                                size="sm" style="width:30px;height:30px;margin:5px;">
                                >>
                              </b-button>
                            </td>
                          </tr>
                        </template>
                      </tbody>
                    </table>
                  </div>
                </b-col>
              </b-row>
            </b-tab>
          </b-tabs>
        </b-container>
      </div>
    </b-modal>
    <!-- modal detail ormas bdan hukum -->
    <b-modal id="modal-profil-badan-hukum" ok-only centered size="xl" content-class="shadow" hide-header hide-footer>
      <div>
        <b-container fluid>
          <b-row class="pt-5 mt-2 pr-0 pl-0">
            <b-col class="d-flex justify-content-between pr-0 pl-0">
              <b style="font-size:20px;">
                Profil Ormas Badan Hukum
              </b>
              <div>
                <b-button variant="outline-secondary" style="background: #FFFFFF;" size="sm" class="mr-4"
                  @click="$bvModal.hide('modal-profil-badan-hukum')">
                  <v-icon class="pr-3">mdi-close</v-icon>
                  Tutup
                </b-button>
              </div>
            </b-col>
          </b-row>
          <b-row class="pt-5">
            <b-tabs pills card id="detail-tab" justified v-model="tabDetail" style="border-radius:10px;width:100%;">

              <b-tab title="Profil" style="background-color:white;margin-top:0px;">
                <b-row>
                  <b-col cols="7">
                    <b-row>
                      <b-col sm="3">
                        <p style="font-weight: 600">{{ "Nama Ormas" }}</p>
                      </b-col>:<b-col sm="6" style="white-space: pre-wrap">{{ nama }}</b-col>
                    </b-row>
                    <b-row>
                      <b-col sm="3">
                        <p style="font-weight: 600">{{ "Jenis Badan Hukum" }}</p>
                      </b-col>:<b-col sm="6" v-if="jenis_ormas_badan_hukum === 1">{{ 'Perkumpulan'}}</b-col>
                      <b-col sm="6" v-if="jenis_ormas_badan_hukum === 2">{{ 'Perkumpulan'}}</b-col>
                      <b-col sm="6" v-if="jenis_ormas_badan_hukum === 0">{{ '-'}}</b-col>
                    </b-row>
                    <b-row class="pt-0 pb-3 d-flex align-items-center">
                      <b-col sm="3">
                        <p style="font-weight: 600; margin:0px;">{{ "Nomor SK Badan Hukum" }}</p>
                      </b-col>:<b-col sm="6">{{ nomor_sk }}
                      </b-col>
                    </b-row>
                    <b-row class="pt-0 pb-3 d-flex align-items-center">
                      <b-col sm="3">
                        <p style="font-weight: 600">{{ "Tanggal Terbit SK Badan Hukum" }}</p>
                      </b-col>:
                      <b-col sm="6" style="white-space: pre-wrap">
                        {{ tanggal_sk | moment('DD - MM - YYYY')}}
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col sm="3">
                        <p style="font-weight: 600">
                          {{ "Alamat sekretariat" }}
                        </p>
                      </b-col>:<b-col sm="6">{{ domisili_terakhir.alamat }}</b-col>
                    </b-row>
                    <b-row>
                      <b-col sm="3">
                        <p style="font-weight: 600">
                          {{ "RT" }}
                        </p>
                      </b-col>:<b-col sm="6">{{ domisili_terakhir.rt }}</b-col>
                    </b-row>
                    <b-row>
                      <b-col sm="3">
                        <p style="font-weight: 600">
                          {{ "RW" }}
                        </p>
                      </b-col>:<b-col sm="6">{{ domisili_terakhir.rw }}</b-col>
                    </b-row>
                    <b-row>
                      <b-col sm="3">
                        <p style="font-weight: 600">
                          {{ "Kelurahan" }}
                        </p>
                      </b-col>:<b-col sm="6">{{ domisili_terakhir.kode_kelurahan }}</b-col>
                    </b-row>
                    <b-row>
                      <b-col sm="3">
                        <p style="font-weight: 600">
                          {{ "Kecamatan" }}
                        </p>
                      </b-col>:<b-col sm="6">{{ domisili_terakhir.kode_kecamatan }}</b-col>
                    </b-row>
                    <b-row>
                      <b-col sm="3">
                        <p style="font-weight: 600">{{ "Kabupaten/Kota" }}</p>
                      </b-col>:<b-col sm="6">{{ nama_kedudukan_kabupaten }}</b-col>
                    </b-row>
                    <b-row>
                      <b-col sm="3">
                        <p style="font-weight: 600">{{ "Provinsi" }}</p>
                      </b-col>:<b-col sm="6">{{ nama_kedudukan_provinsi }}</b-col>
                    </b-row>
                    <b-row>
                      <b-col sm="3">
                        <p style="font-weight: 600">
                          {{ "Keterangan Sosial" }}
                        </p>
                      </b-col>:
                      <b-col sm="6">
                        <ul class="ml-5" v-if="ket_sosial === '{}' || ket_sosial === null || ket_sosial === ''">
                          -
                        </ul>
                        <ul class="ml-5" v-else>
                          {{ ket_sosial }}
                        </ul>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col sm="3">
                        <p style="font-weight: 600">
                          {{ "Keterangan Sosial Lainnya" }}
                        </p>
                      </b-col>:
                      <b-col sm="6">
                        <ul class="ml-5" v-if="ket_sosial_lainnya === '{}' || ket_sosial_lainnya === null || ket_sosial_lainnya === ''">
                          -
                        </ul>
                        <ul class="ml-5" v-else>
                          {{ ket_sosial_lainnya }}
                        </ul>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col sm="3">
                        <p style="font-weight: 600">
                          {{ "Keterangan Kemanusiaan" }}
                        </p>
                      </b-col>:
                      <b-col sm="6">
                        <ul class="ml-5" v-if="ket_kemanusiaan === '{}' || ket_kemanusiaan === null || ket_kemanusiaan === ''">
                          -
                        </ul>
                        <ul class="ml-5" v-else>
                          {{ ket_kemanusiaan }}
                        </ul>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col sm="3">
                        <p style="font-weight: 600">
                          {{ "Keterangan Kemanusiaan Lainnya" }}
                        </p>
                      </b-col>:
                      <b-col sm="6">
                        <ul class="ml-5" v-if="ket_kemanusiaan_lainnya === '{}' || ket_kemanusiaan_lainnya === null || ket_kemanusiaan_lainnya === ''">
                          -
                        </ul>
                        <ul class="ml-5" v-else>
                          {{ ket_kemanusiaan_lainnya }}
                        </ul>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col sm="3">
                        <p style="font-weight: 600">
                          {{ "Keterangan Keagamaan" }}
                        </p>
                      </b-col>:
                      <b-col sm="6">
                        <ul class="ml-5" v-if="ket_keagamaan === '{}' || ket_keagamaan === null || ket_keagamaan === ''">
                          -
                        </ul>
                        <ul class="ml-5" v-else>
                          {{ ket_keagamaan }}
                        </ul>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col sm="3">
                        <p style="font-weight: 600">
                          {{ "Keterangan Keagamaan Lainnya" }}
                        </p>
                      </b-col>:
                      <b-col sm="6">
                        <ul class="ml-5" v-if="ket_keagamaan_lainnya === '{}' || ket_keagamaan_lainnya === null || ket_keagamaan_lainnya === ''">
                          -
                        </ul>
                        <ul class="ml-5" v-else>
                          {{ ket_keagamaan_lainnya }}
                        </ul>
                      </b-col>
                      <b-row>
                        <b-col sm="3">
                          <p style="font-weight: 600">
                            {{ "Maksud Asas Tujuan" }}
                          </p>
                        </b-col>:<b-col sm="6"><div v-html="maksud.asas_tujuan"></div></b-col>
                      </b-row>
                    </b-row>
                  </b-col>
                </b-row>
              </b-tab>
              <b-tab title="Info Pendirian" style="background-color:white;margin-top:0px;">
                <b-row>
                  <b-col cols="7">
                    <b-row>
                      <b-col sm="4">
                        <p style="font-weight: 600">Nama Notaris</p>
                      </b-col>:<b-col sm="6">{{ nama_notaris }} </b-col>
                    </b-row>
                    <b-row>
                      <b-col sm="4">
                        <p style="font-weight: 600">Kedudukan Notaris</p>
                      </b-col>:<b-col sm="6">{{ kedudukan_notaris }} </b-col>
                    </b-row>
                    <b-row class="d-flex align-items-center">
                      <b-col sm="4">
                        <p style="font-weight: 600; margin:0px;">Notaris Pengganti</p>
                      </b-col>:<b-col sm="6">{{ notaris_pengganti }}
                      </b-col>
                    </b-row>
                    <b-row class="d-flex align-items-center">
                      <b-col sm="4">
                        <p style="font-weight: 600; margin:0px;">Nomor AKTA Pendirian</p>
                      </b-col>:<b-col sm="6">{{ nomor_akta_terakhir }}
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col sm="4">
                        <p style="font-weight: 600">Tanggal AKTA Pendirian</p>
                      </b-col>:<b-col sm="6">{{ tanggal_akta_terakhir | moment("DD - MM - YYYY") }} </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-tab>
              <b-tab title="Struktur Ormas" style="background-color:white;margin-top:0px;">
                <div class="tab-content">
                  <!--begin::Table-->
                  <div class="table-responsive">
                    <table class="
                table
                table-head-custom
                table-vertical-center
                table-head-bg
                table-bordered
              ">
                      <thead>
                        <tr class="text-left">
                          <th style="width: 170px; padding-left:0px; padding-right: 0px;" class="text-capitalize">Nama
                          </th>
                          <th style="width: 90px; padding-left:0px; padding-right: 0px;" class="text-capitalize">Jabatan
                          </th>
                          <th v-if="userType == 'admin'" style="width: 100px; padding-left:0px; padding-right: 0px;"
                            class="text-capitalize">No. Identitas</th>
                          <th style="width: 100px; padding-left:0px; padding-right: 0px;" class="text-capitalize">
                            Tanggal Lahir</th>
                          <th v-if="userType == 'admin'" style="width: 100px; padding-left:0px; padding-right: 0px;"
                            class="text-capitalize">No. HP</th>
                          <th v-if="userType == 'admin'" style="width: 100px; padding-left:0px; padding-right: 0px;"
                            class="text-capitalize">Aksi</th>
                        </tr>
                      </thead>
                      <tbody style="background:#FAFAFA;">
                        <template v-for="(item, i) in pengurus">
                          <tr v-bind:key="i">
                            <td>
                              <span class=" text-dark-75 font-weight-bolder d-block text-left font-size-lg
                        ">{{ item.data ? item.data.nama_pengurus : '' }}</span>
                            </td>
                            
                            <td>
                              <span class="
                          text-dark-75
                          text-left
                          font-weight-bolder
                          d-block
                          font-size-lg
                        ">{{ item.data ? item.data.jabatan_pengurus : '-' }}</span>
                            </td>
                            <td v-if="userType == 'admin'">
                              <span class="
                          text-left
                          text-dark-75
                          font-weight-bolder
                          d-block
                          font-size-lg
                        ">{{ item.data ? item.data.no_ktp_passport_pengurus : '' }}</span>
                            </td>
                            <td>
                              <span class="
                          text-dark-75
                          text-left
                          font-weight-bolder
                          d-block
                          font-size-lg
                        ">{{item.data ? item.data.tanggal_lahir : '' | moment("DD-MM-YYYY")}}</span>
                            </td>
                            <td v-if="userType == 'admin'">
                              <span class="
                          text-dark-75
                          font-weight-bolder
                          text-left
                          d-block
                          font-size-lg
                        ">{{ item.data ? item.data.notelp_pengurus : '' }}</span>
                            </td>
                            <td>
                              <b-button @click="newTab(item.data ? item.data.no_ktp_passport_pengurus : '')"
                                size="sm" variant="success">
                                <v-icon style="color:white;" small>
                                  mdi-eye
                                </v-icon>
                                Detail {{ item.data ? item.data.jabatan_pengurus : '' }}
                              </b-button>
                            </td>
                          </tr>
                        </template>
                      </tbody>
                    </table>
                  </div>

                  <!--end::Table-->
                  
                </div>
              </b-tab>
            </b-tabs>
          </b-row>
        </b-container>
      </div>
    </b-modal>

    <!-- modal detail Lokus-->
    <b-modal id="modal-detailLokus-ormas" ok-only centered size="xl" content-class="shadow" hide-header hide-footer>
      <div>
        <b-container fluid class="pl-1 pr-1">
          <b-row class="pt-3 pb-3 align-items-center">
            <b-col class="d-flex justify-content-between pr-0">
              <b style="font-size:20px;">
                Lokus Kegiatan Ormas WNA
              </b>
              <div class="align-items-center">
                <b-button variant="outline-secondary" style="background: #FFFFFF;" size="sm" class="mr-4"
                  @click="closeDetailLokus()">
                  <v-icon class="pr-3" style="padding-right: unset !important;">mdi-close</v-icon>
                </b-button>
              </div>
            </b-col>
          </b-row>
          <b-row class="pt-3 pb-3 align-items-center">
            <b-col sm="3">
              <p style="font-weight: 600; margin:0px;">{{ "Provinsi" }}</p>
            </b-col>
            :
            <b-col sm="6">
              <span class="text-dark-75 font-weight-bolder text-left font-size-lg">{{selectedAsingProvinsi}}</span>
            </b-col>
          </b-row>
          <b-row class="pt-3 pb-3 align-items-center">
            <b-col sm="3">
              <p style="font-weight: 600; margin:0px;">{{ "Kabupaten/Kota" }}</p>
            </b-col>
            :
            <b-col sm="6">
              <span class="text-dark-75 font-weight-bolder text-left font-size-lg">{{selectedAsingKabKot}}</span>
            </b-col>
          </b-row>
          <b-row class="pt-3 pb-3 align-items-center">
            <b-col sm="3">
              <p style="font-weight: 600; margin:0px;">{{ "Kecamatan" }}</p>
            </b-col>
            :
            <b-col sm="6">
              <span class="text-dark-75 font-weight-bolder text-left font-size-lg">{{selectedAsingKec}}</span>
            </b-col>
          </b-row>
          <b-row class="pt-3 pb-3 align-items-center">
            <b-col sm="3">
              <p style="font-weight: 600; margin:0px;">{{ "Kelurahan/Desa" }}</p>
            </b-col>
            :
            <b-col sm="6">
              <span class="text-dark-75 font-weight-bolder text-left font-size-lg">{{selectedAsingKel}}</span>
            </b-col>
          </b-row>
          <b-row class="pt-3 pb-3 align-items-center">
            <b-col sm="3">
              <p style="font-weight: 600; margin:0px;">{{ "Jalan" }}</p>
            </b-col>
            :
            <b-col sm="6">
              <span class="text-dark-75 font-weight-bolder text-left font-size-lg">{{selectedAsingJln}}</span>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </b-modal>

    <!-- modal detail Pelaporan-->
    <b-modal id="modal-detailPelaporan-ormas" ok-only centered size="xl" content-class="shadow" hide-header hide-footer>
      <div>
        <b-container fluid class="pl-1 pr-1">
          <b-row class="pt-3 pb-3 align-items-center">
            <b-col class="d-flex justify-content-between pr-0">
              <b style="font-size:20px;">
                Pelaporan Ormas WNA
              </b>
              <div class="align-items-center">
                <b-button 
                  variant="outline-secondary" 
                  style="background: #FFFFFF;" 
                  size="sm" 
                  class="mr-4"
                  @click="resetDetailpelaporan()">
                  <v-icon class="pr-3" style="padding-right: unset !important;">mdi-close</v-icon>
                </b-button>
              </div>
            </b-col>
          </b-row>
          <b-row class="pt-3 pb-3 align-items-center">
            <b-col sm="3">
              <p style="font-weight: 600; margin:0px;">{{ "Tanggal Laporan" }}</p>
            </b-col>
            :
            <b-col sm="6">
              <span class="text-dark-75 font-weight-bolder d-block font-size-lg">{{ tanggalPel | moment('DD - MM - YYYY') }}</span>
            </b-col>
          </b-row>
          <b-row class="pt-3 pb-3 align-items-center">
            <b-col sm="3">
              <p style="font-weight: 600; margin:0px;">{{ "Nama Pelapor" }}</p>
            </b-col>
            :
            <b-col sm="6">
              <span class="text-dark-75 font-weight-bolder text-left font-size-lg">{{ namaPelaporPel }}</span>
            </b-col>
          </b-row>
          <b-row class="pt-3 pb-3 align-items-center">
            <b-col sm="3">
              <p style="font-weight: 600; margin:0px;">{{ "Jabatan Pelapor" }}</p>
            </b-col>
            :
            <b-col sm="6">
              <span class="text-dark-75 font-weight-bolder text-left font-size-lg">{{ jabatanPel }}</span>
            </b-col>
          </b-row>
          <b-row class="pt-3 pb-3 align-items-center">
            <b-col sm="3">
              <p style="font-weight: 600; margin:0px;">{{ "Instansi" }}</p>
            </b-col>
            :
            <b-col sm="6">
              <span class="text-dark-75 font-weight-bolder text-left font-size-lg">{{ instansiPel }}</span>
            </b-col>
          </b-row>
          <b-row class="pt-3 pb-3 align-items-center">
            <b-col sm="3">
              <p style="font-weight: 600; margin:0px;">{{ "Perihal Laporan" }}</p>
            </b-col>
            :
            <b-col sm="6">
              <span class="text-dark-75 font-weight-bolder text-left font-size-lg">{{ perihalPel }}</span>
            </b-col>
          </b-row>
          <b-row class="pt-3 pb-3 align-items-center">
            <b-col sm="3">
              <p style="font-weight: 600; margin:0px;">{{ "Isi Laporan" }}</p>
            </b-col>
            :
            <b-col sm="6">
              <span class="text-dark-75 font-weight-bolder text-left font-size-lg">{{ isiLaporanPel }}</span>
            </b-col>
          </b-row>
          <b-row class="pt-3 pb-3 d-flex align-items-center">
            <b-col sm="3">
              <p style="font-weight: 600; margin:0px;">{{ "Lampiran" }}</p>
            </b-col>
            :
            <b-col sm="2">
              <a v-if="fileLaporanPel && fileLaporanPel != ''" :href="fileURL + fileLaporanPel" target="_blank" type="button">
                <b-button variant="success" size="sm">
                  Lihat File
                </b-button>
              </a>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </b-modal>

    <!-- END MODAL SECTION -->

    <div>
      <b-tabs pills card justified v-model="tab" class="shadow" style="border-radius:10px;">

        <b-tab title="Terdaftar" style="background-color:white;margin-top:0px;">
          <b-row v-if="!loading1">
            <b-col cols="11">
              <div class="d-flex justify-content-between align-items-center p-1 pr-3"
                style="border-radius:8px; border:solid 1px;">
                <input type="text" class="form-control border-0" v-model="search" :placeholder="'Cari...'"
                  v-on:keyup.enter="searching()" />
                <b-button size="sm" style="width:80px;margin-right:10px;" variant="primary" @click="searching()">
                  <v-icon style="color:white;" small>mdi-magnify</v-icon>
                  Cari
                </b-button>
              </div>
            </b-col>
            <b-col cols="1" class="d-flex align-items-center p-0">
              <b-button size="sm" variant="primary" @click="showFilter()">
                <v-icon style="color:white;" small>mdi-filter</v-icon>
                Filter
              </b-button>
            </b-col>
          </b-row>
          <b-row class="pt-5" v-if="filterStatus">
            <b-button @click="delFilter('prov')" class="m-2" size="sm"
              v-if="selectedProvinsi != '' && selectedProvinsi != null" variant="light">Provinsi:
              {{convProv(selectedProvinsi)}}
              <v-icon>
                mdi-close
              </v-icon>
            </b-button>
            <b-button @click="delFilter('kab')" class="m-2" size="sm"
              v-if="filter.kabupaten_kota != '' && filter.kabupaten_kota != null" variant="light">Kabupaten:
              {{convKab(filter.kabupaten_kota)}}
              <v-icon>
                mdi-close
              </v-icon>
            </b-button>
            <b-button @click="delFilter('bk')" class="m-2" size="sm" v-if="filter.bidang_kegiatan[0].id.length > 0"
              variant="light">Bidang Kegiatan:
              <span v-for="(item,i) in filter.bidang_kegiatan" :key="i">
                {{item.id[1]}}, &nbsp;
              </span>
              <v-icon>
                mdi-close
              </v-icon>
            </b-button>
            <b-button @click="delFilter('ss')" class="m-2" size="sm" v-if="filter.status_sekretariat != ''"
              variant="light">Status Sekretariat: {{filter.status_sekretariat[1]}}
              <v-icon>
                mdi-close
              </v-icon>
            </b-button>
            <b-button @click="delFilter('ck')" class="m-2" size="sm" v-if="filter.ciri_khusus != ''" variant="light">
              Ciri Khusus: {{filter.ciri_khusus[1]}}
              <v-icon>
                mdi-close
              </v-icon>
            </b-button>
            <b-button @click="delFilter('sa')" class="m-2" size="sm" v-if="filter.sktAktif != ''" variant="light">Status
              SKT: {{filter.sktAktif === 'true' ? 'Aktif' : 'Tidak Aktif'}}
              <v-icon>
                mdi-close
              </v-icon>
            </b-button>
            <b-button @click="delFilter('sk')" class="m-2" size="sm" v-if="filter.status_kegiatan != ''"
              variant="light">Status Kegiatan: {{filter.status_kegiatan === 'true' ? 'Aktif' : 'Tidak Aktif'}}
              <v-icon>
                mdi-close
              </v-icon>
            </b-button>
            <b-button @click="delFilter('jo')" class="m-2" size="sm" v-if="filter.jenjang_ormas != ''" variant="light">
              Jenjang Ormas: {{filter.jenjang_ormas === 'true' ? 'Berjenjang' : 'Tidak Berjenjang'}}
              <v-icon>
                mdi-close
              </v-icon>
            </b-button>
            <b-button @click="delFilter('ds')" class="m-2" size="sm" v-if="filter.dalam_sengketa != ''" variant="light">
              Ormas Dalam Sengketa: {{filter.dalam_sengketa === 'true' ? 'Sedang Berlangsung' : 'Sudah Berakhir'}}
              <v-icon>
                mdi-close
              </v-icon>
            </b-button>
            <b-button @click="delFilter('bn')" class="m-2" size="sm" v-if="filter.batas_negara != ''" variant="light">
              Daerah Batas Negara: {{filter.batas_negara}}
              <v-icon>
                mdi-close
              </v-icon>
            </b-button>
            <b-button @click="delFilter('tahun_ter_skt')" class="m-2" size="sm"
              v-if="selectedTahunSkt != '' && selectedTahunSkt != null" variant="light">Tahun Terbit SKT:
              {{selectedTahunSkt}}
              <v-icon>
                mdi-close
              </v-icon>
            </b-button>

          </b-row>

          <b-row class="mt-5">
            <b-col class="d-flex align-items-center justify-content-between">
              <span style="font-size:16px; font-weight:bold;">Surat Keterangan Terdaftar</span>
              <div>
                <button :disabled="loadExExcel" @click="cetakEXCEL()"
                  class="btn font-weight-bolder font-size-sm mr-3 bg-hitam" style="color:white;"
                  data-wizard-type="action-submit">
                  <i class="fas fa-file-excel" style="color:white;"></i>Ekspor ke Excel
                </button>
                <button @click="cetakPDF()" :disabled="loadExPdf" style="color:white;"
                  class="btn font-weight-bolder font-size-sm bg-hitam" data-wizard-type="action-submit">
                  <i class="fas fa-file-pdf" style="color:white;"></i>Cetak PDF
                </button>
              </div>
            </b-col>
          </b-row>

          <div class="table-responsive mt-4" style="border-radius:15px;">
            <table class="
            table
            table-head-custom
            table-vertical-center
            table-head-bg
            table-bordered
          " style="font-size:14px !important;">
              <thead>
                <tr class="text-left">
                  <th style="width: 20px" class="text-capitalize">No.</th>
                  <th style="min-width: 50px" class="text-capitalize">Nama Ormas</th>
                  <th style="min-width: 50px" class="text-capitalize">Status Sekretariat</th>
                  <th style="min-width: 50px" class="text-capitalize">Status Kegiatan</th>
                  <th style="min-width: 50px" class="text-capitalize">Status Pelaporan</th>
                  <th style="min-width: 50px" class="text-capitalize">Provinsi</th>
                  <th style="min-width: 50px" class="text-capitalize">Aksi</th>
                </tr>
              </thead>
              <tbody v-if="loadingTerdaftar">
                <template>
                  <tr>
                    <td colspan="6"> -- Sedang memuat -- </td>
                  </tr>
                </template>
              </tbody>
              <tbody v-if="!loadingTerdaftar && paginateDataTerdaftar.length === 0">
                <template>
                  <tr>
                    <td colspan="6"> -- Tidak ada data -- </td>
                  </tr>
                </template>
              </tbody>
              <tbody v-else style="background: #FAFAFA;">
                <template v-for="(item, i) in paginateDataTerdaftar">
                  <tr v-bind:key="i">
                    <td>
                      <span v-if="item.sktAktif == true" class="
                      text-dark-75
                      font-weight-bolder
                      d-block
                      font-size-lg
                    ">{{ (i+1) + ((currentPage-1)*perPage)}}</span>
                    <span v-else style="color: red !important;" class="
                      text-dark-75
                      font-weight-bolder
                      d-block
                      font-size-lg
                    ">{{ (i+1) + ((currentPage-1)*perPage)}}</span>
                    </td>
                    <td class="text-left">
                      <span v-if="item.sktAktif == true" style="white-space: pre-wrap" class="
                      text-dark-75
                      font-weight-bolder
                      text-left
                      font-size-lg
                    "> {{ item.namaOrmas}}
                      </span>
                      <span v-else style="color: red !important;white-space: pre-wrap" class="
                      text-dark-75
                      font-weight-bolder
                      text-left
                      font-size-lg
                    "> {{ item.namaOrmas}}
                      </span>
                    </td>
                    <td class="text-left">
                      <span v-if="item.sktAktif == true" class="
                      text-dark-75
                      font-weight-bolder
                      d-block
                      text-left
                      font-size-lg
                    ">{{ item.namaLokasi }}</span>
                    <span v-else style="color: red !important;" class="
                      text-dark-75
                      font-weight-bolder
                      d-block
                      text-left
                      font-size-lg
                    ">{{ item.namaLokasi }}</span>
                    </td>
                    <td class="text-left">
                      <span v-if="item.sktAktif == true" class="
                      text-dark-75
                      text-left
                      font-weight-bolder
                      d-block
                      font-size-lg
                    ">{{item.statusKegiatan ? 'Aktif' : 'Tidak Aktif'}}</span>
                    <span v-else style="color: red !important;" class="
                      text-dark-75
                      text-left
                      font-weight-bolder
                      d-block
                      font-size-lg
                    ">{{item.statusKegiatan ? 'Aktif' : 'Tidak Aktif'}}</span>
                    </td>
                    <td class="text-left">
                      <span v-if="item.namaLokasi == 'Kantor Pusat' && item.sktAktif == true" class="
                      text-dark-75
                      font-weight-bolder
                      d-block
                      text-left
                      font-size-lg
                    ">Sudah Lapor</span>
                    <span v-if="item.namaLokasi == 'Kantor Pusat' && item.sktAktif != true" style="color: red !important;" class="
                      text-dark-75
                      font-weight-bolder
                      d-block
                      text-left
                      font-size-lg
                    ">Sudah Lapor</span>
                      <span v-if="item.namaLokasi != 'Kantor Pusat' && item.sktAktif == true" class="
                      text-dark-75
                      font-weight-bolder
                      d-block
                      text-left
                      font-size-lg
                    ">{{item.statusPelaporan ? 'Belum Lapor' : 'Sudah Lapor'}}</span>
                    <span v-if="item.namaLokasi != 'Kantor Pusat' && item.sktAktif != true" style="color: red !important;" class="
                      text-dark-75
                      font-weight-bolder
                      d-block
                      text-left
                      font-size-lg
                    ">{{item.statusPelaporan ? 'Belum Lapor' : 'Sudah Lapor'}}</span>
                    </td>
                    <td class="text-left">
                      <span v-if="item.sktAktif == true" class="
                      text-dark-75
                      text-left
                      font-weight-bolder
                      d-block
                      font-size-lg
                    ">{{item.wilayahProvinsi.nama}}</span>
                    <span v-else style="color: red !important;" class="
                      text-dark-75
                      text-left
                      font-weight-bolder
                      d-block
                      font-size-lg
                    ">{{item.wilayahProvinsi.nama}}</span>
                    </td>
                    <td>
                      <section>
                        <b-button variant="success" size="sm" @click="getDetailOrganisasi(item.id)" style="width:80px;">
                          {{ '>> Detail'}}
                        </b-button>
                      </section>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
          <b-row>
            <b-col class="d-flex justify-content-end">
              <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" aria-controls="my-table">
              </b-pagination>
            </b-col>
          </b-row>
        </b-tab>
        <b-tab title="Tidak Terdaftar" style="background-color:white;margin-top:0px;">
          <b-row v-if="!loading1">
            <b-col cols="11">
              <div class="d-flex justify-content-between align-items-center p-1 pr-3"
                style="border-radius:8px; border:solid 1px;">
                <input type="text" class="form-control border-0" v-model="search" :placeholder="'Cari...'"
                  v-on:keyup.enter="searching()" />
                <b-button size="sm" style="width:80px;margin-right:10px;" variant="primary" @click="searching()">
                  <v-icon style="color:white;" small>mdi-magnify</v-icon>
                  Cari
                </b-button>
              </div>
            </b-col>
            <b-col cols="1" class="d-flex align-items-center p-0">
              <b-button size="sm" variant="primary" @click="showFilter()">
                <v-icon style="color:white;" small>mdi-filter</v-icon>
                Filter
              </b-button>
            </b-col>
          </b-row>
          <b-row class="pt-5" v-if="filterStatus">
            <b-button @click="delFilter('bk')" class="m-2" size="sm" v-if="filter.bidang_kegiatan[0].id.length > 0"
              variant="light">Bidang Kegiatan:
              <span v-for="(item,i) in filter.bidang_kegiatan" :key="i">
                {{item.id[1]}}, &nbsp;
              </span>
              <v-icon>
                mdi-close
              </v-icon>
            </b-button>
            <b-button @click="delFilter('ss')" class="m-2" size="sm" v-if="filter.status_sekretariat != ''"
              variant="light">Status Sekretariat: {{filter.status_sekretariat[1]}}
              <v-icon>
                mdi-close
              </v-icon>
            </b-button>
            <b-button @click="delFilter('ck')" class="m-2" size="sm" v-if="filter.ciri_khusus != ''" variant="light">
              Ciri Khusus: {{filter.ciri_khusus[1]}}
              <v-icon>
                mdi-close
              </v-icon>
            </b-button>
            <b-button @click="delFilter('sk')" class="m-2" size="sm" v-if="filter.status_kegiatan != ''"
              variant="light">Status Kegiatan: {{filter.status_kegiatan === 'true' ? 'Aktif' : 'Tidak Aktif'}}
              <v-icon>
                mdi-close
              </v-icon>
            </b-button>
            <b-button @click="delFilter('jo')" class="m-2" size="sm" v-if="filter.jenjang_ormas != ''" variant="light">
              Jenjang Ormas: {{filter.jenjang_ormas === 'true' ? 'Berjenjang' : 'Tidak Berjenjang'}}
              <v-icon>
                mdi-close
              </v-icon>
            </b-button>
            <b-button @click="delFilter('ds')" class="m-2" size="sm" v-if="filter.dalam_sengketa != ''" variant="light">
              Ormas Dalam Sengketa: {{filter.dalam_sengketa === 'true' ? 'Sedang Berlangsung' : 'Sudah Berakhir'}}
              <v-icon>
                mdi-close
              </v-icon>
            </b-button>
            <b-button @click="delFilter('bn')" class="m-2" size="sm" v-if="filter.batas_negara != ''" variant="light">
              Daerah Batas Negara: {{filter.batas_negara}}
              <v-icon>
                mdi-close
              </v-icon>
            </b-button>
            <b-button @click="delFilter('prov')" class="m-2" size="sm"
              v-if="selectedProvinsi != '' && selectedProvinsi != null" variant="light">Provinsi:
              {{convProv(selectedProvinsi)}}
              <v-icon>
                mdi-close
              </v-icon>
            </b-button>
            <b-button @click="delFilter('kab')" class="m-2" size="sm"
              v-if="filter.kabupaten_kota != '' && filter.kabupaten_kota != null" variant="light">Kabupaten:
              {{convKab(filter.kabupaten_kota)}}
              <v-icon>
                mdi-close
              </v-icon>
            </b-button>

          </b-row>
          <b-row class="mt-5">
            <b-col class="d-flex align-items-center justify-content-between">
              <span style="font-size:16px; font-weight:bold;">Surat Keterangan Tidak Terdaftar</span>
              <div>
                <button :disabled="loadTt" class="btn font-weight-bolder font-size-sm mr-3 bg-hitam"
                  style="color:white;" data-wizard-type="action-submit" @click="printOutTidakTerdaftar('excel')">
                  <i class="fas fa-file-excel" style="color:white;"></i>Ekspor ke Excel
                </button>
                <button :disabled="loadTt" class="btn font-weight-bolder font-size-sm mr-3 bg-hitam"
                  style="color:white;" data-wizard-type="action-submit" @click="printOutTidakTerdaftar('pdf')">
                  <i class="fas fa-file-pdf" style="color:white;"></i>Cetak PDF
                </button>
              </div>
            </b-col>
          </b-row>

          <div class="table-responsive mt-4" style="border-radius:15px;">
            <table class="
            table
            table-head-custom
            table-vertical-center
            table-head-bg
            table-bordered
          " style="font-size:14px !important;">
              <thead>
                <tr class="text-left">
                  <th style="width: 20px" class="text-capitalize">No.</th>
                  <th style="min-width: 50px" class="text-capitalize">Nama Ormas</th>
                  <th style="min-width: 50px" class="text-capitalize">Status Sekretariat</th>
                  <th style="min-width: 50px" class="text-capitalize">Status Kegiatan</th>
                  <th style="min-width: 50px" class="text-capitalize">Status Pelaporan</th>
                  <th style="min-width: 50px" class="text-capitalize">Provinsi</th>
                  <th style="min-width: 50px" class="text-capitalize">Aksi</th>
                </tr>
              </thead>
              <tbody v-if="loadingTidakTerdaftar">
                <template>
                  <tr>
                    <td colspan="6"> -- Sedang memuat -- </td>
                  </tr>
                </template>
              </tbody>
              <tbody v-if="!loadingTidakTerdaftar && listTidakTerdaftar.length === 0">
                <template>
                  <tr>
                    <td colspan="6"> -- Tidak ada data -- </td>
                  </tr>
                </template>
              </tbody>
              <tbody v-else style="background: #FAFAFA;">
                <template v-for="(itemtt, itt) in listTidakTerdaftar">
                  <tr v-bind:key="itt">
                    <td>
                      <span class="
                      text-dark-75
                      font-weight-bolder
                      d-block
                      font-size-lg
                    ">{{ (itt+1) + ((currentPagee-1)*perPage)}}</span>
                    </td>
                    <td class="text-left">
                      <span class="
                      text-dark-75
                      font-weight-bolder
                      text-left
                      font-size-lg
                    "> {{ itemtt.nama_ormas}}
                      </span>
                    </td>
                    <td class="text-left">
                      <span v-if="itemtt.masterLokasiSekretariat" class="
                      text-dark-75
                      font-weight-bolder
                      d-block
                      text-left
                      font-size-lg
                    ">{{ itemtt.masterLokasiSekretariat.nama_lokasi }}</span>
                    </td>
                    <td class="text-left">
                      <span class="
                      text-dark-75
                      text-left
                      font-weight-bolder
                      d-block
                      font-size-lg
                    ">Aktif</span>
                    </td>
                    <td class="text-left">
                      <span v-if="itemtt.nama_lokasi == 'Kantor Pusat'" class="
                      text-dark-75
                      font-weight-bolder
                      d-block
                      text-left
                      font-size-lg
                    ">Belum Lapor</span>
                      <span v-if="itemtt.nama_lokasi != 'Kantor Pusat'" class="
                      text-dark-75
                      font-weight-bolder
                      d-block
                      text-left
                      font-size-lg
                    ">Belum Lapor</span>
                    </td>
                    <td class="text-left">
                      <span class="
                      text-dark-75
                      text-left
                      font-weight-bolder
                      d-block
                      font-size-lg
                    ">{{itemtt.masterProvinsi.nama}}</span>
                    </td>
                    <td>
                      <section>
                        <b-button variant="success" size="sm" @click="toDetail(itemtt.id)"
                          style="width:80px;">
                          {{ '>> Detail'}}
                        </b-button>
                      </section>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
          <b-row>
            <b-col class="d-flex justify-content-end">
              <b-pagination v-model="currentPagee" :total-rows="totalRowss" :per-page="perPage"
                aria-controls="my-table"></b-pagination>
            </b-col>
          </b-row>
        </b-tab>
        <b-tab title="Ormas WNA" style="background-color:white;margin-top:0px;">
          <b-row v-if="!loading1">
            <b-col cols="11">
              <div class="d-flex justify-content-between align-items-center p-1 pr-3"
                style="border-radius:8px; border:solid 1px;">
                <input type="text" class="form-control border-0" v-model="search" :placeholder="'Cari Nama / MSP Ormas Asing...'"
                  v-on:keyup.enter="searchingAsing()" />
                <b-button size="sm" style="width:80px;margin-right:10px;" variant="primary" @click="searchingAsing()">
                  <v-icon style="color:white;" small>mdi-magnify</v-icon>
                  Cari
                </b-button>
              </div>
            </b-col>
          </b-row>
          <b-row class="mt-5">
            <b-col class="d-flex align-items-center justify-content-between">
              <span style="font-size:16px; font-weight:bold;">Ormas WNA</span>
              <div>
                <button :disabled="loadExExcel" @click="cetakEXCELAsing()"
                  class="btn font-weight-bolder font-size-sm mr-3 bg-hitam" style="color:white;"
                  data-wizard-type="action-submit">
                  <i class="fas fa-file-excel" style="color:white;"></i>Ekspor ke Excel
                </button>
                <button @click="cetakPDFAsing()" :disabled="loadExPdf" style="color:white;"
                  class="btn font-weight-bolder font-size-sm bg-hitam" data-wizard-type="action-submit">
                  <i class="fas fa-file-pdf" style="color:white;"></i>Cetak PDF
                </button>
              </div>
            </b-col>
          </b-row>

          <div class="table-responsive mt-4" style="border-radius:15px;">
            <table class="table table-head-custom table-vertical-center table-head-bg table-bordered"
              style="font-size:14px !important;">
              <thead>
                <tr class="text-left">
                  <th style="width: 20px" class="text-capitalize">No.</th>
                  <th style="min-width: 50px" class="text-capitalize">Nama Ormas Asing</th>
                  <th style="min-width: 50px" class="text-capitalize">Negara</th>
                  <th style="min-width: 50px" class="text-capitalize">Mitra K/L</th>
                  <th style="min-width: 50px" class="text-capitalize">Nomor MSP</th>
                  <th style="width: 100px" class="text-capitalize">Aksi</th>
                </tr>
              </thead>
              <tbody v-if="listOrmasAsing.length == 0">
                <template v-if="!loadingTable">
                  <tr>
                    <td colspan="6"> -- Tidak ada data -- </td>
                  </tr>
                </template>
              </tbody>
              <tbody style="background: #FFFFFF;">
                <template v-for="(item, i) in listOrmasAsing">
                  <tr v-bind:key="i">
                    <td>
                      <span class="text-dark-75 font-weight-bolder d-block font-size-lg">
                        {{(i + 1) + ((currentPageAsing - 1)*perPage)}}
                      </span>
                    </td>
                    <td class="text-left">
                      <span class="text-dark-75 font-weight-bolder text-left font-size-lg">{{ item.namaOrmas }}</span>
                    </td>
                    <td class="text-left">
                      <span class="text-dark-75 font-weight-bolder text-left font-size-lg">{{ item.masterNegara.negara }}</span>
                    </td>
                    <td class="text-left">
                      <span class="text-dark-75 font-weight-bolder text-left font-size-lg">{{ item.asingKemitraan ? item.asingKemitraan.mitraKl : '-' }}</span>
                    </td>
                    <td class="text-left">
                      <span class="text-dark-75 font-weight-bolder text-left font-size-lg">{{ item.nomorMsp }}</span>
                    </td>
                    <td class="text-left">
                      <b-button variant="success" size="sm" @click="todetailAsing(item.id)">
                        >> Detail
                      </b-button>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
          <b-row>
            <b-col class="d-flex justify-content-end">
              <b-pagination v-model="currentPageAsing" :total-rows="totalRowAsing" :per-page="perPage" aria-controls="my-table">
              </b-pagination>
            </b-col>
          </b-row>
        </b-tab>
        <b-tab title="Ormas Badan Hukum" style="background-color:white;margin-top:0px;">
          <b-row v-if="!loading1">
            <b-col cols="11">
              <div class="d-flex justify-content-between align-items-center p-1 pr-3"
                style="border-radius:8px; border:solid 1px;">
                <input type="text" class="form-control border-0" v-model="search" :placeholder="'Cari...'"
                  v-on:keyup.enter="searchingBadanHukum()"/>
                <b-button size="sm" style="width:80px;margin-right:10px;" variant="primary" @click="searchingBadanHukum()">
                  <v-icon style="color:white;" small>mdi-magnify</v-icon>
                  Cari
                </b-button>
              </div>
            </b-col>
            <b-col cols="1" class="d-flex align-items-center p-0">
              <b-button size="sm" variant="primary" @click="showFilterBadanHukum()">
                <v-icon style="color:white;" small>mdi-filter</v-icon>
                Filter
              </b-button>
            </b-col>
          </b-row>
          <b-row class="pt-5" v-if="filterBadanHukumStatus">
            <b-button @click="delFilterBadanHukum('jenis')" class="m-2" size="sm" v-if="filterbadanhukum.jenisbadanhukum != 0" variant="light">
              Jenis Badan Hukum: {{filterbadanhukum.jenisbadanhukum === 1 ? 'Perkumpulan' : 'Yayasan'}}
              <v-icon>
                mdi-close
              </v-icon>
            </b-button>

          </b-row>

          <b-row class="mt-5">
            <b-col class="d-flex align-items-center justify-content-between">
              <span style="font-size:16px; font-weight:bold;">Ormas Badan Hukum</span>
              <!-- <div>
                <button :disabled="loadExExcel" @click="cetakEXCEL()"
                  class="btn font-weight-bolder font-size-sm mr-3 bg-hitam" style="color:white;"
                  data-wizard-type="action-submit">
                  <i class="fas fa-file-excel" style="color:white;"></i>Ekspor ke Excel
                </button>
                <button @click="cetakPDF()" :disabled="loadExPdf" style="color:white;"
                  class="btn font-weight-bolder font-size-sm bg-hitam" data-wizard-type="action-submit">
                  <i class="fas fa-file-pdf" style="color:white;"></i>Cetak PDF
                </button>
              </div> -->
            </b-col>
          </b-row>

          <div class="table-responsive mt-4" style="border-radius:15px;">
            <table class="
            table
            table-head-custom
            table-vertical-center
            table-head-bg
            table-bordered
          " style="font-size:14px !important;">
              <thead>
                <tr class="text-left">
                  <th style="width: 20px" class="text-capitalize">No.</th>
                  <th style="min-width: 50px" class="text-capitalize">Nama Ormas</th>
                  <th style="min-width: 50px" class="text-capitalize">Jenis Badan Hukum</th>
                  <th style="min-width: 50px" class="text-capitalize">Provinsi</th>
                  <!-- <th style="min-width: 50px" class="text-capitalize">Kabupaten/Kota</th> -->
                  <th style="min-width: 50px" class="text-capitalize">Aksi</th>
                </tr>
              </thead>
              <tbody v-if="loadingBadanHukum">
                <template>
                  <tr>
                    <td colspan="6"> -- Sedang memuat -- </td>
                  </tr>
                </template>
              </tbody>
              <tbody v-if="!loadingBadanHukum && listBadanHukum.length === 0">
                <template>
                  <tr>
                    <td colspan="6"> -- Tidak ada data -- </td>
                  </tr>
                </template>
              </tbody>
              <tbody v-else style="background: #FAFAFA;">
                <template v-for="(itembh, indexbh) in listBadanHukum">
                  <tr v-bind:key="indexbh">
                    <td>
                      <span class="
                      text-dark-75
                      font-weight-bolder
                      d-block
                      font-size-lg
                      ">
                        {{ (indexbh+1) + ((currentPageBadanHukum-1)*perPageBadanHukum)}}
                      </span>
                    </td>
                    <td class="text-left">
                      <span style="white-space: pre-wrap" class="
                      text-dark-75
                      font-weight-bolder
                      text-left
                      font-size-lg
                      ">
                       {{ itembh.nama}}
                      </span>
                    </td>
                    <td class="text-left">
                      <span v-if="itembh.jenis_ormas == 1" class="
                      text-dark-75
                      font-weight-bolder
                      d-block
                      text-left
                      font-size-lg
                    ">{{ "Perkumpulan" }}</span>
                    <span v-if="itembh.jenis_ormas == 2" class="
                      text-dark-75
                      font-weight-bolder
                      d-block
                      text-left
                      font-size-lg
                    ">{{ "Yayasan" }}</span>
                    </td>
                    <td class="text-left">
                      <span class="
                      text-dark-75
                      text-left
                      font-weight-bolder
                      d-block
                      font-size-lg
                    ">{{itembh.nama_kedudukan_provinsi}}</span>
                    <!-- <td class="text-left">
                      <span class="
                      text-dark-75
                      text-left
                      font-weight-bolder
                      d-block
                      font-size-lg
                    ">{{itembh.nama_kedudukan_kabupaten}}</span>
                    </td> -->
                    <td>
                      <section>
                        <b-button v-if="userType === 'admin'" variant="success" size="sm" @click="getDetailBadanHukum(itembh.nama, itembh.nomor_sk)" style="width:80px;">
                          {{ '>> Detail'}}
                        </b-button>
                      </section>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
          <b-row>
            <b-col class="d-flex justify-content-end">
              <b-pagination v-model="currentPageBadanHukum" :total-rows="totalRowsBadanHukum" :per-page="perPageBadanHukum" aria-controls="my-table">
              </b-pagination>
            </b-col>
          </b-row>
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>
<script>
import Swal from "sweetalert2";
import axios from "axios";
export default {
  name: "Informasi",
  data() {
    return {
      baseURL_bakkum: process.env.VUE_APP_APPLICATION_BAKUM_URL,
      tanggal_skt_end: '',
      loadingTerdaftar: false,
      loadingBadanHukum: false,
      loadingTable: false,
      listOrmasAsing: [],
      loadingTidakTerdaftar: false,
      loadExExcel: false,
      loadExPdf: false,
      currentPage: 1,
      currentPageBadanHukum: 1,
      currentPagee: 1,
      currentPageAsing: 1,
      loadTt: false,
      currentOffset: 0,
      currentOffsetTT: 0,
      currentOffsetOA: 0,
      totalRows: 0,
      totalRowss: 0,
      totalRowAsing: 0,
      totalRowsBadanHukum: 0,
      offsetData: 0,
      userType: '',
      isOrmas: false,
      isOrmasUnreg: false,
      perPage: 20,
      perPageBadanHukum: 20,
      paginateDataTerdaftar: [],
      tab: 0,
      tabDetail: 0,
      tabDetailTT: 0,
      activeTab: 0,
      loading1: false,
      tabDetailRiwayat: 0,
      tabDetailCabang: 0,
      search: '',
      listProvinsi: [],
      listTahunSkt: [],
      selectedProvinsi: null,
      selectedKota: null,
      selectedTahunSkt: null,
      listCabang: [{
        status_cabang: '',
        wilayahProvinsi: { nama :''},
        wilayahKabupatenKota: { nama: ''},
        alamat_sekretariat: '',
        masterLokasiSekretariat: { nama_lokasi: ''}
      }],
      detailCabang: null,
      filter: {
        bidang_kegiatan: [{id: []}],
        status_sekretariat: '',
        ciri_khusus: '',
        jenjang_ormas: '',
        status_kegiatan: '',
        dalam_sengketa: '',
        batas_negara: '',
        kabupaten_kota: '',
        sktAktif: '',
        tahunTerbitSkt: '',
      },
      filterbadanhukum: {
        kabupaten_kota: '',
        jenisbadanhukum: 0,
      },
      info_organization: {
        "id": null,
        "nama_ormas": "",
        "nama_singkat": "",
        "npwp": "",
        "m_kategori_ormas_id": null,
        "alamat_sekretariat": "",
        "no_skt": "",
        "skt_aktif": "",
        "tanggal_skt": "",
        "tanggal_berdiri": "",
        "email": "",
        "no_telp": "",
        "no_fax": "",
        "website": "",
        "status_ormas": null,
        "status_badan_hukum": null,
        "status_legalitas": null,
        "sys_user_id": null,
        "m_lokasi_sekretariat_id": null,
        "provinsi_sekretariat": "",
        "kabupaten_kota_sekretariat": "",
        "m_ciri_khusus_id": null,
        "berhimpunOtonom": {
          "jenis": 1,
          "afiliasiOrmas": { nama_ormas: ''}
        },
        "bidangKegiatans": [
          {
            "id": null,
            "data_ormas_id": null,
            "keterangan": "",
            "m_jenis_ormas_id": null,
            "masterJenisOrmas": {
              "id": null,
              "nama_jenis_ormas": "",
              "terhapus": false
            }
          }
        ],
        "tujuanOrmas": [
          {
            "id": null,
            "data_ormas_id": null,
            "keterangan": ""
          }
        ],
        "masterProvinsi": {
          "id": null,
          "kode": "",
          "nama": "",
          "tipe": null,
          "latlong": null,
          "terhapus": false
        },
        "masterKabupatenKota": {
          "id": null,
          "kode": "",
          "nama": "",
          "tipe": null,
          "latlong": null,
          "terhapus": false
        },
        "masterCiriKhusus": {
          "id": null,
          "nama_ciri_khusus": "",
          "terhapus": 0
        }
      },
      founder_organization: {
        "id": null,
        "dataOrmasId": null,
        "cabangOrmasId": null,
        "nomorAkta": "",
        "tanggalAkta": "",
        "tempatPendirian": "",
        "tanggalPendirian": "",
        "nomorSuratPendaftaran": "",
        "tanggalSuratPendaftaran": "",
        "m_kesbangpol_id": null,
        wilayahProvinsi: {
          masterKesbangpolProv: {
            nama: '',
            email: '',
            noTelepon: ''
          }
        },
        wilayahKabupatenKota: {
          masterKesbangpolKab: {
            nama: '',
            email: '',
            noTelpon: ''
          }
        }
      },
      structure_organization: {
        "pengurusInti": [{
          "id": null,
          "data_ormas_id": null,
          "cabang_ormas_id": null,
          "nama_pengurus": "",
          "m_jenis_identitas_id": null,
          "identitas": "",
          "tempat_lahir": "",
          "tanggal_lahir": "",
          "alamat": "",
          "provinsi": "",
          "kabupaten_kota": "",
          "no_telp": "",
          "no_hp": "",
          "periode_mulai": "",
          "periode_selesai": "",
          "status_pengurus": null,
          "keterangan": "",
          "foto": "",
          "m_kategori_pengurus_id": {},
          "m_jabatan_id": {},
          "masterKategoriPengurus": {
            "id": null,
            "nama_kategori_pengurus": "",
            "terhapus": null,
            "kode_kategori_pengurus": ""
          },
          "masterJabatan": {
            "id": null,
            "nama_jabatan": "",
            "terhapus": null,
            "kode_jabatan": ""
          }
        }],
        "listPengurus": [{
          "id": null,
          "data_ormas_id": null,
          "cabang_ormas_id": null,
          "nama_pengurus": "",
          "m_jenis_identitas_id": null,
          "identitas": "",
          "tempat_lahir": "",
          "tanggal_lahir": "",
          "alamat": "",
          "provinsi": "",
          "kabupaten_kota": "",
          "no_telp": "",
          "no_hp": "",
          "periode_mulai": "",
          "periode_selesai": "",
          "status_pengurus": null,
          "keterangan": "",
          "foto": "",
          "m_kategori_pengurus_id": {},
          "m_jabatan_id": {},
          "masterKategoriPengurus": {
            "id": null,
            "nama_kategori_pengurus": "",
            "terhapus": null,
            "kode_kategori_pengurus": ""
          },
          "masterJabatan": {
            "id": null,
            "nama_jabatan": "",
            "terhapus": null,
            "kode_jabatan": ""
          }
        }]
      },
      document_organization: [
          {
          "id": null,
          "nama_jenis_dokumen": "",
          "terhapus": null,
          "kode": ""
          }
        ],
      bank_account_organization: [
        {
        "id": null,
        "data_ormas_id": null,
        "cabang_id": null,
        "no_rekening": "",
        "nama_bank": "",
        "cabang_bank": "",
        "nama_pemilik_rekening": "",
        "id_jenis_rekening": ""
        },
      ],
      det: null,
      ormasIdentify : null,
      pengurus: null,
      listTerdaftar: [],
      listBadanHukum: [],
      listSekretariat: [],
      listCiriKhusus: [],
      listBidangKegiatan: [],
      listKegiatanOrmas: [],
      detailKegiatan: {
        id:'',
        nama_kegiatan: '',
        masterJenisKegiatan: {nama_jenis_kegiatan: ''},
        tanggal_kegiatan: '',
        tempat_kegiatan: '',
        keterangan: '',
        masterSumberDana: {nama_sumber_dana: ''},
        
      },
      listRiwayat: [],
      listAfterPerubahan: [],
      currentData: [],
      changedData: [],
      filterStatus: false,
      filterStatusAsing: false,
      filterBadanHukumStatus: false,
      query: {},
      namabadanhukum: '',
      kodeprovbadanhukum: '',
      kodekabkotbadanhukum: '',
      jenisbadanhukum: 0,
      listTidakTerdaftar: [],
      idPenambahan: null,
      idPenambahanAsing: null,
      dataOrmasing: [],
      Negara:'',
      sisaAktif: '',
      asingLokuses: [],
      asingKemitraan: {
        asingKemitraan: '',
        mitraKl: '',
        mitraJabatan: '',
        mitraPejabat: '',
        ormasLokal: []
      },
      asingKemitraanLkpds: [],
      namaOrmasSKTAsing: [],
      asingAnggotas: [],
      asingPelaporans: [],
      tanggalPel: '',
      namaPelaporPel: '',
      jabatanPel: '',
      instansiPel: '',
      isiLaporanPel: '',
      fileLaporanPel: '',
      perihalPel: 0,
      selectedAsingProvinsi: '',
      selectedAsingKabKot: '',
      selectedAsingKec: '',
      selectedAsingKel: '',
      selectedAsingJln: '',
      programOA: [],
      info_organizationtt: {
        "id": null,
        "nama_ormas": "",
        "nama_singkat": "",
        "npwp": "",
        "m_kategori_ormas_id": null,
        "alamat_sekretariat": "",
        "no_skt": "",
        "skt_aktif": "",
        "tanggal_skt": "",
        "tanggal_berdiri": "",
        "email": "",
        "no_telp": "",
        "no_fax": "",
        "website": "",
        "status_ormas": null,
        "status_badan_hukum": null,
        "status_legalitas": null,
        "sys_user_id": null,
        "m_lokasi_sekretariat_id": null,
        "provinsi_sekretariat": "",
        "kabupaten_kota_sekretariat": "",
        "m_ciri_khusus_id": null,
        "berhimpunOtonom": [{nama_ormas: ''}],
        "wadahBerhimpun": {nama_ormas: ''},
        "bidangKegiatans": [
          {
            "id": null,
            "data_ormas_id": null,
            "keterangan": "",
            "m_jenis_ormas_id": null,
            "nama_jenis_ormas": "",
          }
        ],
        "tujuanOrmas": [
          {
            "id": null,
            "data_ormas_id": null,
            "keterangan": ""
          }
        ],
        "masterProvinsi": {
          "id": null,
          "kode": "",
          "nama": "",
          "tipe": null,
          "latlong": null,
          "terhapus": false
        },
        "masterKabupatenKota": {
          "id": null,
          "kode": "",
          "nama": "",
          "tipe": null,
          "latlong": null,
          "terhapus": false
        },
        "masterCiriKhusus": {
          "id": null,
          "nama_ciri_khusus": "",
          "terhapus": 0
        }
      },
      founder_organizationtt: {
        "id": null,
        "dataOrmasId": null,
        "cabangOrmasId": null,
        "nomorAkta": "",
        "tanggalAkta": "",
        "tempatPendirian": "",
        "tanggalPendirian": "",
        "nomorSuratPendaftaran": "",
        "tanggalSuratPendaftaran": "",
        "m_kesbangpol_id": null,
        wilayahProvinsi: {
          masterKesbangpolProv: {
            nama: '',
            email: '',
            noTelepon: ''
          }
        },
        wilayahKabupatenKota: {
          masterKesbangpolKab: {
            nama: '',
            email: '',
            noTelpon: ''
          }
        }
      },
      structure_organizationtt: {
        "pengurusInti": [{
          "id": null,
          "data_ormas_id": null,
          "cabang_ormas_id": null,
          "nama_pengurus": "",
          "m_jenis_identitas_id": null,
          "identitas": "",
          "tempat_lahir": "",
          "tanggal_lahir": "",
          "alamat": "",
          "provinsi": "",
          "kabupaten_kota": "",
          "no_telp": "",
          "no_hp": "",
          "periode_mulai": "",
          "periode_selesai": "",
          "status_pengurus": null,
          "keterangan": "",
          "foto": "",
          "m_kategori_pengurus_id": {},
          "m_jabatan_id": {},
          "masterKategoriPengurus": {
            "id": null,
            "nama_kategori_pengurus": "",
            "terhapus": null,
            "kode_kategori_pengurus": ""
          },
          "masterJabatan": {
            "id": null,
            "nama_jabatan": "",
            "terhapus": null,
            "kode_jabatan": ""
          }
        }],
        "listPengurus": [{
          "id": null,
          "data_ormas_id": null,
          "cabang_ormas_id": null,
          "nama_pengurus": "",
          "m_jenis_identitas_id": null,
          "identitas": "",
          "tempat_lahir": "",
          "tanggal_lahir": "",
          "alamat": "",
          "provinsi": "",
          "kabupaten_kota": "",
          "no_telp": "",
          "no_hp": "",
          "periode_mulai": "",
          "periode_selesai": "",
          "status_pengurus": null,
          "keterangan": "",
          "foto": "",
          "m_kategori_pengurus_id": {},
          "m_jabatan_id": {},
          "masterKategoriPengurus": {
            "id": null,
            "nama_kategori_pengurus": "",
            "terhapus": null,
            "kode_kategori_pengurus": ""
          },
          "masterJabatan": {
            "id": null,
            "nama_jabatan": "",
            "terhapus": null,
            "kode_jabatan": ""
          }
        }]
      },
      document_organizationtt: [
          {
          "id": null,
          "nama_jenis_dokumen": "",
          "terhapus": null,
          masterJenisDokumen: {nama_jenis_dokumen: ''},
          "kode": ""
          }
        ],
      bank_account_organizationtt: [
        {
        "id": null,
        "data_ormas_id": null,
        "cabang_id": null,
        "no_rekening": "",
        "nama_bank": "",
        "cabang_bank": "",
        "nama_pemilik_rekening": "",
        masterJenisRekening: {nama: ''},
        "id_jenis_rekening": ""
        },
      ],
      listCabangtt: [],
      listKota: [],
      fileURL: process.env.VUE_APP_APPLICATION_FILE_URL + '/',
      listDokumen: [],
      listDoc: [],
      nama: '',
      nomor_akta_terakhir: 0,
      jenis_ormas_badan_hukum: 0,
      tanggal_akta_terakhir: '',
      kedudukan_provinsi_id: '',
      nama_kedudukan_provinsi: '',
      kedudukan_kabupaten_id: '',
      nama_kedudukan_kabupaten: '',
      nama_notaris: '',
      kedudukan_notaris: '',
      notaris_pengganti: '',
      nomor_sk: '',
      tanggal_sk: '',
      ket_sosial: '',
      ket_sosial_lainnya: '',
      ket_kemanusiaan: '',
      ket_kemanusiaan_lainnya: '',
      ket_keagamaan: '',
      ket_keagamaan_lainnya: '',
      domisili_terakhir: {
        kode_kecamatan: '',
        kode_kelurahan: '',
        rt: '',
        rw: '',
        alamat: ''
      },
      // pengurus: [],
      maksud: {
        asas_tujuan: '',
      },
    };
  },
  mounted() {
    this.fetchDataTerdaftar()
    this.getUserType()
    this.getStatusSekretariat()
    this.getCiriKhusus()
    this.getBidangKegiatan()
    this.getProvinsi()
    this.getTahunSkt()
    // this.getListOrmasAsing()
  },
  watch:{
    selectedProvinsi(to){
      this.getKota(to)
    },
    currentPage(to){
      this.currentOffset = to - 1
      this.fetchDataTerdaftar()
    },
    currentPagee(to){
      this.currentOffsetTT = to - 1
      this.fetchTidakTerdaftar()
    },
    currentPageAsing(to){
      this.currentOffsetOA = to - 1
      this.getListOrmasAsing()
    },
    currentPageBadanHukum(to){
      this.pageDataBadanHukum = to
      this.getBadanHukumNextPage()
    },
    tabDetail(to){
      if(this.userType == 'admin'){
        if (to == 1) this.getFounderOrganization()
        else if (to == 2) this.getStructureOrganization()
        else if (to == 3) this.getDocumentOrganization()
        else if (to == 4) this.getBankAccountOrganization()
        else if (to == 5) this.getListCabang()
        else if (to == 6) this.getListKegiatan()
        else if (to == 7) this.getRiwayatPerubahan()
      } else {
        if (to == 1) this.getFounderOrganization()
        else if (to == 2) this.getStructureOrganization()
        else if (to == 3) this.getBankAccountOrganization()
        else if (to == 4) this.getListCabang()
        else if (to == 5) this.getListKegiatan()
        else if (to == 6) this.getRiwayatPerubahan()
      }
      
    },
    tabDetailTT(to){
      if (to == 5) {
        this.getListCabangTidakTerdaftar(this.idPenambahan)
      }

      if (to == 3) {
        this.getListDokumen()
      }
    },
    tab(to){
      if(to == 0) {
        this.resetFilter()
        this.submitFilter()
      }
      if(to == 1) {
        this.resetFilter()
        this.submitFilter()
      }
      if(to == 2) {
        this.resetFilterAsing()
        this.submitFilterAsing()
      }
      if(to == 3) {
        this.resetFilterBadanHukum()
        this.submitFilterBadanHukum()
      }
    },
    search: {
      handler() {
        // Check if the user has stopped typing
        if (this.typingTimeout) {
          clearTimeout(this.typingTimeout);
        }
        this.typingTimeout = setTimeout(() => {
          // User has stopped typing, perform your action here
          if (this.tab == 3) {
            this.searchingBadanHukum()
          } if (this.tab == 2) {
            this.searchingAsing()
          } else {
            this.searching();
          }

        }, 500); // Adjust the delay as needed (1 second in this example)
      },
      immediate: false, // Set to true if you want to trigger it immediately
    }
  },
  methods: {
    async downloadItem(url, label, typeD) {
      const response = await axios.get(url, { responseType: "blob" });
      const blob = new Blob([response.data], { type: typeD });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = label;
      link.click();
      URL.revokeObjectURL(link.href);
    },
    cetakPDFCabang(id){
      this.$store
      .dispatch("printPDFCabang", id)
      .then((res) => {
        window.open(res.data.meta.urlPath , "_blank");
      })
    },
    replaceByDefault(e) {
      e.target.src = "/assets/images/default-thumbnail.jpg"
    },
    convProv(id){
      var b = this.listProvinsi.find(element =>{
        return element.kode == id
      })
      return b.nama
    },
    convKab(id){
      var b = this.listKota.find(element =>{
        return element.kode == id
      })
      return b.nama
    },
    convTahunSkt(id){
      var b = this.listTahunSkt.find(element => {
        return element == id
      })

      return b
    },
    getProvinsi(){
      this.$store
        .dispatch("getPropinsi")
        .then((res) => {
          
           this.$nextTick(() => {
            this.listProvinsi = res.data.data
          })
          })
    },
    getTahunSkt(){
      for (let index = 2000; index <= new Date().getFullYear(); index++) {
        this.listTahunSkt.push(index)
      }
    },
    cetakPDFDetail(id){
      this.loadExPdf = true
      this.$store
        .dispatch("printPDFInfoOrg", id)
        .then((res) => {
          this.loadExPdf = false
          if(!res.data.status){
            Swal.fire({
              title: "Export File Gagal",
              text: res.data.message,
              icon: "warning",
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "OK",
            }).then(() => {
            })
            return false
          }
          window.open(res.data.meta.urlPath , "_blank");
        }).catch((err) =>{
          this.loadExPdf = false
        Swal.fire({
          title: "Export File Gagal",
          text: err.message,
          icon: "warning",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "OK",
        }).then(() => {
        })
      })
    },
    cetakPDF(){
      this.loadExPdf = true
      this.query.exporttype = 'nasional'
      this.$store
        .dispatch("printPDFInfoOrganisasi", this.query)
        .then((res) => {
          this.loadExPdf = false
          if(!res.data.status){
            Swal.fire({
              title: "Export File Gagal",
              text: res.data.message,
              icon: "warning",
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "OK",
            }).then(() => {
            })
            return false
          }
          window.open(res.data.meta.urlPath , "_blank");
        }).catch((err) =>{
          this.loadExPdf = false
        Swal.fire({
          title: "Export File Gagal",
          text: err.message,
          icon: "warning",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "OK",
        }).then(() => {
        })
      })
    },
    cetakEXCEL(){
      this.loadExExcel = true
      this.query.exporttype = 'nasional'
      this.$store
        .dispatch("printExcelInfoOrganisasi", this.query)
        .then((res) => {
          this.loadExExcel = false
          if(!res.data.status){
            Swal.fire({
              title: "Export File Gagal",
              text: res.data.message,
              icon: "warning",
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "OK",
            }).then(() => {
            })
            return false
          }
          window.open(res.data.meta.urlPath , "_blank");
        }).catch((err) =>{
          this.loadExExcel = false
        Swal.fire({
          title: "Export File Gagal",
          text: err.message,
          icon: "warning",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "OK",
        }).then(() => {
        })
      })
    },
    cetakPDFAsing(){
      this.loadExPdf = true
      this.query.type = 1 // means All data (nasional)
      this.$store
        .dispatch("printPDFAsing", this.query)
        .then((res) => {
          this.loadExPdf = false
          if(!res.data.status){
            Swal.fire({
              title: "Export File Gagal",
              text: res.data.message,
              icon: "warning",
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "OK",
            }).then(() => {
            })
            return false
          }
          window.open(res.data.meta.urlPath , "_blank");
        }).catch((err) =>{
          this.loadExPdf = false
        Swal.fire({
          title: "Export File Gagal",
          text: err.message,
          icon: "warning",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "OK",
        }).then(() => {
        })
      })
    },
    cetakEXCELAsing(){
      this.loadExExcel = true
      this.query.type = 1 // means All data (nasional)
      this.$store
        .dispatch("printExcelAsing", this.query)
        .then((res) => {
          this.loadExExcel = false
          if(!res.data.status){
            Swal.fire({
              title: "Export File Gagal",
              text: res.data.message,
              icon: "warning",
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "OK",
            }).then(() => {
            })
            return false
          }
          window.open(res.data.meta.urlPath , "_blank");
        }).catch((err) =>{
          this.loadExExcel = false
        Swal.fire({
          title: "Export File Gagal",
          text: err.message,
          icon: "warning",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "OK",
        }).then(() => {
        })
      })
    },
    printOutTidakTerdaftar(t){
      this.loadTt = true
      this.$store
        .dispatch("printOutTidakTerdaftar", [this.query, t])
        .then((res) => {
          this.loadTt = false
          if(!res.data.status){
            Swal.fire({
              title: "Export File Gagal",
              text: res.data.message,
              icon: "warning",
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "OK",
            }).then(() => {
            })
            return false
          }
          window.open(res.data.meta.urlPath , "_blank");
        }).catch((err) =>{
          this.loadTt = false
          Swal.fire({
            title: "Export File Gagal",
            text: err.message,
            icon: "warning",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "OK",
          }).then(() => {
          })
      })
    },
    cetakDetailUnreg(id){
      this.loadExPdf = true
      this.$store
        .dispatch("printPDFDetailUnreg", id)
        .then((res) => {
          this.loadExPdf = false
          if(!res.data.status){
            Swal.fire({
              title: "Export File Gagal",
              text: res.data.message,
              icon: "warning",
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "OK",
            }).then(() => {
            })
            return false
          }
          window.open(res.data.meta.urlPath , "_blank");
        }).catch((err) =>{
          this.loadExPdf = false
        Swal.fire({
          title: "Export File Gagal",
          text: err.message,
          icon: "warning",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "OK",
        }).then(() => {
        })
      })
    },
    resetFilter(){
      this.query = {}
      this.search = ''
      this.filter = {
        bidang_kegiatan: [{id: []}],
        status_sekretariat: '',
        ciri_khusus: '',
        jenjang_ormas: '',
        status_kegiatan: '',
        dalam_sengketa: '',
        batas_negara: '',
        kabupaten_kota: '',
        sktAktif: '',
        tahunTerbitSkt: '',
      }
      this.selectedProvinsi = ''
    },
    getUserType(){
      var prof = JSON.parse(localStorage.getItem('profile'))

      if(prof.profilUser && prof.profilUser.masterKesbangpol && prof.profilUser.masterKesbangpol.kabupaten_kota_kode == null){
        this.userType = 'provinsi'
        this.getKota(Number(prof.profilUser.masterKesbangpol.provinsi_kode))
      }else if(prof.profilUser && prof.profilUser.masterKesbangpol && prof.profilUser.masterKesbangpol.kabupaten_kota_kode != null){
        this.userType = 'kabupaten'
      } else {
        this.userType = 'admin'
      }
    },
    resetFilterBadanHukum(){
      this.search = ''
      this.filterbadanhukum = {
        kabupaten_kota: '',
        jenisbadanhukum: 0,
      }
      this.jenisbadanhukum = 0,
      this.namabadanhukum = ''
    },
    showFilterBadanHukum(){
      this.filterBadanHukumStatus = false
      this.$bvModal.show('filterBadanHukum')
    },
    submitFilterBadanHukum(){
      this.filterBadanHukumStatus = true
      this.$bvModal.hide('filterBadanHukum')
      this.jenisbadanhukum = this.filterbadanhukum.jenisbadanhukum
      if(this.search != '') this.namabadanhukum = this.search.trim()
      if(this.tab == 3 && this.namabadanhukum !== ""){
        this.fetchDataBadanHukum()
        this.loadingBadanHukum = true
      }else{
      this.listBadanHukum = []
      this.loadingBadanHukum = false
      }
    },
    delFilterBadanHukum(p){
      if(p == 'jenis') {
        this.filterbadanhukum.jenisbadanhukum = 0
        this.jenisbadanhukum = 0
      }
      this.submitFilterBadanHukum()
    },
    searchingBadanHukum(){
      this.listBadanHukum = []
      this.namabadanhukum = this.search.trim()
      if (this.namabadanhukum.length < 3 && this.namabadanhukum.length > 0) return;

      if(this.tab == 3 && this.namabadanhukum !== ""){
        this.fetchDataBadanHukum()
        this.loadingBadanHukum = true
      }else{
      this.listBadanHukum = []
      this.loadingBadanHukum = false
      }
    },
    showFilterAsing(){
      this.filterStatusAsing = false
      this.$bvModal.show('filterAsing')
    },
    submitFilterAsing(){
      this.filterStatusAsing = true
      this.$bvModal.hide('filterAsing')

      if(this.filter.bidang_kegiatan[0].id.length != 0) {
        var bkeg = []
        for(var i=0;i < this.filter.bidang_kegiatan.length; i++){
          bkeg.push(this.filter.bidang_kegiatan[i].id[0])
        }
      }
      this.query = {
        sk: this.filter.status_kegiatan,
        bk: bkeg,
        ss: this.filter.status_sekretariat[0],
        ck: this.filter.ciri_khusus[0],
        jo: this.filter.jenjang_ormas,
        prov: this.selectedProvinsi,
        kab: this.filter.kabupaten_kota,
        ds : this.filter.dalam_sengketa,
        sa: this.filter.sktAktif
      }

      if(this.search != '') this.query.no = this.search.trim()
      this.getListOrmasAsing()
      
    },
    delFilterAsing(p){
      if(p == 'bk') this.filter.bidang_kegiatan = [{id: ''}]
      if(p == 'ss') this.filter.status_sekretariat = ''
      if(p == 'ck') this.filter.ciri_khusus = ''
      if(p == 'jo') this.filter.jenjang_ormas = ''
      if(p == 'ds') this.filter.dalam_sengketa = ''
      if(p == 'bn') this.filter.batas_negara = ''
      if(p == 'sk') this.filter.status_kegiatan = ''
      if(p == 'kab') this.filter.kabupaten_kota = ''
      if(p == 'prov') this.selectedProvinsi = ''
      if(p == 'sa') this.filter.sktAktif = ''
      this.submitFilterAsing()
    },
    searchingAsing(){
      this.query.no = this.search.trim()

      if (this.query.no.length > 0 && this.query.no.length < 3) return

      this.getListOrmasAsing()
    },
    resetFilterAsing(){
      this.query = {}
      this.search = ''
      this.filter = {
        bidang_kegiatan: [{id: []}],
        status_sekretariat: '',
        ciri_khusus: '',
        jenjang_ormas: '',
        status_kegiatan: '',
        dalam_sengketa: '',
        batas_negara: '',
        kabupaten_kota: '',
        sktAktif: '',
      }
      this.selectedProvinsi = ''
      this.selectedTahunSkt = ''
    },
    showFilter(){
      this.filterStatus = false
      this.$bvModal.show('filter')
    },
    submitFilter(){
      this.filterStatus = true
      this.$bvModal.hide('filter')

      if(this.filter.bidang_kegiatan[0].id.length != 0) {
        var bkeg = []
        for(var i=0;i < this.filter.bidang_kegiatan.length; i++){
          bkeg.push(this.filter.bidang_kegiatan[i].id[0])
        }
      }
      this.query = {
        sk: this.filter.status_kegiatan,
        bk: bkeg,
        ss: this.filter.status_sekretariat[0],
        ck: this.filter.ciri_khusus[0],
        jo: this.filter.jenjang_ormas,
        prov: this.selectedProvinsi,
        kab: this.filter.kabupaten_kota,
        ds : this.filter.dalam_sengketa,
        sa: this.filter.sktAktif,
        tahun_ter_skt: this.selectedTahunSkt
      }

      if(this.search != '') this.query.no = this.search.trim()
      if(this.tab == 0){
        this.fetchDataTerdaftar()
      } else {
        this.fetchTidakTerdaftar()
      }
      
    },
    delFilter(p){
      if(p == 'bk') this.filter.bidang_kegiatan = [{id: ''}]
      if(p == 'ss') this.filter.status_sekretariat = ''
      if(p == 'ck') this.filter.ciri_khusus = ''
      if(p == 'jo') this.filter.jenjang_ormas = ''
      if(p == 'ds') this.filter.dalam_sengketa = ''
      if(p == 'bn') this.filter.batas_negara = ''
      if(p == 'sk') this.filter.status_kegiatan = ''
      if(p == 'kab') this.filter.kabupaten_kota = ''
      if(p == 'prov') this.selectedProvinsi = ''
      if(p == 'sa') this.filter.sktAktif = ''
      if(p == 'tahun_ter_skt') this.selectedTahunSkt = ''
      this.submitFilter()
    },
    searching(){
      this.query.no = this.search.trim()

      if (this.query.no.length < 3 && this.query.no.length > 0) return;

      if(this.tab == 0){
        this.fetchDataTerdaftar()
      } else {
        this.fetchTidakTerdaftar()
      }
    },
    async itemCountGet(q) {
      this.$store
        .dispatch("getDataOrganisasiAllCount", q)
        .then((res) => {
          this.totalRows = res.data.count
      })
    },
    fetchDataTerdaftar(){
      this.loadingTerdaftar = true
      this.listTerdaftar = []
      this.query.offset = this.currentOffset
      this.itemCountGet(this.query)
      this.$store
        .dispatch("getDataOrganisasiAll",this.query)
        .then((res) => {
          this.paginateDataTerdaftar = res.data.data
          this.loadingTerdaftar = false
      })
    },
    fetchDataBadanHukum(){
      var ambilusers = JSON.parse(localStorage.getItem('profile'))
      this.totalRowsBadanHukum = 0
      this.currentPageBadanHukum = 1
      this.listBadanHukum = []
      this.namabadanhukum = this.namabadanhukum
      if(this.filterbadanhukum.jenisbadanhukum === 0){
        this.jenisbadanhukum = 0
      } else {
        this.jenisbadanhukum = this.filterbadanhukum.jenisbadanhukum
      }
      if(this.namabadanhukum !== '' && this.jenisbadanhukum === 0){
        this.totalRowsBadanHukum = 0
        this.currentPageBadanHukum = 1
        this.listBadanHukum = []
        axios
        .get(this.baseURL_bakkum + 'cariBadanHukum/' + ambilusers.id + '?nama=' + this.namabadanhukum, {
          headers: {
            "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
          "Access-Control-Allow-Headers": "Origin, Content-Type, Accept, Content-Length, Accept-Language, Accept-Encoding, Connection, Access-Control-Allow-Origin",
          "Access-Control-Allow-Credentials": "false",
          }
        })
        .then((listBadanHukums) => {
          var l = 0;
          for(l=0; l<listBadanHukums.data.data.length; l++){
            this.listBadanHukum.push(listBadanHukums.data.data[l]);
          }
          this.totalRowsBadanHukum = listBadanHukums.data.meta.total
          this.loadingBadanHukum = false
        })
      }
      if(this.namabadanhukum !== '' && this.jenisbadanhukum === 1){
        this.totalRowsBadanHukum = 0
        this.currentPageBadanHukum = 1
        this.listBadanHukum = []
          axios
            .get(this.baseURL_bakkum + 'cariPerkumpulan?nama=' + this.namabadanhukum)
            .then((listBadanHukums) => {
              var l = 0;
              for(l=0; l<listBadanHukums.data.data.length; l++){
                this.listBadanHukum.push(listBadanHukums.data.data[l]);
              }
              this.totalRowsBadanHukum = listBadanHukums.data.meta.total
              this.loadingBadanHukum = false
            })
        }
        if(this.namabadanhukum !== '' && this.jenisbadanhukum === 2){
        this.totalRowsBadanHukum = 0
        this.currentPageBadanHukum = 1
        this.listBadanHukum = []
          axios
            .get(this.baseURL_bakkum + 'cariYayasan?nama=' + this.namabadanhukum)
            .then((listBadanHukums) => {
              var l = 0;
              for(l=0; l<listBadanHukums.data.data.length; l++){
                this.listBadanHukum.push(listBadanHukums.data.data[l]);
              }
              this.totalRowsBadanHukum = listBadanHukums.data.meta.total
              this.loadingBadanHukum = false
            })
        } else {
        this.listBadanHukum = []
        this.totalRowsBadanHukum = 0
        this.currentPageBadanHukum = 1
        this.loadingBadanHukum = false
      }
    },
    getBadanHukumNextPage() {
      var ambiluserss = JSON.parse(localStorage.getItem('profile'))
      this.loadingBadanHukum = true
      this.listBadanHukum = [];
      this.namabadanhukum = this.namabadanhukum
      this.currentPageBadanHukum = this.pageDataBadanHukum;
      if(this.filterbadanhukum.jenisbadanhukum === 0){
        this.jenisbadanhukum = 0
      } else {
        this.jenisbadanhukum = this.filterbadanhukum.jenisbadanhukum
      }
      if(this.namabadanhukum !== '' && this.jenisbadanhukum === 0){
        this.listBadanHukum = []
        axios
        .get(this.baseURL_bakkum + 'cariBadanHukum/' + ambiluserss.id + '?nama=' + this.namabadanhukum + '&page=' + this.currentPageBadanHukum)
        .then((listBadanHukums) => {
          var l = 0;
          for(l=0; l<listBadanHukums.data.data.length; l++){
            this.listBadanHukum.push(listBadanHukums.data.data[l]);
          }
          this.loadingBadanHukum = false
        })
      }
      if(this.namabadanhukum !== '' && this.jenisbadanhukum === 1){
        this.listBadanHukum = []
          axios
            .get(this.baseURL_bakkum + 'cariPerkumpulan?pages=' + this.currentPageBadanHukum + '&nama=' + this.namabadanhukum)
            .then((listBadanHukums) => {
              var l = 0;
              for(l=0; l<listBadanHukums.data.data.data.length; l++){
                this.listBadanHukum.push(listBadanHukums.data.data.data[l]);
              }
          this.loadingBadanHukum = false
          })
        }
        if(this.namabadanhukum !== '' && this.jenisbadanhukum === 2){
          this.listBadanHukum = []
          axios
            .get(this.baseURL_bakkum + 'cariYayasan?pages=' + this.currentPageBadanHukum + '&nama=' + this.namabadanhukum)
            .then((listBadanHukums) => {
              var l = 0;
              for(l=0; l<listBadanHukums.data.data.data.length; l++){
                this.listBadanHukum.push(listBadanHukums.data.data.data[l]);
              }
          this.loadingBadanHukum = false
          })
        } else {
      this.listBadanHukum = []
      }
    },
    paginateTerdaftar() {
      this.paginateDataTerdaftar = this.listTerdaftar.slice((this.currentPage - 1) * this.perPage, this.currentPage * this.perPage);
    },
    async getCountListOrmas(q) {
      this.$store.dispatch("getListPenambahanOrmasCount", q).then((res) => {
          this.totalRowss = res.data.count
      })
    },
    fetchTidakTerdaftar(){
      this.loadingTidakTerdaftar = true
      this.getCountListOrmas(this.query)
      this.query.offset = this.currentOffsetTT
      this.listTidakTerdaftar = []
      this.$store
        .dispatch("getListPenambahanOrmas", this.query)
        .then((res) => {
          this.$nextTick(() => {
            this.listTidakTerdaftar = res.data.data
            this.loadingTidakTerdaftar = false
        })
      })
    },
    getDetailOrganisasi(id){
      this.$store
      .dispatch("getDetailDataOrganisasi", id)
      .then((res) => {
          this.isOrmas = res.data.isOrmas
          this.ormasIdentify = []
          this.info_organization = []
          this.tabDetail = 0
          this.tabDetailRiwayat = 0
          this.tabDetailCabang = 0
          if(this.isOrmas) this.getProfileInfo(res.data.noSkt)
          else this.getDetailCabang(id)
        })
    },
    getDetailBadanHukum(nama, sk){
      Swal.showLoading({allowOutsideClick: false})
      // this.data_pengurus_badanhukum = []
      axios
          .get(this.baseURL_bakkum + 'getDetailBadanHukum?nama=' + nama + '&nomor_sk=' + sk)
          .then((res) => {
            this.nama = res.data.data[0].nama
            this.nomor_akta_terakhir = res.data.data[0].nomor_akta_terakhir
            this.tanggal_akta_terakhir = res.data.data[0].tanggal_akta_terakhir
            this.kedudukan_provinsi_id = res.data.data[0].kedudukan_provinsi_id
            this.nama_kedudukan_provinsi = res.data.data[0].nama_kedudukan_provinsi
            this.kedudukan_kabupaten_id = res.data.data[0].kedudukan_kabupaten_id
            this.nama_kedudukan_kabupaten = res.data.data[0].nama_kedudukan_kabupaten
            this.nama_notaris = res.data.data[0].nama_notaris
            this.kedudukan_notaris = res.data.data[0].kedudukan_notaris
            this.notaris_pengganti = res.data.data[0].notaris_pengganti
            this.nomor_sk = res.data.data[0].nomor_sk
            this.tanggal_sk = res.data.data[0].tanggal_sk
            this.ket_sosial = res.data.data[0].ket_sosial
            this.ket_sosial_lainnya = res.data.data[0].ket_sosial_lainnya
            this.ket_kemanusiaan = res.data.data[0].ket_kemanusiaan
            this.ket_kemanusiaan_lainnya = res.data.data[0].ket_kemanusiaan_lainnya
            this.ket_keagamaan = res.data.data[0].ket_keagamaan
            this.ket_keagamaan_lainnya = res.data.data[0].ket_keagamaan_lainnya
            this.domisili_terakhir = {
              kode_kecamatan: res.data.data[0].domisili_terakhir.kode_kecamatan,
              kode_kelurahan: res.data.data[0].domisili_terakhir.kode_kelurahan,
              rt: res.data.data[0].domisili_terakhir.rt,
              rw: res.data.data[0].domisili_terakhir.rw,
              alamat: res.data.data[0].domisili_terakhir.alamat
            }
            this.maksud = {
              asas_tujuan: res.data.data[0].maksud.asas_tujuan
            }
            
            
            var penguruss = res.data.data[0].pengurus;
            
            this.pengurus = penguruss
            
            if(this.pengurus[0].data.jenis_pengurus == "ORGAN PERKUMPULAN"){
              this.jenis_ormas_badan_hukum = 1
            }
            if(this.pengurus[0].data.jenis_pengurus == "ORGAN YAYASAN"){
              this.jenis_ormas_badan_hukum = 2
            } else {
              this.jenis_ormas_badan_hukum = 0
            }
            Swal.close()
            this.$bvModal.show('modal-profil-badan-hukum')
        })
    },
    getProfileInfo(id){
      this.$store
        .dispatch("getProfileOrganization", id)
        .then((res) => {
          this.ormasIdentify = res.data
          this.info_organization = res.data
          this.$bvModal.show('modal-profil')
          const tanggal_skt_end = new Date(this.info_organization.tanggal_skt)
            tanggal_skt_end.setFullYear(tanggal_skt_end.getFullYear() + 5)
            this.tanggal_skt_end = tanggal_skt_end
          
        })
    },
    getProfileInfoUAsing(id){
      this.$store
        .dispatch("getDetailDataOrganisasi", id)
        .then((res) => {
          this.namaOrmasSKTAsing[id] = res.data.namaOrmas ? res.data.namaOrmas : ''
        })
    },
    getFounderOrganization(){
      this.$store
        .dispatch("getFounderOrganization", this.info_organization.id)
        .then((res) => {
          this.founder_organization = res.data
        })
    },
    getDetailPerubahanAfter(id){
      this.currentData = {}
      this.changedData = {}
      this.listAfterPerubahan = {}

      this.$store
        .dispatch("getAfterPerubahan", id)
        .then((res) => {
          let curdata = res.data.data.currentData
          this.currentData = curdata
          this.changedData = res.data.data.changedData
          this.det.profil = curdata.profil
          this.listAfterPerubahan = res.data.data
          this.$bvModal.show('modal-detail-riwayat')
        })
    },
    getStructureOrganization(){
      this.$store
        .dispatch("getStructureOrganization", this.info_organization.id)
        .then((res) => {
          this.pengurus = res.data.pengurusInti
          this.structure_organization = res.data
        })
    },
    getDocumentOrganization(){
      this.$store
        .dispatch("getDocumentOrganization", this.info_organization.id)
        .then((res) => {
          this.document_organization = res.data
        })
    },
    getListKegiatan(){
      this.$store
        .dispatch("getKegiatanOrmas", this.info_organization.id)
        .then((res) => {
          this.listKegiatanOrmas = res.data.data
        })
    },
    getKota(id){
      this.$store
      .dispatch("getKabupatenKota", id)
      .then((res) => {
        
        this.$nextTick(() => {
          this.listKota = res.data.data
        })
      })
    },
    getBankAccountOrganization(){
      this.$store
        .dispatch("getBankAccountOrganization", this.info_organization.id)
        .then((res) => {
          this.bank_account_organization = res.data
          this.loading5 = false
        })
    },
    getRiwayatPerubahan(){
      this.$store
        .dispatch("listPermohonanPerubahan", this.info_organization.id)
        .then((res) => {
          this.listRiwayat = res.data.data
          this.loading5 = false
        })
    },

    detailRiwayat(/*item*/){
      this.getProfileInfo()
      this.getStructureOrganization()
      this.$store
      .dispatch("getDataPerubahan", 10)
      .then((res) => {
        this.det = res.data.data
        this.$bvModal.show('modal-detail-riwayat')
      })
    },
    getDetailCabang(id){
      this.detailCabang = null
      this.$store
      .dispatch("getDetailCabang", id)
      .then((res) => {
        this.$nextTick(() =>{
          this.detailCabang = res.data.data
          this.$bvModal.show('detail-cabang')
        })
        
        })
    },
    getDetailCabangtt(id){
      this.detailCabang = null
      this.$store
      .dispatch("getDetailCabangPenambahan", id)
      .then((res) => {
        this.$nextTick(() =>{
          this.detailCabang = res.data.data
          this.$bvModal.show('detail-cabang')
        })
        
        })
    },
    getListCabang(){
      this.$store
        .dispatch("getListCabangAll", this.info_organization.id)
        .then((res) => {
          this.$nextTick(() => {
            this.listCabang = res.data.data
          })
      })
    },
    getStatusSekretariat(){
      this.$store
        .dispatch("getStatusSekretariat")
        .then((res) => {
          
          this.$nextTick(() => {
            this.listSekretariat = res.data
          })
          })
    },
    getCiriKhusus(){
      this.$store
        .dispatch("getCiriKhusus")
        .then((res) => {
           this.$nextTick(() => {
            this.listCiriKhusus = res.data
          })
          })
    },
    getBidangKegiatan(){
      this.$store
        .dispatch("getBidangKegiatan")
        .then((res) => {
           this.$nextTick(() => {
            this.listBidangKegiatan = res.data
          })
          })
    },
    newTabDoc(url){
      if(url.length > 0){
        window.open(url[0].path);
      }else {
        Swal.fire({
            title: "Gagal Mendapatkan data",
            text: "",
            icon: "warning",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Ok",
          })
      }
    },
    newTab(url){
      if(url.substring(0,4) == 'http') window.open(url);
      else window.open(this.fileURL + '/' + url);
    },
    download() {
      Swal.fire({
        title: "Download Tidak Berhasil",
        text: "",
        icon: "warning",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Ok",
      })
    },
    showDetailKegiatan(item){
      this.detailKegiatan = item
      this.$bvModal.show('detail-kegiatan')
    },
    detailPerubahan(item){
      this.loading = true
      this.$store
      .dispatch("getDataPerubahan", item.id)
      .then((res) => {
        this.loading = false
        this.det = res.data.data
        this.getStructureOrganization()
        this.getDetailPerubahanAfter(item.id)
      })
    },
    toDetail(id){
      this.tabDetailTT = 0
      this.$store
      .dispatch("getDetailPenambahanOrmas", id)
      .then((res) => {
        this.isOrmasUnreg = true
        this.idPenambahan = res.data.data.id
        this.getListCabangTidakTerdaftar(res.data.data.id)
        let d = res.data.data

        if(d.dokumenOrmas) this.listDoc = d.dokumenOrmas

        this.info_organizationtt = {
        "id": d.id,
        "nama_ormas": d.nama_ormas,
        "file_logo": d.file_logo,
        "file_bendera": d.file_bendera,
        "file_npwp": d.file_npwp,
        "file_sk_pengurus": d.file_sk_pengurus,
        "nama_singkat": d.nama_singkat,
        "npwp": d.npwp,
        "alamat_sekretariat": d.masterLokasiSekretariat,
        "no_skt": d.no_skt,
        "tanggal_skt": "",
        "tanggal_berdiri": "",
        "email": d.email,
        "no_telp": d.no_telp,
        "no_fax": d.no_fax,
        "m_lokasi_sekretariat_id": d.m_lokasi_sekretariat_id,
        "provinsi_sekretariat": d.infoPendirian && d.infoPendirian.wilayahProvinsi ? d.infoPendirian.wilayahProvinsi.nama : '',
        "kabupaten_kota_sekretariat": d.infoPendirian && d.infoPendirian.wilayahKabupatenKota ? d.infoPendirian.wilayahKabupatenKota.nama : '',
        "m_ciri_khusus_id": d.masterCiriKhusus ? d.masterCiriKhusus.id : d.m_ciri_khusus_id,
        berhimpunOtonom: d.badanOtonom,
        wadahBerhimpun: d.wadahBerhimpun,
        "bidangKegiatans": d.bidangKegiatan,
        "tujuanOrmas": d.tujuanOrmas,
        "masterProvinsi": d.masterProvinsi ? d.masterProvinsi : '',
        "masterKabupatenKota": d.masterKabupatenKota ? d.masterKabupatenKota : '',
        "masterCiriKhusus": d.masterCiriKhusus ? d.masterCiriKhusus.id : {nama_ciri_khusus: d.m_ciri_khusus_id}
      },
      this.founder_organizationtt = {
        "nomorAkta": d.infoPendirian ? d.infoPendirian.nomorAkta : '',
        namaNotaris: d.infoPendirian ? d.infoPendirian.namaNotaris : '',
        fileAkta: d.infoPendirian ? d.infoPendirian.file_akta : '',
        "tanggalAkta": d.infoPendirian ? d.infoPendirian.tanggalAkta : '',
        "tempatPendirian": d.infoPendirian ? d.infoPendirian.wilayahProvinsi ? d.infoPendirian.wilayahProvinsi.nama : '' + ' ' + d.infoPendirian.wilayahKabupatenKota ? d.infoPendirian.wilayahKabupatenKota.nama : '' : '',
        "tanggalPendirian": d.infoPendirian ? d.infoPendirian.tanggalPendirian : '',
        "nomorSuratPendaftaran": d.infoPendirian ? d.infoPendirian.nomorSuratPendaftaran : '',
        "tanggalSuratPendaftaran": d.infoPendirian ? d.infoPendirian.tanggalAkta : ''
      },
      this.structure_organizationtt = {
        "pengurusInti": d.strukturOrmas ? d.strukturOrmas.pengurusInti : '',
        "listPengurus": d.strukturOrmas ? d.strukturOrmas.listPengurus : ''
      },
      this.document_organizationtt = d.dokumenOrmas ? d.dokumenOrmas : []
      this.bank_account_organizationtt = d.rekeningOrmas ? d.rekeningOrmas : []
         this.$bvModal.show('modal-profil-tidak-terdaftar')
      })
    },
    toDetailLokus(item){
      this.closeDetailLokus()
      this.$bvModal.show('modal-detailLokus-ormas')
      this.selectedAsingKabKot = item.masterKabupatenKota
      this.selectedAsingProvinsi = item.masterProvinsi
      this.selectedAsingKec = item.masterKecamatan
      this.selectedAsingKel = item.masterKelurahan
      this.selectedAsingJln = item.jalan
    },
    closeDetailLokus(){
      this.$bvModal.hide('modal-detailLokus-ormas')
      this.selectedAsingProvinsi = '',
      this.selectedAsingKabKot = '',
      this.selectedAsingKec = '',
      this.selectedAsingKel = '',
      this.selectedAsingJln = ''
    },
    resetDetailpelaporan() {
      this.$bvModal.hide('modal-detailPelaporan-ormas')
      this.tanggalPel = '',
      this.namaPelaporPel = '',
      this.jabatanPel = '',
      this.instansiPel = '',
      this.isiLaporanPel = '',
      this.fileLaporanPel = '',
      this.perihalPel = 0
    },
    toDetailPelaporan(item){
      this.resetDetailpelaporan()
      this.$bvModal.show('modal-detailPelaporan-ormas')
      this.tanggalPel = item.tanggal
      this.namaPelaporPel = item.namaPelapor
      this.jabatanPel = item.jabatan
      this.instansiPel = item.instansi
      this.isiLaporanPel = item.isiLaporan
      this.fileLaporanPel = item.fileLaporan
      this.perihalPel = item.perihal
    },
    todetailAsing(id) {
      this.activeTab = 0
      this.$store
      .dispatch("getDetailPenambahanOrmasAsing", id)
        .then((res) => {
          localStorage.setItem('idPenambahanOrmas', res.data.id)
          this.idPenambahanAsing = res.data.id
          this.dataOrmasing = res.data
          this.programOA = JSON.parse(this.dataOrmasing.program)
          this.Negara = this.dataOrmasing.masterNegara.negara
          var present_date = new Date();
          var dEnd = new Date(this.dataOrmasing.akhirMsp);
          var Result = Math.round(dEnd.getTime() - present_date.getTime()) / (1000 * 3600 * 24);
          var Tahun = Result / 365
          var Bulan = Tahun * 12
          var Hari = Bulan * 30
          if (this.dataOrmasing.akhirMsp === '') {
            this.sisaAktif = ''
          }
          if (Tahun < 1) {
            this.sisaAktif = Math.floor(Bulan) + ' Bulan'
            if (Bulan < 1) {
              this.sisaAktif = Math.floor(Hari) + ' Hari'
            }
          } else {
            this.sisaAktif = Math.floor(Tahun) + ' Tahun'
          }
          if (this.dataOrmasing.asingLokuses != undefined) {
            var lokuss = []
            for (var j = 0; j < this.dataOrmasing.asingLokuses.length; j++) {
              var jj = this.dataOrmasing.asingLokuses[j]
              lokuss.push({
                asingKemitraan: id,
                provinsiKode: jj.provinsiKode,
                kabupatenKotaKode: jj.kabupatenKotaKode,
                kecamatanKode: jj.kecamatanKode ? jj.kecamatanKode : '',
                kelurahanKode: jj.kelurahanKode ? jj.kelurahanKode : '',
                jalan: jj.jalan,
                masterProvinsi: jj.masterProvinsi ? jj.masterProvinsi.nama : '',
                  masterKabupatenKota: jj.masterKabupatenKota ? jj.masterKabupatenKota.nama : '',
                  masterKecamatan: jj.masterKecamatan ? jj.masterKecamatan.nama : '',
                  masterKelurahan: jj.masterKelurahan ? jj.masterKelurahan.nama : ''
              })
            }
            this.asingLokuses = lokuss
          }
          if (
            this.dataOrmasing.asingKemitraan.mitraKl !== null 
            || this.dataOrmasing.asingKemitraan.mitraJabatan !== null 
            || this.dataOrmasing.asingKemitraan.mitraPejabat !== null 
            || this.dataOrmasing.asingKemitraan.ormasLokal.length !== 0
          ){
            this.asingKemitraan = {
              asingKemitraan: id,
              mitraKl: this.dataOrmasing.asingKemitraan.mitraKl,
              mitraJabatan: this.dataOrmasing.asingKemitraan.mitraJabatan,
              mitraPejabat: this.dataOrmasing.asingKemitraan.mitraPejabat,
              ormasLokal: this.dataOrmasing.asingKemitraan.ormasLokal
            }
            for (var xx = 0; xx < this.asingKemitraan.ormasLokal.length; xx++){
              var xxx = this.asingKemitraan.ormasLokal[xx]
              if (xxx.idOrmasLokal !== 0) {
                this.getProfileInfoUAsing(xxx.idOrmasLokal)
              }
            }
          }
          if (this.dataOrmasing.asingKemitraanLkpds != undefined) {
            var Lkpdss = []
            for (var k = 0; k < this.dataOrmasing.asingKemitraanLkpds.length; k++) {
              var kk = this.dataOrmasing.asingKemitraanLkpds[k]
              Lkpdss.push({
                asingKemitraan: id,
                nama: kk.nama,
                noRkt: kk.noRkt,
                namaPejabat: kk.namaPejabat,
                fileRkt: kk.fileRkt
              })
            }
            this.asingKemitraanLkpds = Lkpdss
          }
          if (this.dataOrmasing.asingAnggotas != undefined) {
            var anggotass = []
            for (var m = 0; m < this.dataOrmasing.asingAnggotas.length; m++) {
              var mm = this.dataOrmasing.asingAnggotas[m]
              anggotass.push({
                asingKemitraan: id,
                nama: mm.nama,
                nomorIdentitas: mm.nomorIdentitas,
                negaraKode: mm.negaraKode,
                negara: mm.masterNegara.negara
              })
            }
            this.asingAnggotas = anggotass
          }
          if (this.dataOrmasing.asingPelaporans != undefined) {
              var pelaporanss = []
              for (var n = 0; n < this.dataOrmasing.asingPelaporans.length; n++) {
                var nn = this.dataOrmasing.asingPelaporans[n]
                pelaporanss.push({
                  asingKemitraan: id,
                  id: nn.id,
                  tanggal: nn.tanggal,
                  namaPelapor: nn.namaPelapor,
                  jabatan: nn.jabatan,
                  instansi: nn.instansi,
                  isiLaporan: nn.isiLaporan,
                  fileLaporan: nn.fileLaporan,
                  perihal: nn.perihal
                })
              }
              this.asingPelaporans = pelaporanss
            }
          this.$bvModal.show('modal-detailasing-ormas')
        })
    },
    getListCabangTidakTerdaftar(id){
      this.$store
        .dispatch("getListCabangPenambahan", id)
        .then((res) => {
          this.$nextTick(() => {
            this.listCabangtt = res.data.data
          })
          })
    },
    async getCountListOrmasAsing(q) {
      this.$store.dispatch("getListPenambahanOrmasAsingCount", q).then((res) => {
        this.totalRowAsing = res.data.count
      })
    },
    getListOrmasAsing() {
      this.loadingTable = true
      this.query.offset = this.currentOffsetOA
      this.query.type = 'all'
      this.getCountListOrmasAsing(this.query)
      this.$store.dispatch("getListPenambahanOrmasAsing", this.query).then((res) => {
        this.$nextTick(() => {
          this.loadingTable = false
          this.listOrmasAsing = res.data
        })
      })
    },
    getListDokumen(){
      this.$store
        .dispatch('getDokumenList')
        .then((res) => {
          // this.resetFormCabang()
          // this.createMode = false
          this.listDokumen = res.data
          // this.resetFormCabang()
          // this.fillFormCabang(res.data.data, id)
        })
    },
    cekUploaded(id){
      if(this.listDoc){
        var dd = this.listDoc.find(val => {
          return val.masterJenisDokumen.id === id
        })
        if(dd){
          return true
        } else{ return false}
      }
    },
    seeUploaded(id){
      var dd = this.listDoc.find(val => {
        return val.masterJenisDokumen.id === id
      })

      if(dd){
        this.newTab(this.fileURL + dd.path)
      }
    },
    async downloadItemTT(id) {
      var dd = this.listDoc.find(val => {
        return val.masterJenisDokumen.id === id
      })

      if(dd){
        const response = await axios.get(this.fileURL + dd.path, { responseType: "blob" });
        const blob = new Blob([response.data], { type: dd.tipe_dokumen });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = dd.nama_dokumen;
        link.click();
        URL.revokeObjectURL(link.href);
      }

    },
  },
  computed: {
    
  }
};
</script>

<style>
.nav.nav-pills .nav-link {
  color: #053A69;
  font-weight: bold;
}
.nav-item {
  margin-left:10px;
  margin-right:10px;
}
.button-plus {
  background:#339933;cursor:pointer; padding:5px; border-radius:50%;width:30px; height:30px;text-align:center; margin-left:5px;

}
.button-minus {
  background:#FF4433;cursor:pointer; padding:5px; border-radius:50%;width:30px; height:30px;text-align:center; margin-left:5px;
}

#modal-profil .modal-dialog.modal-xl {
  max-width:100% !important;
  margin-left:1rem;
  margin-right:1rem;
}

#detail-tab .card-header {
  font-size: 11px !important;
}

#modal-detail-riwayat___BV_modal_content_ .modal-body{
  padding:0px !important;
}
</style>
